import { Tabs, Tab, Typography, Box, Grid } from '@mui/material/';
import { useState } from 'react';
import { useSearchMappingsStyles } from '../../theme/Theme';
import { useStyles } from './muiStyles';

import MappingOntologyTableContent from './MappingOntologyTableContent';
import MappingGraphContent from '../mappingsGraph/MappingGraphBetweenOntologies';

function OntologyMappingsTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function ontologyMappingsProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
/**
 *
 * Author Nenad Krdzavac
 * Email neand.krdzavac@tib.eu
 * */
export const SearchMappings = () => {
  const mapping_classes = useSearchMappingsStyles();

  const classes = useStyles();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container className={classes.cont}>
      {/**
         Coypu logo is added below "how to use" button.
         **/}

      <Grid item xs={10} md={12} className={classes.grid}>
        <Typography variant="h5" gutterBottom>
          Mappings
        </Typography>
        <Typography variant="body2">
          This page explains how to explore and search mappings between pair of ontologies computed by LogMap ontology matching tool. "LogMap tool implements a sound but incomplete reasoning algorithm
          for checking class unsatisfiability. Consequently, there is no guarantee that all classes in union of O1 ∪ O2 ∪ M will be satisfiable", where O1 is source ontology, O2 is target ontology,
          and M is set of mappings.
        </Typography>
      </Grid>
      <Grid item xs={10} md={12} className={classes.grid}>
        <Tabs onChange={handleChange} value={value} className={mapping_classes.tabs} TabIndicatorProps={{ style: { display: 'flex' } }}>
          <Tab label="mappings between ontologies" className={mapping_classes.tab} {...ontologyMappingsProps(0)} />
          <Tab label="mappings graph" className={mapping_classes.tab} {...ontologyMappingsProps(1)} />
        </Tabs>

        <OntologyMappingsTabPanel value={value} index={0}>
          <div className={mapping_classes.root}>
            <MappingOntologyTableContent />
          </div>
        </OntologyMappingsTabPanel>

        <OntologyMappingsTabPanel value={value} index={1}>
          <div className={mapping_classes.root}>
            <MappingGraphContent />
          </div>
        </OntologyMappingsTabPanel>
      </Grid>
    </Grid>
  );
};
