import React, { useState } from 'react';
import { Grid, Snackbar, Slide } from '@mui/material';

export const WebProtege = () => {
  const [showNotification, setShowNotification] = useState(false);

  const showBrowserNotification = () => {
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 5000);
  };

  return (
    <>
      <Grid container>
        <Grid item md={12}>
          <Snackbar open={showNotification} TransitionComponent={Slide} message="This tool works only on Mozilla Browser" autoHideDuration={5000} onClose={() => setShowNotification(false)} />
          <iframe
            title="WebProtege"
            style={{ height: '100%', width: '100%', border: 'none', minHeight: 'calc(100vh - 223px)' }}
            loading="lazy"
            src="https://service.tib.eu/wp4tib/"
            onLoad={showBrowserNotification}
          />
        </Grid>
      </Grid>
    </>
  );
};
