import React, {useEffect, useState} from "react";
import {Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Typography} from "@mui/material";

const EditDialog = ({ open, onClose, onSave, data }) => {
    const [editedData, setEditedData] = useState(data);

    useEffect(() => {
        setEditedData(data);
    }, [open, data]);

    const handleChange = (field) => (e) => {
        setEditedData({ ...editedData, [field]: e.target.value });
    }

    const handleSave = async () => {
        console.log(data);
        await fetch(`${process.env.REACT_APP_DIFF_BACKEND_URL}/api/history/ontologies/edit/${data.id}`, {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(editedData),
        });
        handleClose();
        onSave();
    }

    const handleClose = () => {
        onClose();
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    minHeight: '50%',
                    maxHeight: '55%',
                    minWidth: '30%',
                    maxWidth: '30%',
                    position: 'relative',
                },
            }}
        >
            <DialogTitle>Edit</DialogTitle>
            <DialogContent>
                <form style={{textAlign: 'left'}}>
                    <Typography variant="h6">Name</Typography>
                    <TextField
                        fullWidth
                        value={editedData.name}
                        onChange={handleChange('name')}
                    />
                    <br />
                    <br />
                    <Typography variant="h6">Description</Typography>
                    <TextField
                        fullWidth
                        value={editedData.description}
                        onChange={handleChange('description')}
                    />
                </form>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditDialog;