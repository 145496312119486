import { AddForm } from "../AddForm";
import { ondetService } from "../../../../api/ondet.service";

export const AddConto = () => {
  return (
    <AddForm
      apiUrl={`${process.env.REACT_APP_DIFF_BACKEND_URL}/api/history/ontologies`}
      makeDiff={ondetService.contoDiff}
    />
  );
};
