import { Scrollbars } from 'react-custom-scrollbars-2';
import { Typography, CircularProgress, Skeleton } from '@mui/material';
import styled from 'styled-components';

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const SkeletonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
`;

export const ResultSection = ({ domain, isLoading, words, label, renderResults, hasSearched }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
      <Typography variant="h5" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {domain}
      </Typography>
      <Scrollbars style={{ flex: '1', height: '100%', marginRight: '10px' }}>
        {isLoading ? (
          <LoadingContainer>
            <Typography variant="h6" style={{ marginRight: '10px' }}>
              Loading the Results...
            </Typography>
            <CircularProgress />
          </LoadingContainer>
        ) : !hasSearched ? (
          <SkeletonContainer>
            <Skeleton variant="rectangular" width="100%" height={50} />
            <Skeleton variant="rectangular" width="100%" height={50} />
            <Skeleton variant="rectangular" width="100%" height={50} />
            <Skeleton variant="rectangular" width="100%" height={50} />
            <Skeleton variant="rectangular" width="100%" height={50} />
            <Skeleton variant="rectangular" width="100%" height={50} />
            <Skeleton variant="rectangular" width="100%" height={50} />
            <Skeleton variant="rectangular" width="100%" height={50} />
          </SkeletonContainer>
        ) : words.length === 0 ? (
          <LoadingContainer>
            <Typography variant="h6" style={{ marginRight: '10px' }}>
              No results for "{label}" in this domain
            </Typography>
          </LoadingContainer>
        ) : (
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>{renderResults(words)}</div>
        )}
      </Scrollbars>
    </div>
  );
};
