import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import fairDS_logo from '../../../assets/img/sandbox_logos/fair-DS.svg';

const useStyles = makeStyles((theme) => ({
  text: {
    [theme.breakpoints.down('xl')]: {
      display: 'none',
    },
  },
}));

export const FairDSLogo = () => {
  const classes = useStyles();
  return (
    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="body2" className={classes.text}>
        Developed within
      </Typography>
      <img src={fairDS_logo} width="100%" height="80%" />
    </Box>
  );
};
