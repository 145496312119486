import MaterialReactTable from "material-react-table";
import React, {useEffect, useState} from 'react';
import {Typography, Grid, Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

export const PIDChecker = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_PID_BACKEND_URL}/read-ontology-iri-status/`);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const jsonData = await response.json();
                // Check if jsonData has results
                if (jsonData?.results?.length > 0) {
                    // Get the latest result
                    const latestResult = jsonData.results[jsonData.results.length - 1];

                    // Extract all IRIs from the latest ontology details
                    const iris = latestResult.ontology.flatMap(item => item.ontologyDetails.map(detail => detail?.iri));

                    // Get previous results excluding the latest one, and reverse them for chronological checking
                    const previousResults = jsonData.results.slice(0, -1).reverse();

                    // Create a map to store the earliest downSince times for matching IRIs
                    const iriDownSinceMap = {};

                    // Iterate over previous results to find when IRIs went down
                    for (const result of previousResults) {
                        for (const ontologyItem of result.ontology) {
                            for (const detail of ontologyItem.ontologyDetails) {
                                const iri = detail?.iri;
                                if (iri && iris.includes(iri) && !iriDownSinceMap[iri]) {
                                    iriDownSinceMap[iri] = detail?.lastTimeChecked || null;
                                }
                            }
                        }
                    }

                    // Update the downSince property in the latest result's ontology details
                    latestResult.ontology.forEach(latestOntologyItem => {
                        latestOntologyItem.ontologyDetails.forEach(latestDetail => {
                            if (latestDetail?.iri && iriDownSinceMap[latestDetail.iri]) {
                                latestDetail.downSince = iriDownSinceMap[latestDetail.iri];
                            }
                        });
                    });

                    // Update the data with the latest result
                    setData(latestResult);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const flattenedData = data.ontology
        ? data.ontology.map(ont => ({
            ontologyId: ont.ontologyId,
            details: ont.ontologyDetails || null,
            numberOfIRI: ont.totalNumberOfIRI,
            numberOfDownIRI: Array.isArray(ont?.ontologyDetails) ? ont.ontologyDetails.filter(detail => detail !== null).length : null,
            ontologyURL: ont.ontologyFileLocation,
            pidPatterns: ont.pidPatterns || {},
        }))
        : [];
    console.log(flattenedData)
    const columns = [
        { header: 'Ontology ID', accessorKey: 'ontologyId' },
        { header: 'Number of IRI', accessorKey: 'numberOfIRI' },
        { header: 'Number of Down IRI', accessorKey: 'numberOfDownIRI' },
        {
            header: 'Pid Patterns',
            accessorKey: 'pidPatterns',
            Cell: ({ cell }) => {
                const patterns = cell.getValue(); // Get the pidPatterns object
                // Check if pidPatterns is null or an empty object
                if (!patterns || Object.keys(patterns).length === 0) {
                    return <div>No Pid Patterns Available</div>; // Render a fallback message
                }
                return (
                    <div>
                        {Object.entries(patterns).map(([key, value]) => (
                            <div key={key}>{`${key}: ${value}`}</div>
                        ))}
                    </div>
                );
            }
        }
    ];


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    };

    return (
        <Grid container justifyContent="center">
            <Grid item xs={10} md={11}>
                <Typography variant="h5" gutterBottom textAlign="center">
                    PID Checker Service
                </Typography>
                <Typography variant="body2" gutterBottom style={{marginBottom: '1rem'}}>
                    The PID Checker service is designed to verify the resolvability and unresolvability of IRI (Internationalized Resource Identifier) statuses for terms within the NFDI4Energy project's ontologies. This service determines whether the IRIs associated with these terms are available or not, ensuring that all ontology terms are properly accessible.
                </Typography>
                <MaterialReactTable
                    columns={columns}
                    enableRowExpansion={true}
                    initialState={{ pagination: { pageSize: 5 } }}
                    enableColumnResizing={true}
                    autoResetPageIndex={false}
                    enableStickyHeader={true}
                    data={flattenedData}
                    renderDetailPanel={({ row }) => (
                        <Box sx={{ padding: '1rem' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>IRI</TableCell>
                                        <TableCell>Last Time Checked</TableCell>
                                        <TableCell>Unresolvable Since</TableCell>
                                        <TableCell>Server Status</TableCell>
                                        <TableCell>Term Name</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Array.isArray(row.original?.details) && row.original.details.some(detail => detail !== null) ? (
                                        row.original.details.map((detail, index) => {
                                            const iri = detail?.iri;
                                            const time = detail?.lastTimeChecked;
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        <a href={iri} target="_blank" rel="noreferrer">{iri}</a>
                                                    </TableCell>
                                                    <TableCell>{formatDate(time)}</TableCell>
                                                    <TableCell>
                                                        {detail?.downSince ? formatDate(detail.downSince) : formatDate(time)}
                                                    </TableCell>
                                                    <TableCell>{detail?.serverStatus}</TableCell>
                                                    <TableCell>{detail?.termName}</TableCell>
                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        // Display message when details are empty or not an array
                                        <TableRow>
                                            <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                                                No details available.
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </Box>
                    )}
                />
            </Grid>
        </Grid>
    );
};