import React, { useMemo } from 'react';
import { Grid, Box } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import DisplayMappingDetails from './DisplayMappingDetails';

const TableForRowTargetOntologies = ({ mappings, row, table }) => {

  const columns = useMemo(
    () => [
      {
        accessorKey: 'targetOntologyName',
        header: 'Target Ontology Name',
        muiTableHeadCellProps: {
          align: 'left',
        },
      },
      {
        accessorKey: 'numberOfMappings',
        header: 'Number of Mappings',
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'numberOfConflictiveMappings',
        header: 'Number of Conflitive Mappings',
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
    ],
    []
  );

  const filteredMappings = [];

  mappings.forEach((outerItem) => {
    outerItem.targetOntologyList.forEach((innerSourceItem) => {
      innerSourceItem.targetOntology.forEach((innerTargetItem) => {
        /**
         * compares outer loop id with row id because of adding in table
         * column only those ontology ids that are target ontologies for given
         * source ontology.
         */
        if (outerItem.id === row) {
          const tableRowObject = {
            id: outerItem.id,
            targetOntologyId: `${innerTargetItem.ontologyId}`,
            targetOntologyName: `${innerTargetItem.title}`,
            numberOfMappings: innerSourceItem.numberOfMappings,
            numberOfConflictiveMappings: innerSourceItem.numberOfConflictiveMappings,
          };
          if (innerSourceItem.numberOfMappings > 0 || innerSourceItem.numberOfConflictiveMappings > 0) {
            filteredMappings.push(tableRowObject);
          }
        }
      });
    });
  });

  return (
    <Grid container>
      <MaterialReactTable
        columns={columns}
        data={filteredMappings}
        enableColumnResizing={true}
        autoResetPageIndex={false}
        enableStickyHeader={true}
        enableRowActions={true}
        enableFullScreenToggle={false}
        muiTableBodyRowProps={({ row }) => ({
          sx: {
            backgroundColor: row.getIsExpanded() ? '#efefef' : 'white',
          },
        })}
        muiTableProps={{
          sx: {
            borderCollapse: 'collapse',
          },
        }}
        muiTableHeadCellProps={{
          sx: () => ({
            backgroundColor: '#D3D3D3',
          }),
        }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: 'Actions',
            size: 10,
          },
        }}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
            <DisplayMappingDetails mappings={mappings} row={row} targetOntologyTitle={row.original.targetOntologyName} targetOntologyId={row.original.targetOntologyId} table={table} />
          </Box>
        )}
        initialState={{ density: 'compact' }}
      />
    </Grid>
  );
};
export default TableForRowTargetOntologies;
