import { useState } from 'react';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import { Box, Modal, IconButton, Button, Backdrop, Typography } from '@mui/material';
import TableForRowTargetOntologies from './TableForRowTargetOntologies';

const style = {
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  height: '90%',
  backgroundColor: 'white',
  border: '1px solid #d9dcdd',
  borderRadius: '4px',
  overflowY: 'scroll',
  overflowX: 'hidden',
};

const closeBtnStyle = {
  position: 'absolute',
  top: '10px',
  right: '20px',
  fontWeight: 'bold',
  cursor: 'pointer',
};

const DisplayMappingDetailsTabView = ({ mappings, row, table }) => {
  const [open, setOpen] = useState(false);

  const handleModalToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <IconButton onClick={handleModalToggle}>
        <ExpandCircleDownOutlinedIcon />
      </IconButton>

      <Modal
        open={open}
        onClose={handleModalToggle}
        BackdropComponent={Backdrop}
        BackdropProps={{
          invisible: true,
          onClick: null,
        }}
      >
        <Box sx={style}>
          <div style={{ position: 'sticky', top: '0', zIndex: 1, background: '#d1d3d9', padding: '10px' }}>
            <Typography variant="h5" style={{ textAlign: 'center' }}>
              List of Target Ontologies
            </Typography>
            <span style={closeBtnStyle} onClick={handleModalToggle}>
              X
            </span>
          </div>
          <div style={{ overflowY: 'auto', padding: '1rem', flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box>
              <TableForRowTargetOntologies mappings={mappings} row={row} table={table} />
            </Box>
          </div>
          <div style={{ position: 'sticky', bottom: '0', padding: '10px', zIndex: 1, background: '#d1d3d9', display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" color="secondary" onClick={handleModalToggle}>
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};
export default DisplayMappingDetailsTabView;
