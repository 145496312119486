import { SandboxApp } from './SandboxApp';
import { BrowserRouter } from 'react-router-dom';

export const SandboxAppParent = () => {
  return (
    <BrowserRouter>
      <SandboxApp />
    </BrowserRouter>
  );
};
