import React from 'react';
import { SizeMe } from 'react-sizeme';
import { ForceGraph2D } from 'react-force-graph';

const MappingGraphForEachRow = ({mappings, id, targetOntologyId}) => {

  const n = [];
  const links = [];

  /**
   * Create graph:
   *
   * Creates JSON object for graph nodes and links between nodes for each mapping id. It includes nodes and links for
   * conflictive mappings and mappings.
   */
  {
    mappings.forEach((key) => {

      if (key.id === id.original.id) {

        key.targetOntologyList.forEach((key1) => {

          key1.targetOntology.map((key11)=>{

            if(key11.ontologyId === targetOntologyId) {

            console.log(key11.ontologyId + " " + targetOntologyId);

        if (key1.mappingList.length > 0) {
          /**
           * intialize coordinate of nodes.
           * @type {number}
           */
          let yCoordindateMapping = 0;
          let xCoordindateMapping = 0;

          key1.mappingList.map(function (key2, index2) {

            const sIRI = key2.sourceIRI;
            const tIRI = key2.targetIRI;

            let source = /[^/]*$/.exec(sIRI)[0];
            let target = /[^/]*$/.exec(tIRI)[0];

            /**
             * if source and target class / property names are equal
             * then add full iri as a node label.
             */
            if (source === target) {
              source = sIRI;
              target = tIRI;
            }

            const sourceNode = {
              id: source,
              iri: sIRI,
              x: xCoordindateMapping - 70,
              y: yCoordindateMapping - 10,
            };
            const targetNode = {
              id: target,
              iri: tIRI,
              x: xCoordindateMapping - 10,
              y: yCoordindateMapping - 10,
            };

            yCoordindateMapping = yCoordindateMapping + 5;

            n.push(sourceNode);
            n.push(targetNode);

            let linkcolor = '#000000';

            let mappingsDir = '';

            if (Number(key2.typeOfMapping) === 0) {
              /**
               * if mapping type is equal zero then links are coloured in green
               * @type {string}
               */
              linkcolor = '#A2C2AE';

              if (key2.mappingDirection === -2) {
                mappingsDir = 'classEquivalence';
              } else if (key2.mappingDirection === 0) {
                mappingsDir = 'subClassOf';
              } else {
                if (key2.mappingDirection === -1) {
                  mappingsDir = 'superClassOf';
                }
              }
            } else if (Number(key2.typeOfMapping) === 1) {
              /**
               * if mapping type is equal to one then links are coloured in dark blue
               * @type {string}
               */
              linkcolor = '#0901A9';

              if (key2.mappingDirection === -2) {
                mappingsDir = 'propertyEquivalence';
              } else if (key2.mappingDirection === 0) {
                mappingsDir = 'subPropertyOf';
              } else if (key2.mappingDirection === -1) {
                mappingsDir = 'superPropertyOf';
              }
            } else if (Number(key2.typeOfMapping) === 2) {
              /**
               * if type of mapping is equal two then links are coloured in pink
               */

              linkcolor = '#FF00FF';

              if (key2.mappingDirection === -2) {
                mappingsDir = 'propertyEquivalence';
              } else if (key2.mappingDirection === 0) {
                mappingsDir = 'subPropertyOf';
              } else {
                if (key2.mappingDirection === -1) {
                  mappingsDir = 'superPropertyOf';
                }
              }
            }

            const mappingListLinks = {
              source: source,
              target: target,
              mappingdirection: key2.mappingDirection,
              typeofmapping: key2.typeOfMapping,
              structuralconfidencemapping: Number(key2.structuralConfidenceMapping).toFixed(2),
              confidence: Number(key2.confidence).toFixed(2),
              map: 'mapping',
              label: '(' + source + ',' + mappingsDir + ',' + target + ')',
              color: linkcolor,
            };

            links.push(mappingListLinks);
          });
        }

        if (key1.conflictiveMappingsList.length > 0) {
          let xCoordindateCMapping = 0;
          let yCoordindateCMapping = 0;

          key1.conflictiveMappingsList.map(function (key3, index3) {
            const sIRI = key3.sourceIRI;
            const tIRI = key3.targetIRI;

            /**
             * gets substring of class and property IRIs after last slash character.
             * @type {string}
             */
            let source = /[^/]*$/.exec(sIRI)[0];
            let target = /[^/]*$/.exec(tIRI)[0];

            /**
             * If source class / property name is equal to target class /property  name then
             * add to node labels full iri of target class /property name
             */
            if (source === target) {
              source = sIRI;
              target = tIRI;
            }

            const sourcecNode = {
              id: source,
              iri: sIRI,
              x: xCoordindateCMapping + 50,
              y: yCoordindateCMapping - 10,
            };

            const targetcNode = {
              id: target,
              iri: tIRI,
              x: xCoordindateCMapping,
              y: yCoordindateCMapping - 10,
            };

            yCoordindateCMapping = yCoordindateCMapping + 5;

            n.push(sourcecNode);
            n.push(targetcNode);

            let linkccolor = '#000000';
            let mappingsDir = '';

            if (key3.typeOfMapping === 0) {
              /**
               * if mapping type is equal zero then links are coloured in green
               * @type {string}
               */
              linkccolor = '#A2C2AE';

              if (key3.mappingDirection === -2) {
                mappingsDir = 'classEquivalence';
              } else if (key3.mappingDirection === 0) {
                mappingsDir = 'subClassOf';
              } else {
                if (key3.mappingDirection === -1) {
                  mappingsDir = 'superClassOf';
                }
              }
            } else if (key3.typeOfMapping === 1) {
              /**
               * if mapping tyle is euqal one then links are coloured in dark blue
               * @type {string}
               */
              linkccolor = '#0901A9';

              if (key3.mappingDirection === -2) {
                mappingsDir = 'propertyEquivalence';
              } else if (key3.mappingDirection === 0) {
                mappingsDir = 'subPropertyOf';
              } else {
                if (key3.mappingDirection === -1) {
                  mappingsDir = 'superPropertyOf';
                }
              }
            } else if (Number(key3.typeOfMapping) === 2) {
              /**
               * if type of mapping is equal two then links are coloured in pink
               */

              linkccolor = '#FF00FF';

              if (key3.mappingDirection === -2) {
                mappingsDir = 'propertyEquivalence';
              } else if (key3.mappingDirection === 0) {
                mappingsDir = 'subPropertyOf';
              } else {
                if (key3.mappingDirection === -1) {
                  mappingsDir = 'superPropertyOf';
                }
              }
            }

            const cmappingListLinks = {
              source: source,
              target: target,
              mappingdirection: key3.mappingDirection,
              typeofmapping: key3.typeOfMapping,
              structuralconfidencemapping: Number(key3.structuralConfidenceMapping).toFixed(2),
              confidence: Number(key3.confidence).toFixed(2),
              map: 'cmapping',
              label: '(' + source + ',' + mappingsDir + ',' + target + ')',
              color: linkccolor,
            };

            links.push(cmappingListLinks);
          });
        }
        }
    })
      })
      }
    });
  }

  /**
   * removes duplicates from n-array and creates graph nodes.
   * @type {*[]}
   */
  const nodes = n.filter((thing, index, self) => index === self.findIndex((temp) => temp.id === thing.id));

  return (
    <SizeMe monitorHeight={true} monitorWidth={true}>
      {({ size }) => (
        /**
         * style={{height:'100px', width: '100px'}}
         * @param size
         * @returns {JSX.Element}
         */
        <div>
          <ForceGraph2D
            graphData={{ nodes, links }}
            showNavInfo={true}
            autoPauseRedraw={true}
            linkDirectionalArrowLength={1.1}
            linkDirectionalArrowRelPos={1}
            linkCurvature={0.03}
            linkWidth={3}
            enableNodeDrag={true}
            nodeVal={0.05}
            minZoom="6"
            maxZoom="20"
            nodeOpacity={1}
            backgroundColor="white"
            height={750}
            width={1640}
            warmUpTicks={100}
            cooldownTicks={0}
            nodeLabel="iri"
            linkLabel="label"
            linkAutoColorBy="color"
            nodeColor={(node) => {
                return  '#4f6d7a';
            }}

            /**
             * customization view of nodes that includes fonts, node size, node label
             * alignment, node colour, etc.
             */
            nodeCanvasObjectMode={() => 'after'}
            nodeCanvasObject={(node, crc, number) => {
              const label = node.id;
              crc.font = '1.7px Times';
              crc.textAlign = 'left';
              crc.textBaseline = 'middle';
              crc.fillStyle = 'black';
              crc.fillText(label, node.x, node.y - 2);
            }}
            /**
             * customization view of links between nodes that size, label
             * alignment, colour, position, etc.
             */
            linkCanvasObjectMode={() => 'after'}
            linkCanvasObject={(link, crc) => {
              const start = link.source;
              const end = link.target;

              // calculating label position
              const textPos = Object.assign(
                ...['x', 'y'].map((c) => ({
                  [c]: start[c] + (end[c] - start[c]) / 2, // calc middle point
                }))
              );

              const label = '(' + link.typeofmapping + ' , ' + link.mappingdirection + ' , ' + link.structuralconfidencemapping + ' , ' + link.confidence + ')';
              const mapping = link.map;

              //fontSize to fit in link length
              crc.font = '1.7px Times';

              // text label
              crc.save();
              crc.translate(textPos.x, textPos.y);
              crc.textAlign = 'center';
              crc.textBaseline = 'middle';

              if (mapping === 'mapping') {
                crc.fillStyle = 'black';
              }

              if (mapping === 'cmapping') {
                crc.fillStyle = 'red';
              }

              crc.fillText(label, 0, 0);
              crc.restore();
            }}
          />
        </div>
      )}
    </SizeMe>
  );
};
export default MappingGraphForEachRow;