import { Link, Typography } from '@mui/material';
import React from 'react';

export const NERExplanation = () => {
  return (
    <div>
      {' '}
      <Typography variant="h5" gutterBottom>
        Annotation Service
      </Typography>
      <Typography variant="body2" gutterBottom>
        The tool is intended to demonstrate the indexing of texts based on ontologies in a prototypical way at first.
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Status:</b> We currently offer classification of the eleven parent terms of the{' '}
        <Link href="https://astrothesaurus.org/" target="_blank" underline="hover" rel="noopener">
          Unified Astronomy Thesaurus (UAT){' '}
        </Link>
        <Link href="https://terminology.nfdi4ing.de/ts/ontologies/uat" target="_blank" underline="hover" rel="noopener">
          ontology
        </Link>
        .
      </Typography>
      <Typography variant="body2" gutterBottom style={{ marginBottom: '1rem' }}>
        <b>Future Work:</b> The tool will be extended successively with further functionalities. Among other things, this concerns the performance, the removal of the restriction to recognise only the
        11 parent UAT terms, but also the transfer to other ontologies.
      </Typography>
    </div>
  );
};
