import React, { useState } from 'react';
import { Box, Modal, Button, Backdrop, CircularProgress, Fab, Typography } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  text: {
    [theme.breakpoints.up('xl')]: {
      marginLeft: '10px',
    },
    [theme.breakpoints.down('xl')]: {
      display: 'none',
    },
  },
}));

const style = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  height: '90%',
  backgroundColor: 'white',
  border: '1px solid #d9dcdd',
  borderRadius: '4px',
  overflow: 'hidden',
};

const closeBtnStyle = {
  position: 'absolute',
  top: '10px',
  right: '20px',
  fontWeight: 'bold',
  cursor: 'pointer',
};

export const RateMe = ({ url }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleModalToggle = () => {
    setOpen(!open);
  };

  const hideSpinner = () => {
    setIsLoading(false);
  };

  const getGoogleSurvey = () => {
    return <iframe title="Survey" src={url} style={{ width: '100%', height: 'calc(100% - 130px)', border: 'none', backgroundColor: '#ffffff' }} onLoad={hideSpinner} />;
  };

  return (
    <div style={{ display: 'flex', padding: '10px 0' }}>
      <Fab aria-label="add" onClick={handleModalToggle}>
        <ThumbUpIcon style={{ color: 'white' }} />
        <Typography variant="button" className={classes.text}>
          User Survey
        </Typography>
      </Fab>
      <Modal
        open={open}
        onClose={handleModalToggle}
        BackdropComponent={Backdrop}
        BackdropProps={{
          invisible: true,
          onClick: null,
        }}
      >
        <Box sx={style}>
          <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Survey</h2>
          <span style={closeBtnStyle} onClick={handleModalToggle}>
            X
          </span>
          {isLoading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'calc(100% - 130px)',
              }}
            >
              <CircularProgress />
            </Box>
          ) : null}
          {getGoogleSurvey()}
          <Button
            variant="contained"
            color="secondary"
            style={{
              marginTop: '10px',
              marginLeft: '10px',
            }}
            onClick={handleModalToggle}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};
