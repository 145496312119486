import MaterialReactTable from 'material-react-table';
import { Grid } from '@mui/material/';

/**
 *
 * Author Nenad Krdzavac
 * Email neand.krdzavac@tib.eu
 * */
const MappingOverview = ({ mappings, id, targetOntologyId, type, columns, mappingType, typeOfMapping }) => {
  let count = 0;

  const processMappings = (type, mappings, id, targetOntologyId, typeMapping, typeOfMapping, filteredDetailsMappings) => {
    if (typeMapping) {
      mappings.forEach((outerItem) => {
        if (outerItem.id === id.original.id) {
          outerItem.targetOntologyList.forEach((innerTargetItem) => {
            innerTargetItem.targetOntology.forEach((innerTargetOntItem) => {
              if (innerTargetOntItem.ontologyId === targetOntologyId) {
                console.log('type of mapping: ' + typeOfMapping);

                const mappingList = typeOfMapping === 'mapping' ? innerTargetItem.mappingList : innerTargetItem.conflictiveMappingsList;

                console.log('mapping list size: ' + mappingList.length);

                mappingList.forEach((key) => {
                  if (key.typeOfMapping === typeMapping.typeOfMapping) {
                    count = count + 1;
                    const mappingDirection = typeMapping.mappingDirections[key.mappingDirection];

                    if (mappingDirection) {
                      const newObj = {
                        id: outerItem.id,
                        sourceiri: `${key.sourceIRI}`,
                        mappingDirection: JSON.stringify(mappingDirection),
                        targetiri: `${key.targetIRI}`,
                        structuralConfidenceMapping: Number(key.structuralConfidenceMapping).toFixed(2),
                        confidence: Number(key.confidence).toFixed(2),
                      };

                      console.log('newObj.sourceiri: ' + newObj.sourceiri + ' newObj.targetiri: ' + newObj.targetiri);

                      filteredDetailsMappings.push(newObj);
                    }
                  }
                });
              }
            });
          });
        }
      });
    }
  };
  const filteredDetailsMappings = [];

  if (typeOfMapping === 'mapping') {
    const typeMapping = mappingType[type];

    console.log('type mapping (type of mapping): ' + typeMapping.typeOfMapping);

    processMappings(type, mappings, id, targetOntologyId, typeMapping, 'mapping', filteredDetailsMappings);

  } else if (typeOfMapping === 'conflictive') {

    const typeMapping = mappingType[type];

    processMappings(type, mappings, id, targetOntologyId, typeMapping, 'conflictive', filteredDetailsMappings);
  }

  return (
    <Grid container>
      {count > 0 && (
        <MaterialReactTable
          columns={columns}
          data={filteredDetailsMappings}
          enableColumnResizing={true}
          autoResetPageIndex={false}
          enableStickyHeader={true}
          enableFullScreenToggle={false}
          /**
           * It changes background color of each row to white smoke colour when the row is expanded.
           * otherwise, background color of the row is white
           * solved by Dr FarFar Kheir Eddine, email: Kheir.Farfar@tib.eu
           **/
          muiTableBodyRowProps={({ row }) => ({
            sx: {
              backgroundColor: row.getIsExpanded() ? '#efefef' : 'white',
            },
          })}
          muiTableProps={{
            sx: {
              borderCollapse: 'collapse',
            },
          }}
          /**
           * Defines background color for table headers
           * It is not needed to use useTheme hook.
           * It is enough to use the `sx` prop with the theme callback.
           */
          muiTableHeadCellProps={{
            sx: () => ({
              backgroundColor: '#D3D3D3' /* lightgray color*/,
            }),
          }}
          initialState={{ density: 'compact' }}
        />
      )}
      {count === 0 && <div style={{ padding: '20px', textAlign: 'center' }}>There are not mappings between {type}</div>}
    </Grid>
  );
};
export default MappingOverview;
