import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Divider, Typography } from '@mui/material';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AutoAwesomeMotionOutlinedIcon from '@mui/icons-material/AutoAwesomeMotionOutlined';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { useLocation } from 'react-router';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
import PolylineOutlinedIcon from '@mui/icons-material/PolylineOutlined';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import CheckIcon from '@mui/icons-material/Check';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

import Collapse from '@mui/material/Collapse';
import React, { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  sectionHeader: (props) => ({
    display: props.isOpen ? 'block' : 'none',
    paddingTop: '5px',
    marginBottom: '1px',
    fontSize: '13px',
    textAlign: 'center',
    fontWeight: 500,
    color: theme.palette.primary.main,
  }),
  list: {
    margin: '5px',
    padding: 0,
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const ListItems = ({ isOpen }) => {
  const ListItemsIndexes = Object.freeze({
    Robot: 0,
    Conto: 1,
    Mappings: 2,
  });

  const classes = useStyles({ isOpen });
  const location = useLocation();

  const [open, setOpen] = useState(null);

  const handleClick = (index) => {
    if (index === open) {
      setOpen(null); // Clicking the open list closes it
    } else {
      setOpen(index); // Clicking a closed list opens it
    }
  };

  return (
    <List className={classes.list}>
      <ListItemButton
        title="Open Sandbox Home"
        selected={location.pathname === process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox'}
        component={Link}
        to={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox'}
      >
        <ListItemIcon>
          <HomeOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Sandbox Home" />
      </ListItemButton>
      <Divider className={classes.divider} />
      <Typography className={classes.sectionHeader}>Ontology Curation</Typography>

      {/* @Aamir recommended to not delete the code below that is commented: for now we don't want to show it, may be we use it late */}
      {/* <ListItemButton
        title="Open Mappings"
        selected={location.pathname === process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/mappings'}
        component={Link}
        to={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/mappings'}
      >
        <ListItemIcon>
          <LayersOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Mappings" />
      </ListItemButton>
      <ListItemButton
        title="Open Pairwise Similarity"
        selected={location.pathname === process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/pairwisesimilarity'}
        component={Link}
        to={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/pairwisesimilarity'}
      >
        <ListItemIcon>
          <GraphicEqOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Pairwise Similarity" />
      </ListItemButton>

      <ListItemButton
        title="Open Quality Assessment"
        selected={location.pathname === process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/qualityassessment'}
        component={Link}
        to={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/qualityassessment'}
      >
        <ListItemIcon>
          <AssessmentOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Quality Assessment" />
      </ListItemButton> */}

      {/*Commented item for the Semantic diff used with Robot library*/}

      {/*<ListItemButton onClick={() => handleClick(ListItemsIndexes.Robot)} title="Open Semantic Diff" component={Link} to={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/semanticdiff/add'}>*/}
      {/*  <ListItemIcon>*/}
      {/*    <HistoryToggleOffIcon />*/}
      {/*  </ListItemIcon>*/}
      {/*  <ListItemText primary="ROBOT Diff" align="left" />*/}
      {/*  <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>{open === ListItemsIndexes.Robot ? <KeyboardArrowRightOutlinedIcon /> : <ExpandCircleDownOutlinedIcon />}</div>*/}
      {/*</ListItemButton>*/}
      {/*<Collapse in={open === ListItemsIndexes.Robot} timeout="auto" unmountOnExit>*/}
      {/*  <List disablePadding>*/}
      {/*    <ListItemButton*/}
      {/*      title="Open Process Ontology View"*/}
      {/*      selected={location.pathname === process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/semanticdiff/add'}*/}
      {/*      component={Link}*/}
      {/*      to={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/semanticdiff/add'}*/}
      {/*    >*/}
      {/*      <ListItemText inset primary="Track changes" align="left" />*/}
      {/*    </ListItemButton>*/}
      {/*    <ListItemButton*/}
      {/*      title="Open Table View"*/}
      {/*      selected={location.pathname === process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/semanticdiff'}*/}
      {/*      component={Link}*/}
      {/*      to={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/semanticdiff'}*/}
      {/*    >*/}
      {/*      <ListItemText inset primary="List of ontologies" align="left" />*/}
      {/*    </ListItemButton>*/}
      {/*  </List>*/}
      {/*</Collapse>*/}

      <ListItemButton onClick={() => handleClick(ListItemsIndexes.Conto)} title="Open Semantic Diff" component={Link} to={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/ondet/add'}>
        <ListItemIcon>
          <HistoryToggleOffIcon />
        </ListItemIcon>
        <ListItemText primary="OnDeT" align="left" />
        <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>{open === ListItemsIndexes.Conto ? <KeyboardArrowRightOutlinedIcon /> : <ExpandCircleDownOutlinedIcon />}</div>
      </ListItemButton>
      <Collapse in={open === ListItemsIndexes.Conto} timeout="auto" unmountOnExit>
        <List disablePadding>
          <ListItemButton
            title="Open Process Ontology View"
            selected={location.pathname === process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/ondet/add'}
            component={Link}
            to={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/ondet/add'}
          >
            <ListItemText inset primary="Track changes" align="left" />
          </ListItemButton>
          <ListItemButton
            title="Open Table View"
            selected={location.pathname === process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/ondet'}
            component={Link}
            to={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/ondet'}
          >
            <ListItemText inset primary="List of ontologies" align="left" />
          </ListItemButton>
        </List>
      </Collapse>

      <ListItemButton onClick={() => handleClick(ListItemsIndexes.Mappings)} title="Open Mappings View" component={Link} to={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/mappingTableView'}>
        <ListItemIcon>
          <AutoAwesomeMotionOutlinedIcon style={{ color: '#4f6d7a' }} />
        </ListItemIcon>
        <ListItemText primary="Mappings" align="left" />
        <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
          {open === ListItemsIndexes.Mappings ? <KeyboardArrowRightOutlinedIcon style={{ color: '#4f6d7a' }} /> : <ExpandCircleDownOutlinedIcon style={{ color: '#4f6d7a' }} />}
        </div>
      </ListItemButton>

      <Collapse in={open === ListItemsIndexes.Mappings} timeout="auto" unmountOnExit>
        <List disablePadding>
          <ListItemButton
            title="Open Table View"
            selected={location.pathname === process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/mappingTableView'}
            component={Link}
            to={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/mappingTableView'}
          >
            <ListItemText inset primary="Table View" align="left" />
          </ListItemButton>

          <ListItemButton
            title="Open Graph View"
            selected={location.pathname === process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/mappingGraphView'}
            component={Link}
            to={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/mappingGraphView'}
          >
            <ListItemText inset primary="Graph View" align="left" />
          </ListItemButton>

          {/** produces mappings between source ontology submitted by user and selected set of target ontologies **/}
          <ListItemButton
            title="Open External Mappings"
            selected={location.pathname === process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/generatemapping'}
            component={Link}
            to={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/generatemapping'}
          >
            <ListItemText inset primary="External Mappings" align="left" />
          </ListItemButton>
        </List>
      </Collapse>

      <Divider className={classes.divider} />
      <Typography className={classes.sectionHeader}>Ontology Application</Typography>
      <ListItemButton
        title="Open Annotation Service"
        selected={location.pathname === process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/annotation'}
        component={Link}
        to={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/annotation'}
      >
        <ListItemIcon>
          <AttachmentOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Annotation Service" />
      </ListItemButton>
        <Divider className={classes.divider} />
        <Typography className={classes.sectionHeader}>PID Checker</Typography>
        <ListItemButton
            title="Open PID Checker Service"
            selected={location.pathname === process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/pidChecker'}
            component={Link}
            to={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/pidChecker'}
        >
            <ListItemIcon>
                <CheckIcon />
            </ListItemIcon>
            <ListItemText primary="PID Checker Service" />
        </ListItemButton>
        <Divider className={classes.divider} />
        <Typography className={classes.sectionHeader}>Git Onto Service</Typography>
        <ListItemButton
            title="Open Git Onto Status Service"
            selected={location.pathname === process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/gitOntologyStatusCheck'}
            component={Link}
            to={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/gitOntologyStatusCheck'}
        >
            <ListItemIcon>
                <PublishedWithChangesIcon />
            </ListItemIcon>
            <ListItemText primary="Git Ontology Status" />
        </ListItemButton>
      {/* <ListItemButton
        title="Open Semantic Search"
        selected={location.pathname === process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/semanticSearch'}
        component={Link}
        to={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/semanticSearch'}
      >
        <ListItemIcon>
          <PolylineOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Semantic Search" />
      </ListItemButton> */}

      <Divider className={classes.divider} />
      <Typography className={classes.sectionHeader}>Ontology Creation</Typography>
      <ListItemButton
        title="Open Webprotege"
        selected={location.pathname === process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/webprotege'}
        component={Link}
        to={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/webprotege'}
      >
        <ListItemIcon>
          <CloudQueueIcon />
        </ListItemIcon>
        <ListItemText primary="WebProtege" />
      </ListItemButton>
      {/* <ListItemButton
        title="Open WebVowl"
        selected={location.pathname === process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox//webvowl'}
        component={Link}
        to={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/webvowl'}
      >
        <ListItemIcon>
          <LanguageOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="WebVOWL" />
      </ListItemButton> */}
      <Divider className={classes.divider} />
      <Typography className={classes.sectionHeader}>General</Typography>
      <ListItemButton
        title="Open Release Information"
        selected={location.pathname === process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/release'}
        component={Link}
        to={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/release'}
      >
        <ListItemIcon>
          <ArticleOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Release" />
      </ListItemButton>
      <ListItemButton
        title="Open Funding Information"
        selected={location.pathname === process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/funding'}
        component={Link}
        to={process.env.REACT_APP_PROJECT_SUB_PATH + '/sandbox/funding'}
      >
        <ListItemIcon>
          <HandshakeOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Funding" />
      </ListItemButton>
    </List>
  );
};
