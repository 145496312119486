import { Fab, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  text: {
    [theme.breakpoints.up('xl')]: {
      marginLeft: '10px',
    },
    [theme.breakpoints.down('xl')]: {
      display: 'none',
    },
  },
}));

export const FileIssue = () => {
  const classes = useStyles();
  const handleFileIssue = () => {
    window.open('https://gitlab.com/TIBHannover/terminology/tib-terminology-service-issue-tracker', '_blank');
  };
  return (
    <div style={{ display: 'flex' }}>
      <Fab aria-label="add" onClick={handleFileIssue}>
        <EditIcon style={{ color: 'white' }} />
        <Typography variant="button" className={classes.text}>
          File an Issue
        </Typography>
      </Fab>
    </div>
  );
};
