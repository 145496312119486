import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const Container = styled('div')({
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    maxWidth: '800px',
    margin: 'auto',
});

const ErrorCode = styled('div')({
    margin: '10px 0',
    padding: '10px',
    backgroundColor: '#fff3cd',
    borderLeft: '5px solid #ffc107',
    borderRadius: '4px',
    lineHeight: '1.5',
});


export const PIDCheckerServiceExplanation = () => {
    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                PID Checker Service Overview
            </Typography>
            <Typography variant="body1" gutterBottom>
                The PID Checker service is a vital tool within the NFDI4Energy project. It ensures that all Internationalized Resource Identifiers (IRIs) in our ontologies are accessible and resolvable. This service is crucial for maintaining the integrity and usability of ontology terms.
            </Typography>

            <Typography variant="h6" gutterBottom>
                Service Report Details
            </Typography>
            <Typography variant="body1" gutterBottom>
                The service provides a comprehensive report with the following columns:
            </Typography>
            <ul>
                <li><strong>Ontology ID:</strong> Displays the name of the ontology.</li>
                <li><strong>Number of IRIs:</strong> Shows the total count of IRIs within the ontology.</li>
                <li><strong>Number of Down IRIs:</strong> Indicates how many IRIs are currently unresolvable.</li>
                <li><strong>PID Patterns:</strong> Lists how many IRIs follow specific PID patterns such as DOI, Handle, PURL, URN, ARK, and W3ID. For example, "5 PURL, 10 DOI." If none, it states "No PID patterns available."</li>
            </ul>

            <Typography variant="body1" gutterBottom>
                For detailed insights on down IRIs, expand the specific ontology to reveal:
            </Typography>
            <ul>
                <li><strong>Specific IRI:</strong> The exact IRI that is currently down.</li>
                <li><strong>Last Time Checked:</strong> The date and time of the last verification.</li>
                <li><strong>Unresolvable Since:</strong> The date and time since the IRI became unresolvable.</li>
                <li><strong>Server Status:</strong> Displays one of the common HTTP error codes listed below.</li>
                <li><strong>Term Name:</strong> The term name associated with the IRI.</li>
            </ul>

            <Typography variant="h6" gutterBottom>
                Common HTTP Error Codes
            </Typography>
            <Typography variant="body1" gutterBottom>
                Here are some common HTTP error codes you might see in the Server Status:
            </Typography>

            <ErrorCode>
                <strong>404 Not Found:</strong><br />
                The requested resource cannot be located on the server. This typically means the IRI does not exist or is inaccessible.
            </ErrorCode>

            <ErrorCode>
                <strong>401 Unauthorized:</strong><br />
                Authentication is required to access this resource. Please log in or provide valid credentials.
            </ErrorCode>

            <ErrorCode>
                <strong>403 Forbidden:</strong><br />
                Access to the requested resource is denied. You may not have the necessary permissions.
            </ErrorCode>

            <ErrorCode>
                <strong>500 Internal Server Error:</strong><br />
                An error occurred on the server while processing your request. This is a general server-side error.
            </ErrorCode>

            <ErrorCode>
                <strong>Timeout Error:</strong><br />
                The server took too long to respond. This could be due to a slow connection or server overload.
            </ErrorCode>

            <ErrorCode>
                <strong>Client Error:</strong><br />
                The server cannot process the request due to a client-side error, such as malformed request syntax.
            </ErrorCode>

            <ErrorCode>
                <strong>Unexpected Error:</strong><br />
                An unexpected condition was encountered on the server, preventing it from fulfilling the request.
            </ErrorCode>
        </Container>
    );
};