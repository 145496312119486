import { useState, useCallback } from 'react';
import { Snackbar, Rating, Typography } from '@mui/material';

export const Stars = ({ url, ratingEntry, tool }) => {
  const [rating, setRating] = useState(0);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');

  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };

  const handleRatingChange = (event, newValue) => {
    setRating(newValue);
    handleSubmit(newValue);
  };

  const handleSubmit = useCallback(
    async (newValue) => {
      try {
        await fetch(`${url}&entry.${ratingEntry}=${newValue}`, {
          mode: 'no-cors',
        });
        setNotificationMessage(`Thanks for your feedback! Your rating for ${tool} has been successfully submitted.`);
        setNotificationOpen(true);
      } catch (e) {
        setNotificationMessage(e.message);
        setNotificationOpen(true);
      } finally {
        setRating(0);
      }
    },
    [url, ratingEntry]
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <Snackbar
        open={notificationOpen}
        autoHideDuration={4000}
        onClose={handleNotificationClose}
        message={notificationMessage}
        ContentProps={{
          sx: {
            color: 'black',
            backgroundColor: '#c0d6df',
          },
        }}
        style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
      />
      <Typography variant="caption">How do you rate </Typography>
      <Typography variant="caption">{tool} today</Typography>
      <Rating name="rating" value={rating} onChange={handleRatingChange} size="large" />
    </div>
  );
};
