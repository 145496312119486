import React, { useEffect, useState } from 'react';
import {getOntology, getPreviewData} from '../../../api/queryData';
import { Button, Select, TextField, Autocomplete, Container, Grid, MenuItem, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { OWLTypes } from './OWLTypes';
import ConceptTimeline from './ConceptTimeline';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  cont: {
    minHeight: 'calc(100vh - 305px)',
    minWidth: 'calc(100vw - 305px)',
  },
  rootPair: {
    marginLeft: theme.spacing(3),
    minHeight: '70vh',
    backgroundColor: 'white',
    borderRadius: ' 10px 10px 10px 10px',
    border: '1px solid',
    borderColor: theme.palette.grey[400],
  },
  typoPair: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    flexGrow: 1,
  },
  grid: {
    padding: theme.spacing(3),
  },
}));

export const Criteria = ({ id }) => {
  const steps = ['Select OWL type', 'Select axiom URI', 'Select commit period'];
  const classes = useStyles();

  const [ontology, setOntology] = useState(null);

  const [firstCommit, setFirstCommit] = useState('');
  const [secondCommit, setSecondCommit] = useState('');
  const [diffArray, setDiffArray] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [axiomType, setAxiomType] = useState('');
  const [axiomURIs, setAxiomURIs] = useState([]);
  const [selectedAxiomURI, setSelectedAxiomURI] = useState('');

  useEffect(() => {
    const fetchOntology = async () => {
      const response = await getOntology(id);
      if (response.ok) {
        const ontology = await response.json();
        console.log(ontology);
        setOntology(ontology);
      }
    };
    fetchOntology();
  }, [id]);

  useEffect(() => {
    if (ontology != null) {
      const filteredAxioms = ontology.diffs
          .filter((diff) => diff.axioms.added.some((axiom) => axiom.axiomType === axiomType))
          .map((diff) => diff.axioms.added.find((axiom) => axiom.axiomType === axiomType).axiomURI);

      const uniqueAxiomURIs = [...new Set(filteredAxioms)];

      setAxiomURIs(uniqueAxiomURIs);
      console.log(uniqueAxiomURIs);
    }

  }, [ontology, axiomType]);

  const handleAxiomURIChange = (event) => {
    setSelectedAxiomURI(event.target.value);
    console.log(axiomURIs);
  };

  const handleFirstCommitChange = (event) => {
    setFirstCommit(event.target.value);
    console.log(firstCommit);
  };

  const handleSecondCommitChange = (event) => {
    setSecondCommit(event.target.value);
    console.log(secondCommit);
  };

  const handleClick = async () => {
    const response = await getPreviewData(id, new Date(secondCommit), new Date(firstCommit));

    const data = await response.json();
    if (!response.ok) {
      throw new Error(`HTTP Error. Status: ${response.status}`);
    }
    setDiffArray(data);
  };

  const MenuProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    getContentAnchorEl: null,
    PaperProps: {
      style: {
        maxHeight: 300,
        width: 500,
      },
    },
  };

  const getSectionComponent = () => {
    switch (activeStep) {
      case 0:
        return (
            <Container>
              <Autocomplete
                  options={OWLTypes}
                  renderInput={(params) => <TextField {...params} label="Select OWL type" style={{width: 500}}/>}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 48 * 4.5 + 8,
                        width: '20ch',
                      },
                    },
                  }}
                  onChange={(event, value) => setAxiomType(value)}
              />
            </Container>
        );
      case 1:
        return (
            <Container>
              {axiomURIs.length === 0 ? (
                  <>
                    <div>
                      <Typography variant='info'>No axioms of that type</Typography>
                    </div>
                    <div>
                      <Select
                          value={selectedAxiomURI}
                          onChange={handleAxiomURIChange}
                          style={{width: 700}}
                      >
                        <MenuItem value=''>Select Axiom URI</MenuItem>
                        {axiomURIs.map((axiomURI) => (
                            <MenuItem key={axiomURI} value={axiomURI}>
                              {axiomURI}
                            </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </>
              ) : (
                  <Select
                      value={selectedAxiomURI}
                      onChange={handleAxiomURIChange}
                      style={{width: 700}}
                  >
                    <MenuItem value=''>Select Axiom URI</MenuItem>
                    {axiomURIs.map((axiomURI) => (
                        <MenuItem key={axiomURI} value={axiomURI}>
                          {axiomURI}
                        </MenuItem>
                    ))}
                  </Select>
              )}
            </Container>
        );
      case 2:
        return (
            <Container>
              <div className={classes.rootPair}>
                <Grid container spacing={0} md={12} className={classes.grid}>
                  <Grid item xs={12} md={3}>
                    <div>
                      <Typography variant="h5" className={classes.typoPair}>
                        Choose first commit:
                      </Typography>
                      <Select value={firstCommit} onChange={handleFirstCommitChange} MenuProps={MenuProps}
                              style={{maxWidth: 300}}>
                        {ontology?.diffs.map((commit) => (
                            <MenuItem key={commit.shaOffsetDateTime} value={commit.shaOffsetDateTime}>
                              {commit.message}
                            </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <br/>
                    <div>
                      <Typography variant="h5" className={classes.typoPair}>
                        Choose second commit:
                      </Typography>
                      <Select value={secondCommit} onChange={handleSecondCommitChange} MenuProps={MenuProps}
                              style={{maxWidth: 300}}>
                        {ontology?.diffs.map((commit) => (
                            <MenuItem key={commit.shaOffsetDateTime} value={commit.shaOffsetDateTime}>
                              {commit.message}
                            </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <br/>
                    <div>
                      <Button variant="contained" onClick={handleClick}>
                        Get diff
                      </Button>
                    </div>
                  </Grid>
                  <Grid container spacing={0} item xs={8} md={9} justifyContent="flex-end">
                    <div style={{maxHeight: '500px', overflow: 'auto'}}>{diffArray.length > 0 &&
                        <ConceptTimeline diffs={diffArray} axiomType={axiomType} axiomURI={selectedAxiomURI}/>}</div>
                  </Grid>
                </Grid>
              </div>
            </Container>
        );
      default:
        return (
            <Container>
              <Typography variant='body2'>
                Default case was somehow selected
              </Typography>
            </Container>
        )
    }
  };

  return (
      <Grid container>
        {/*<MiniDrawer />*/}
        {/*<Grid item md={1}></Grid>*/}
        {/*<Grid item md={0.5}></Grid>*/}
        <Grid item xs={12} md={12} className={classes.grid}>
          {/*<NavBreadcrumbs />*/}
          <Stepper activeStep={activeStep}>
            {steps.map((label) => {
              const stepProps = {};
              return (
                  <Step key={label} {...stepProps}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
              <div>
                <p>End</p>
              </div>
          ) : (
              <div style={{marginTop: 10}}>
                <Button color="inherit" variant="contained" disabled={activeStep === 0}
                        onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}>
                  Back
                </Button>
                &nbsp; &nbsp; &nbsp;
                <Button color="inherit" variant="contained"
                        onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}>
                  Next
                </Button>
                <br/>
                <br/>
                <div>{getSectionComponent()}</div>
              </div>
          )}
        </Grid>
      </Grid>
  );
};

Criteria.propTypes = {
  id: PropTypes.string.isRequired
}
