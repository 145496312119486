import { useEffect, useState, useMemo } from 'react';
import { Box, Typography, Grid, Link } from '@mui/material/';
import MaterialReactTable from 'material-react-table';
import DisplayMappingDetailsTabView from './DisplayMappingDetailsTabView';

export const TableViewMapping = () => {
  const [mappings, setMappings] = useState([]);

  //${process.env.REACT_APP_MAPPING_BACKEND_URL}
  useEffect(() => {
    fetch(`${process.env.REACT_APP_MAPPING_BACKEND_URL}/api/ontology/groupedmappings`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setMappings(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'sourceOntologyName',
        header: 'Source Ontology Name',
        muiTableHeadCellProps: {
          align: 'left',
        },
        size: 300,
      },

      {
        accessorKey: 'numberOfTargetOntologies',
        header: 'Number of Target Ontologies',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },

        size: 200,
      },
    ],
    []
  );

  const filteredMappings = [];

  mappings.forEach((outerItem) => {
    outerItem.sourceOntology.forEach((innerSourceItem) => {
      const newObj = {
        /** this id is used to filter mapppings based on the id value in nested JSON. See below row.original.id.**/
        id: outerItem.id,
        sourceOntologyName: `${innerSourceItem.title}`,
        numberOfTargetOntologies: `${outerItem.numberOfTargetOntologies}`,
      };

      /**
       * excludes all mappings OR conflictive mappings that are equal to zero.
       */
      if (outerItem.numberOfTargetOntologies > 0) {
        filteredMappings.push(newObj);
      }
    });
  });
  return (
    <Grid container justifyContent="center">
      <Grid item xs={10} md={11}>
        <Typography variant="h5" gutterBottom textAlign="center">
          Mappings
        </Typography>
        <Typography variant="body2" gutterBottom style={{ marginBottom: '1rem' }}>
          On this page users can explore and search mappings between pairs of ontologies produced by
          <Link href="https://www.cs.ox.ac.uk/isg/tools/LogMap/" target="_blank">
            {' '}
            LogMap
          </Link>{' '}
          ontology matching tool. There is no guarantee that all classes in union of O1 ∪ O2 ∪ M will be satisfiable, where O1 represents source ontology, O2 represents target ontology, and M
          represents set of mappings.
          <p></p>
          Users can also access to the REST API for the automatic exchange of information regarding mappings between ontologies. If interested, please also visit our{' '}
          <a href="https://service.tib.eu/sandbox/mappings/swagger-ui/index.html#/" target="_blank">
            Swagger{' '}
          </a>
          page to become familiar with the calls. We welcome feedback through our user survey or star rating system.
        </Typography>
        <MaterialReactTable
          columns={columns}
          data={filteredMappings}
          enableColumnResizing={true}
          autoResetPageIndex={false}
          enableStickyHeader={true}
          enableRowActions={true}
          muiTableBodyRowProps={({ row }) => ({
            sx: {
              backgroundColor: row.getIsExpanded() ? '#efefef' : 'white',
            },
          })}
          muiTableProps={{
            sx: {
              borderCollapse: 'collapse',
            },
          }}
          muiTableHeadCellProps={{
            sx: () => ({
              backgroundColor: '#D3D3D3',
            }),
          }}
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Actions',
              size: 10,
            },
          }}
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
              <DisplayMappingDetailsTabView mappings={mappings} row={row.original.id} table={table} />
            </Box>
          )}
          initialState={{ density: 'compact' }}
        />
      </Grid>
    </Grid>
  );
};
export default TableViewMapping;
