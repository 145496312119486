import { Typography } from '@mui/material';

export const SemanticSearchExplanation = () => {
  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Semantic Search
      </Typography>

      <Typography variant="body2" gutterBottom style={{ marginBottom: '1rem' }}>
        The Semantic Search Service is build making use of a Sentence-BERT(SBERT) model all-MiniLM-L6-v2. This model has been fine-tuned on a diverse set of datasets for semantic textual similarity
        tasks, allowing it to produce embeddings that are versatile across various use cases. It strikes a balance between speed and performance, enabling it to deliver meaningful results in real-time
        applications or large-scale processing environments, making it a popular choice for those needing efficient and accurate sentence representations.
      </Typography>
    </div>
  );
};
