import React, {useMemo, useState} from 'react';
import { Tabs, Tab, Typography, TableContainer, Table, TableRow, TableCell, TableBody, Link } from '@mui/material/';
import { TabPanel, a11yProps } from '../../theme/Theme';
import SearchMappingsPage from "../mappingsPage/SearchMappingsPage";
import MappingGraphForEachRow from "../mappingsGraph/MappingGraphForEachRow";


const GenerateMappingTab =({ mappings, row, sourceOntologyUrl, numberOfMappings, numberOfConflictiveMappings, targetOntologyTitle, targetOntologyId, table })=>{

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: 'sourceiri',
                header: 'Source IRI',
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
            {
                accessorKey: 'mappingDirection',
                header: 'Mapping Direction',
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
            },
            {
                accessorKey: 'targetiri',
                header: 'Target IRI',
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
            {
                accessorKey: 'structuralConfidenceMapping',
                header: 'Structural Confidence Mapping',
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
            },
            {
                accessorKey: 'confidence',
                header: 'Confidence',
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
            },
        ],
        []
    );
    const mappingType = {
        class: {
            typeOfMapping: 0,
            mappingDirections: {
                '-2': 'class equivalence',
                0: 'subclass of',
                '-1': 'superclass of',
            },
        },
        object: {
            typeOfMapping: 2,
            mappingDirections: {
                '-2': 'property equivalence',
                0: 'sub property of',
                '-1': 'super property of',
            },
        },
        datatype: {
            typeOfMapping: 1,
            mappingDirections: {
                '-2': 'property equivalence',
                0: 'sub property of',
                '-1': 'super property of',
            },
        },
        individual: {
            typeOfMapping: 3,
            mappingDirections: {
                '-2': 'same as',
            },
        },
    };

    return (

        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Typography variant="h5">Metrics</Typography>
            <TableContainer>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>Source Ontology URL:</TableCell>
                            <TableCell><Link href={sourceOntologyUrl} target="_blank" rel="noopener noreferrer">{sourceOntologyUrl}</Link></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Target Ontology URL:</TableCell>
                            <TableCell><Link href={row.original.targetOntologyUrl} target="_blank" rel="noopener noreferrer">{row.original.targetOntologyUrl}</Link> </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Target Ontology Title:</TableCell>
                            <TableCell>{row.original.targetOntologyTitle} </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Number of Mappings:</TableCell>
                            <TableCell>{numberOfMappings}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Number of Conflictive Mappings:</TableCell>
                            <TableCell>{numberOfConflictiveMappings}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <Tabs value={value} onChange={handleChange} TabIndicatorProps={{ style: { display: 'none' } }}>
                <Tab label="mappings" {...a11yProps(0)} />
                <Tab label="conflictive mappings" {...a11yProps(1)} />
                <Tab label="mappings graph" {...a11yProps(2)} />
            </Tabs>
            <div style={{ flex: 1 }}>
                <TabPanel value={value} index={0}>
                    <SearchMappingsPage mappings={mappings} id={row} targetOntologyId={row.original.targetOntologyId} columns={columns} mappingType={mappingType} typeOfMapping="mapping"/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <SearchMappingsPage mappings={mappings} id={row} targetOntologyId={row.original.targetOntologyId} columns={columns} mappingType={mappingType} typeOfMapping="conflictive"/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <MappingGraphForEachRow mappings={mappings} id={row} targetOntologyId={row.original.targetOntologyId} />
                </TabPanel>
            </div>

        </div>
    )

}; export default GenerateMappingTab;