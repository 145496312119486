import {
  getCommitDate,
  getCommitMessage,
  getCommitShaType,
  selectService,
} from "./queryData";

const makeDiff = async (
  leftUrl,
  rightUrl,
  commitSha,
  parentSha,
  parentOffsetDateTime,
  shaOffsetDateTime,
  commitDate,
  message,
  leftRawFile,
  rightRawFile,
) => {
  const response = await fetch(
    `${process.env.REACT_APP_DIFF_BACKEND_URL}/api/history/diff/add`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        gitUrlLeft: leftUrl,
        gitUrlRight: rightUrl,
        gitRawFileLeft: leftRawFile,
        gitRawFileRight: rightRawFile,
        sha: commitSha,
        parentSha: parentSha,
        shaOffsetDateTime: shaOffsetDateTime,
        parentOffsetDateTime: parentOffsetDateTime,
        messageLeft: message,
      }),
    },
  );
  return response.json();
};

export const robotService = {
  robotDiff: async (data, service, ontUrl, setCurrentCommit = null) => {
    const promises = [];
    for (let i = 0; i < data.length - 1; i++) {
      const key = getCommitShaType(service);
      const leftUrl = selectService(service).getRawFileByCommitURL(
        ontUrl,
        data[i][key],
      );
      const rightUrl = selectService(service).getRawFileByCommitURL(
        ontUrl,
        data[i + 1][key],
      );
      const leftRawFile = await selectService(service).getRawFileByCommit(
        ontUrl,
        data[i][key],
      );
      const rightRawFile = await selectService(service).getRawFileByCommit(
        ontUrl,
        data[i + 1][key],
      );
      const sha = data[i][key];
      const parentSha = data[i + 1][key];
      const leftTime = getCommitDate(service, data[i]);
      const rightTime = getCommitDate(service, data[i + 1]);
      const commitDate = getCommitDate(service, data[i + 1]);
      const message = getCommitMessage(service, data[i + 1]);

      const promise = makeDiff(
        leftUrl,
        rightUrl,
        sha,
        parentSha,
        leftTime,
        rightTime,
        commitDate,
        message,
        leftRawFile,
        rightRawFile,
      );

      console.log("promise from update table row", promise);
      try {
        const result = await promise;
        if (result) {
          promises.push(result);
        }
      } catch (error) {
        console.error(error);
      }
      if (setCurrentCommit !== null) {
        setCurrentCommit(i);
      }
    }
    return Promise.all(promises);
  },
};
