import React, {useState}  from 'react';
import {
    Backdrop,
    Box,
    Button,
    IconButton,
    Modal,
    Typography,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Tooltip,
    Link
} from "@mui/material";

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const MappingReport =({mappings, row, sourceOntologyUrl,targetOntologyTitle, targetOntologyUrl })=>{

    const [openModal, setOpenModal] = useState(false);

    const handleGenerateModalToggle = () => {
        setOpenModal(!openModal);
    };

    const boxStyle = {
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        top: '60%',
        left: '60%',
        transform: 'translate(-60%, -60%)',
        width: '80%',
        height: '90%',
        backgroundColor: 'white',
        border: '1px solid #d9dcdd',
        borderRadius: '4px',
        overflowY: 'scroll',
        overflowX: 'hidden',
    };

    return (
        <>
            {
            <IconButton onClick={handleGenerateModalToggle}>
             <Tooltip title="Mapping Exception or Reasoning Explanation">
            <InfoOutlinedIcon />
             </Tooltip>
            </IconButton>
            }

            <Modal
                open={openModal}
                onClose={handleGenerateModalToggle}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    invisible: true,
                    onClick: null,
                }}
            >
                <Box sx={boxStyle}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <Typography variant="h5">Metrics</Typography>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Source Ontology URL:</TableCell>
                                        <TableCell><Link href={sourceOntologyUrl} target="_blank" rel="noopener noreferrer">{sourceOntologyUrl}</Link></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Target Ontology URL:</TableCell>
                                        <TableCell><Link href={targetOntologyUrl} target="_blank" rel="noopener noreferrer">{targetOntologyUrl}</Link></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Target Ontology Title:</TableCell>
                                        <TableCell>{row.original.targetOntologyTitle} </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <Typography variant="h5">Explanation Details</Typography>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Explanation:</TableCell>
                                        <TableCell>{row.original.mappingException}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div style={{ position: 'sticky', bottom: '0', padding: '10px', zIndex: 1, background: '#d1d3d9', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="contained" color="secondary" onClick={handleGenerateModalToggle}>
                            Close
                        </Button>
                    </div>
                </Box>
            </Modal>

        </>

    )


}; export default MappingReport;