import React, { useState } from 'react';
import { Box, Modal, IconButton, Button, Backdrop, Typography, Tooltip } from '@mui/material';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import GenerateMappingTab from './GenerateMappingTab';

const boxStyle = {
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  top: '60%',
  left: '60%',
  transform: 'translate(-60%, -60%)',
  width: '80%',
  height: '90%',
  backgroundColor: 'white',
  border: '1px solid #d9dcdd',
  borderRadius: '4px',
  overflowY: 'scroll',
  overflowX: 'hidden',
};

const GenerateMappingDetails = ({ mappings, row, sourceOntologyUrl, numberOfMappings, numberOfConflictiveMappings, targetOntologyTitle, targetOntologyId, targetOntologyUrl, table }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleGenerateModalToggle = () => {
    setOpenModal(!openModal);
  };

  return (
    <>
      {
        /**
         * check if number of mappings or number of conflictive mappings are greater than zero than
         * it allows users to view mappings details in tables and as a graph. Otherwise does not
         * allow users to view mapping details because of exception that is thrown by mapping tool
         * (LogMap matcher)
         */
        numberOfConflictiveMappings > 0 || numberOfMappings > 0 ? (
          <IconButton onClick={handleGenerateModalToggle}>
            <Tooltip title="Mapping Details">
              <ExpandCircleDownOutlinedIcon />
            </Tooltip>
          </IconButton>
        ) : (
          <IconButton style={{ color: 'white', disabled: 'true' }}>
            <Tooltip title="No Mapping Details">
              <CheckBoxOutlineBlankIcon color="white" />
            </Tooltip>
          </IconButton>
        )
      }

      <Modal
        open={openModal}
        onClose={handleGenerateModalToggle}
        BackdropComponent={Backdrop}
        BackdropProps={{
          invisible: true,
          onClick: null,
        }}
      >
        <Box sx={boxStyle}>
          <div style={{ position: 'sticky', top: '0', zIndex: 1, background: '#d1d3d9', padding: '10px' }}>
            <Typography variant="h5" style={{ textAlign: 'center' }}>
              Mapping Details
            </Typography>
            <span onClick={handleGenerateModalToggle}></span>
          </div>
          <div style={{ overflowY: 'auto', padding: '1rem', flex: 1 }}>
            <Box>
              <GenerateMappingTab
                mappings={mappings}
                row={row}
                sourceOntologyUrl={sourceOntologyUrl}
                numberOfMappings={numberOfMappings}
                numberOfConflictiveMappings={numberOfConflictiveMappings}
                targetOntologyTitle={targetOntologyTitle}
                targetOntologyId={targetOntologyId}
                targetOntologyUrl={targetOntologyUrl}
                table={table}
              />
            </Box>
          </div>
          <div style={{ position: 'sticky', bottom: '0', padding: '10px', zIndex: 1, background: '#d1d3d9', display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" color="secondary" onClick={handleGenerateModalToggle}>
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};
export default GenerateMappingDetails;
