import { Fab, Typography } from '@mui/material';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  text: {
    [theme.breakpoints.up('xl')]: {
      marginLeft: '10px',
    },
    [theme.breakpoints.down('xl')]: {
      display: 'none',
    },
  },
}));

export const ReleaseInfo = ({ extension }) => {
  const classes = useStyles();
  const history = useHistory();

  const jumpToHeading = () => {
    history.push(`${process.env.REACT_APP_PROJECT_SUB_PATH}/sandbox/release?scrollTo=${extension}`);
  };

  return (
    <div style={{ display: 'flex', paddingBottom: '20px' }}>
      <Fab aria-label="add" onClick={jumpToHeading}>
        <ImportContactsIcon style={{ color: 'white' }} />
        <Typography variant="button" className={classes.text}>
          Release Info
        </Typography>
      </Fab>
    </div>
  );
};
