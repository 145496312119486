import React, { useEffect, useState, useMemo } from 'react';
import { Box, Typography, Grid, Link } from '@mui/material/';
import MaterialReactTable from 'material-react-table';
import { useStyles } from './muiStyles';

/**
 *
 * Author Nenad Krdzavac
 * Email neand.krdzavac@tib.eu
 *
 * Content of table that contains all mappings.
 *  - ID - not mandatory to be visible
 *  - source ontology collection
 *  - source ontology name
 *  - target ontology collection
 *  - target ontology name
 *  - number of mapping
 *  - number of conflictive mappings
 *
 */

export const MappingOntologyTableContent = () => {
  const [mappings, setMappings] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_MAPPING_BACKEND_URL}/api/ontology/allmappings`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setMappings(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'sourceOntologyName',
        header: 'Source Ontology Name',
        muiTableHeadCellProps: {
          align: 'left',
        },
      },

      {
        accessorKey: 'targetOntologyName',
        header: 'Target Ontology Name',
        muiTableHeadCellProps: {
          align: 'left',
        },
      },

      {
        accessorKey: 'numberOfMappings',
        header: 'Number of Mappings',
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'numberOfConflictiveMappings',
        header: 'Number of Conflitive Mappings',
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
    ],
    []
  );

  const filteredMappings = [];

  mappings.forEach((outerItem) => {
    outerItem.sourceOntology.forEach((innerSourceItem) => {
      outerItem.targetOntology.forEach((innerTargetItem) => {
        const newObj = {
          /** this id is used to filter mapppings based on the id value in nested JSON. See below row.original.id.**/
          id: outerItem.id,
          // sourceOntologyCollection: innerSourceItem.collection.join(', '),
          sourceOntologyName: `${innerSourceItem.title}`,
          // targetOntologyCollection: innerTargetItem.collection.join(', '),
          targetOntologyName: `${innerTargetItem.title}`,
          numberOfMappings: outerItem.numberOfMappings,
          numberOfConflictiveMappings: outerItem.numberOfConflictiveMappings,
        };

        /**
         * excludes all mappings OR conflictive mappings that are equal to zero.
         */
        if (outerItem.numberOfMappings > 0 || outerItem.numberOfConflictiveMappings > 0) {
          filteredMappings.push(newObj);
        }
      });
    });
  });
  return (
    <Grid container className={classes.cont}>
      <Grid item xs={10} md={12} className={classes.grid}>
        <Typography variant="h5" gutterBottom>
          Mappings
        </Typography>
        <Typography variant="body2">
          This page explains how to explore and search mappings between pair of ontologies produced by{' '}
          <Link href="https://www.cs.ox.ac.uk/isg/tools/LogMap/" target="_blank">
            {' '}
            LogMap
          </Link>{' '}
          ontology matching tool. Consequently, there is no guarantee that all classes in union of O1 ∪ O2 ∪ M will be satisfiable", where O1 is source ontology, O2 is target ontology, and M is set of
          mappings.
        </Typography>
      </Grid>

      <MaterialReactTable
        columns={columns}
        data={filteredMappings}
        enableColumnResizing={true}
        autoResetPageIndex={false}
        enableStickyHeader={true}
        enableRowActions={true}
        enableGrouping
        /**
         * Deafult view in mappings table is to group all results by first column
         */
        initialState={{
          grouping: ['sourceOntologyName'],
        }}
        /**
         * It changes background color of each row to white smoke colour when the row is expanded.
         * otherwise, background color of the row is white
         * solved by Dr FarFar Kheir Eddine, email: Kheir.Farfar@tib.eu
         **/
        muiTableBodyRowProps={({ isDetailPanel, row, table }) => ({
          sx: {
            backgroundColor: row.getIsExpanded() ? '#efefef' : 'white',
          },
        })}
        /**
         * Displays specific mapping details in tables and graphs
         * when expanding table row
         */
        // renderDetailPanel={({ row, table }) => (
        //
        //     <TabForRow mappings ={mappings} row={row} table={table}/>
        //
        //     )}

        muiTableProps={{
          sx: {
            borderCollapse: 'collapse',
          },
        }}
        /**
         * Defines background color for table headers
         * It is not needed to use useTheme hook.
         * It is enough to use the `sx` prop with the theme callback.
         */
        muiTableHeadCellProps={{
          sx: (theme) => ({
            backgroundColor: '#D3D3D3' /* lightgray color*/,
          }),
        }}
        /**
         * Displays specific mapping details in tables and graphs
         * in a new window.
         */
        renderRowActions={({ row, table }) => <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '3px' }}>{/*<DisplayMappingDetails mappings={mappings} row={row} table={table}/>*/}</Box>}
      />
    </Grid>
  );
};

export default MappingOntologyTableContent;
