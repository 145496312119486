
export const gitAachenService = {
    getFileCommits,
    getSinceFileCommits,
    getUserFromUrl,
    getRepoFromUrl,
    getBranchFromUrl,
    getEncodedPath,
    getRawFileByCommit,
    getGitFileContent,
    checkGitFileUpdated,
    getGitlabApiRawFileByCommit,

    getRawFileByCommitURL,
};

const headers = {
    'PRIVATE-TOKEN': process.env.REACT_APP_GITAACHEN_ACCESS_TOKEN
};

function getUserFromUrl(gitAachenUrl) {
    return new URL(gitAachenUrl).pathname.split('/')[1];
}

function getRepoFromUrl(gitAachenUrl) {
    const splitURL = new URL(gitAachenUrl).pathname.split('/');
    const hyphenIndex = splitURL.findIndex((elem, index) => index > 0 && elem === "-");

    return splitURL.slice(2, hyphenIndex).join("%2F");
}

function getBranchFromUrl(gitAachenUrl) {
    return new URL(gitAachenUrl).pathname.split('/')[5];
}

function getEncodedPath(gitAachenUrl) {
    return new URL(gitAachenUrl).pathname.split("/").splice(7).join("%2F");
}

async function getFileCommits(gitAachenUrl) {
    const user = getUserFromUrl(gitAachenUrl);
    const repo = getRepoFromUrl(gitAachenUrl);
    const encodedPath = getEncodedPath(gitAachenUrl);
    let commits = [];
    let page = 1;

    while (true) {
        const response = await fetch(`${process.env.REACT_APP_GITAACHEN_API_BASE_URL}/projects/${user}%2F${repo}/repository/commits?path=${encodedPath}&page=${page}`, { headers });
        const data = await response.json();
        commits = commits.concat(data);

        const nextPage = response.headers.get('x-next-page');
        if (!nextPage) {
            break;
        }

        page = parseInt(nextPage);
    }

    return commits;
}

async function getSinceFileCommits(gitAachenUrl, sinceDate) {
    const user = getUserFromUrl(gitAachenUrl);
    const repo = getRepoFromUrl(gitAachenUrl);
    const encodedPath = getEncodedPath(gitAachenUrl);

    const response = await fetch(`${process.env.REACT_APP_GITAACHEN_API_BASE_URL}/projects/${user}%2F${repo}/repository/commits?path=${encodedPath}&since=${sinceDate}`, { headers });
    return await response.json();
}

async function getRawFileByCommit(gitAachenUrl, commitId) {
    const user = getUserFromUrl(gitAachenUrl);
    const repo = getRepoFromUrl(gitAachenUrl);
    const encodedPath = getEncodedPath(gitAachenUrl);

    const response = await fetch(`${process.env.REACT_APP_GITAACHEN_API_BASE_URL}/projects/${user}%2F${repo}/repository/files/${encodedPath}/raw?ref=${commitId}`, { headers });

    return await response.text();
}

function getRawFileByCommitURL(gitAachenUrl, commitId) {
    const user = getUserFromUrl(gitAachenUrl);
    const repo = getRepoFromUrl(gitAachenUrl);
    const encodedPath = getEncodedPath(gitAachenUrl);

    return `https://gitlab.com/${user}/${repo}/-/raw/${commitId}/${encodedPath}`;
}

function getGitlabApiRawFileByCommit(gitAachenUrl, commitId) {
    const user = getUserFromUrl(gitAachenUrl);
    const repo = getRepoFromUrl(gitAachenUrl);
    const filePath = getEncodedPath(gitAachenUrl);

    return `${process.env.REACT_APP_GITAACHEN_API_BASE_URL}/projects/${user}%2F${repo}/repository/files/${filePath}/raw?ref=${commitId}`;
}

async function getGitFileContent(gitAachenUrl) {
    const branch = getBranchFromUrl(gitAachenUrl);
    const filePath = getEncodedPath(gitAachenUrl);
    const user = getUserFromUrl(gitAachenUrl);
    const repo = getRepoFromUrl(gitAachenUrl);

    const response = await fetch(`${process.env.REACT_APP_GITAACHEN_API_BASE_URL}/projects/${user}%2F${repo}/repository/files/${filePath}?ref=${branch}`,{ headers });
    const data = await response.json();

    return btoa(data['content']);
}

const getUserRepoByName = async gitAachenUrl => {
    const user = getUserFromUrl(gitAachenUrl);
    const repo = getRepoFromUrl(gitAachenUrl);

    const userRepo = await fetch(`${process.env.REACT_APP_GITAACHEN_API_BASE_URL}/projects/${user}%2F${repo}`, { headers });
    return await userRepo.json();
};

export const getGitlabLatestCommit = async gitAachenUrl => {
    const userRepo = await getUserRepoByName(gitAachenUrl);
    const branch = getBranchFromUrl(gitAachenUrl) || 'main';

    const response = await fetch(`${process.env.REACT_APP_GITAACHEN_API_BASE_URL}/projects/${userRepo['id']}/repository/commits/${branch}?per_page=1`, { headers });
    const data = await response.json();
    return data.id;
};

async function checkGitFileUpdated(gitAachenUrl, lastFetchedFileSha){
    return {
        status: 'latest',
        commitsBehind: 0,
        branch: 'main'
    }
}
