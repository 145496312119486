import { React, useEffect, useState } from 'react';
import { Box, Fab, Modal, Typography, Backdrop, Button } from '@mui/material';
import MappingsExplanation from '../../mappings/searchMappings/MappingsExplanation';
import ArticleIcon from '@mui/icons-material/Article';
import { makeStyles } from '@mui/styles';
import { useLocation, useHistory } from 'react-router';
import { WebprotegeExplanation } from '../../webprotege/WebprotegeExplanation';
import { DiffExplanation } from '../../historyManagement/DiffExplanation';
import { NERExplanation } from '../../ner/NERExplanation';
import { SemanticSearchExplanation } from '../../word2vec/SemanticSearchExplanation';
import { PIDCheckerServiceExplanation } from '../../pid/PIDCheckerServiceExplanation'
import { GitOntologyStatusCheckExplanation } from '../../gitOntologyStatusChecker/GitOntologyStatusCheckExplanation'

const useStyles = makeStyles((theme) => ({
  text: {
    [theme.breakpoints.up('xl')]: {
      marginLeft: '10px',
    },
    [theme.breakpoints.down('xl')]: {
      display: 'none',
    },
  },
}));

const style = {
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  height: '90%',
  backgroundColor: 'white',
  border: '1px solid #d9dcdd',
  borderRadius: '4px',
  overflowY: 'scroll',
  overflowX: 'hidden',
};

const closeBtnStyle = {
  position: 'absolute',
  top: '10px',
  right: '20px',
  fontWeight: 'bold',
  cursor: 'pointer',
};
/**
 *
 * Author Nenad Krdzavac
 * Email neand.krdzavac@tib.eu
 * */
const HowToUse = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [sectionId, setSectionId] = useState(null);

  useEffect(() => {
    // Check for the "howtouse" parameter in the URL when the component mounts
    const urlSearchParams = new URLSearchParams(location.search);
    if (urlSearchParams.get('howtouse') === 'true') {
      setOpen(true);
      const sectionId = urlSearchParams.get('sectionId');
      setSectionId(sectionId);
    }
  }, [location.search]);

  const handleModalToggle = () => {
    // Add or remove the "howtouse" parameter when toggling the modal
    const urlSearchParams = new URLSearchParams(location.search);
    if (!open) {
      urlSearchParams.set('howtouse', 'true');
    } else {
      urlSearchParams.delete('howtouse');
      urlSearchParams.delete('sectionId');
    }

    // Update the URL with the modified search parameters
    history.push({ search: urlSearchParams.toString() });

    // Toggle the modal state
    setOpen(!open);
  };
  const webprotege = location.pathname === '/ts/sandbox/webprotege';
  const annotation = location.pathname === '/ts/sandbox/annotation';
  const semanticSearch = location.pathname === '/ts/sandbox/semanticsearch';
  const diff =
      location.pathname === '/ts/sandbox/semanticdiff' ||
      location.pathname === '/ts/sandbox/semanticdiff/add' ||
      location.pathname === '/ts/sandbox/ondet' ||
      location.pathname === '/ts/sandbox/ondet/add';
  const mapping = location.pathname === '/ts/sandbox/mappingTableView' || location.pathname === '/ts/sandbox/mappingGraphView' || location.pathname === '/ts/sandbox/generatemapping';
  const pidCheckerService = location.pathname === '/ts/sandbox/pidChecker';
  const gitOntologyStatusChecker = location.pathname === '/ts/sandbox/gitOntologyStatusCheck';

  let toolName = '';
  if (webprotege) {
    toolName = 'WebProtege';
  } else if (annotation) {
    toolName = 'Annotation Service';
  } else if (semanticSearch) {
    toolName = 'Semantic Search';
  } else if (diff) {
    toolName = 'Semantic Diff';
  } else if (pidCheckerService) {
    toolName = 'PID Checker Service';
  } else if (gitOntologyStatusChecker) {
    toolName = 'Git Onto Status';
  } else {
    toolName = 'Mappings';
  }
  return (
      <div>
        <Fab onClick={handleModalToggle} style={{ height: '4rem' }}>
          <ArticleIcon style={{ color: 'white' }} />
          <Typography variant="button" className={classes.text} style={{ fontSize: '14px', lineHeight: '1.2' }}>
            How to use {toolName}
          </Typography>
        </Fab>
        <Modal
            open={open}
            onClose={handleModalToggle}
            BackdropComponent={Backdrop}
            BackdropProps={{
              invisible: true,
              onClick: null,
            }}
        >
          <Box sx={style}>
            <div style={{ position: 'sticky', top: '0', zIndex: 1, background: '#d1d3d9', padding: '10px' }}>
              <Typography variant="h5" style={{ textAlign: 'center' }}>
                How To Use
              </Typography>
              <span style={closeBtnStyle} onClick={handleModalToggle}>
              X
            </span>
            </div>
            <div style={{ overflowY: 'auto', padding: '1rem', flex: 1 }}>
              <Box>
                {annotation && <NERExplanation />}
                {semanticSearch && <SemanticSearchExplanation />}
                {mapping && <MappingsExplanation />}
                {webprotege && <WebprotegeExplanation />}
                {diff && <DiffExplanation sectionId={sectionId} />}
                {pidCheckerService && <PIDCheckerServiceExplanation />}
                {gitOntologyStatusChecker && <GitOntologyStatusCheckExplanation />}
              </Box>
            </div>
            <div style={{ position: 'sticky', bottom: '0', padding: '10px', zIndex: 1, background: '#d1d3d9', display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" color="secondary" onClick={handleModalToggle}>
                Close
              </Button>
            </div>
          </Box>
        </Modal>
      </div>
  );
};
export default HowToUse;