import React from 'react';
import { Typography, Grid, Box } from '@mui/material';

const DialogHeader = ({ title1, title2 }) => {
    return (
        <Grid container>
            <Grid item xs={12} md={4}>
                <Box display="flex" justifyContent="center" marginTop="8px">
                    <Typography variant="h6">{title1}</Typography>
                </Box>
            </Grid>
            <Grid item xs={12} md={8}>
                <Box display="flex" justifyContent="center" marginTop="8px">
                    <Typography variant="h6">{title2}</Typography>
                </Box>
            </Grid>
        </Grid>
    );
};

export default DialogHeader;
