import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Grid, Link, TextField, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';

const NerForm = () => {
  const [inputText, setInputText] = useState('');
  const [results, setResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [highlightedItems, setHighlightedItems] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ text: inputText }),
    };
    try {
      const response = await fetch(`https://service.tib.eu/sandbox/ner/api/customNER`, requestOptions);
      const data = await response.json();
      setResults(data);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleHover = (item) => {
    setHighlightedItems([item]);
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setHighlightedItems([]);
    setIsHovered(false);
  };

  const routeToTopConcepts = (res) => {
    const urlMap = {
      'Solar system astronomy': 'http://astrothesaurus.org/uat/1529',
      'Galactic and extragalactic astronomy': 'http://astrothesaurus.org/uat/563',
      'Observational astronomy': 'http://astrothesaurus.org/uat/1145',
      'Stellar astronomy': 'http://astrothesaurus.org/uat/1583',
      'Solar physics': 'http://astrothesaurus.org/uat/1476',
      'Interstellar medium': 'http://astrothesaurus.org/uat/847',
      'High energy astrophysics': 'http://astrothesaurus.org/uat/739',
      'Astrophysical processes': 'http://astrothesaurus.org/uat/104',
      Cosmology: 'http://astrothesaurus.org/uat/343',
      'Interdisciplinary astronomy': 'http://astrothesaurus.org/uat/804',
      'Exoplanet astronomy': 'http://astrothesaurus.org/uat/486',
    };
    const url = urlMap[res];
    if (url) {
      window.open(url, '_blank');
    }
  };

  const parseTextWithHighlights = () => {
    let replacedText = inputText;
    const jsxElements = [];

    results.forEach((result, index) => {
      const { label } = result;

      const isHovered = highlightedItems.some((highlightedItem) => highlightedItem.label && JSON.stringify(highlightedItem.topConcepts) === JSON.stringify(result.topConcepts));

      const jsxReplacement = (
        <HighlightedLabel key={`label_${index}`} isHovered={isHovered} style={{ backgroundColor: '#c0d6df' }} onMouseEnter={() => handleHover(result)} onMouseLeave={handleMouseLeave}>
          {label}
        </HighlightedLabel>
      );

      jsxElements.push(jsxReplacement);

      replacedText = replacedText.replace(new RegExp(label, 'g'), `@@${index}@@`);
    });

    const textWithHighlights = replacedText.split('@@').map((part, index) => {
      if (index % 2 === 0) {
        return part;
      } else {
        return jsxElements[parseInt(part, 10)];
      }
    });

    return textWithHighlights;
  };

  const handleReset = () => {
    setInputText('');
    setResults(null);
  };

  let content;

  if (isLoading) {
    content = (
      <LoadingContainer>
        <CircularProgress color="primary" />
      </LoadingContainer>
    );
  } else if (results) {
    const uniqueTopConcepts = new Set();

    const sidebarItems = results.map((result, index) => {
      const topConcepts = result.topConcepts;
      const isUniqueTopConcepts = !uniqueTopConcepts.has(JSON.stringify(topConcepts));

      if (isUniqueTopConcepts) {
        uniqueTopConcepts.add(JSON.stringify(topConcepts));
      } else {
        return null;
      }

      const isItemHighlighted = highlightedItems.some((highlightedItem) => highlightedItem.label && JSON.stringify(highlightedItem.topConcepts) === JSON.stringify(result.topConcepts));

      return (
        <HighlightedConcept key={index} isHovered={isItemHighlighted} onMouseEnter={() => handleHover(result)} onMouseLeave={handleMouseLeave}>
          {topConcepts.map((concept, conceptIndex) => (
            <li key={`concept_${conceptIndex}`} onClick={() => routeToTopConcepts(concept)}>
              {concept}
            </li>
          ))}
        </HighlightedConcept>
      );
    });

    const filteredSidebarItems = sidebarItems.filter((item) => item !== null);

    let modifiedText = parseTextWithHighlights();
    content = (
      <ResultsContainer>
        <ResultsText>
          {modifiedText.map((element, index) => (
            <span key={`highlighted_${index}`}>{element}</span>
          ))}
        </ResultsText>
        <ResultsSidebar>
          <ResultsTitle>UAT Terms</ResultsTitle>
          <>{filteredSidebarItems}</>
        </ResultsSidebar>
      </ResultsContainer>
    );
  }

  const handleCopyText = () => {
    navigator.clipboard.writeText('Thermally created scalar and vector Higgs portal dark matter are constrained and al this containes some of the example like the Solar flares is this working.');
    setInputText('Thermally created scalar and vector Higgs portal dark matter are constrained and al this containes some of the example like the Solar flares is this working.');
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <Grid container justifyContent="center">
      <Grid item md={11}>
        <Typography variant="h5" gutterBottom textAlign="center">
          Annotation Service
        </Typography>

        <Typography variant="body2" gutterBottom>
          The tool is intended to demonstrate the annotation of texts by reusing ontological resources in a prototypical way at first.
        </Typography>
        <Typography variant="body2" gutterBottom>
          <b>Status:</b> We currently offer the annotation tool to support classification tasks by releasing entity recognition using the eleven parent terms of the{' '}
          <Link href="https://astrothesaurus.org/" target="_blank" underline="hover" rel="noopener">
            Unified Astronomy Thesaurus (UAT){' '}
          </Link>
          <Link href="https://terminology.nfdi4ing.de/ts/ontologies/uat" target="_blank" underline="hover" rel="noopener">
            ontology
          </Link>
          .
        </Typography>
        <Typography variant="body2" gutterBottom style={{ marginBottom: '2rem' }}>
          <b>Future Work:</b> The tool will be extended successively with further functionalities. Among other things, this concerns the performance, the removal of the restriction to recognise only
          the 11 parent UAT terms, but also the transfer to other ontologies.
        </Typography>
        <Input
          label={<span style={{ fontWeight: 'bold' }}>Enter Text</span>}
          required
          variant="outlined"
          id="filled-multiline-flexible"
          color="primary"
          size="small"
          onChange={(e) => setInputText(e.target.value)}
          value={inputText}
          multiline
          rows={6}
          fullWidth
          focused
          className="ner-form-input"
          helperText={
            <Container>
              <HelperText>
                <b>Example Text:</b>{' '}
                <i>Thermally created scalar and vector Higgs portal dark matter are constrained and al this containes some of the example like the Solar flares is this working.</i>
              </HelperText>
              <CopyButton onClick={handleCopyText}>
                <FileCopyOutlinedIcon style={{ height: 20, margin: 0 }} />
              </CopyButton>
              {copied && <CopiedText>Example text copied</CopiedText>}
            </Container>
          }
        />
        <ButtonContainer>
          <SearchButton variant="contained" color="primary" onClick={handleSubmit} className="ner-form-button">
            Annotate
          </SearchButton>
          <ResetButton variant="contained" color="primary" onClick={handleReset}>
            Reset
          </ResetButton>
        </ButtonContainer>
        <OutputContainer>{content}</OutputContainer>
      </Grid>
    </Grid>
  );
};

export default NerForm;

// Styled components...

const Container = styled('div')`
  display: flex;
  align-items: center;
`;

const HelperText = styled('div')`
  // flex: 1;
`;

const CopyButton = styled(IconButton)`
  margin-left: 8px;
`;

const CopiedText = styled('span')`
  margin-left: 8px;
  color: #4f6d7a;
  font-weight: bold;
`;

const Input = styled(TextField)`
  && {
    margin-bottom: 10px;
  }
`;

const SearchButton = styled(Button)`
  && {
    margin-top: 10px;
  }
`;

const ResetButton = styled(Button)`
  && {
    margin-top: 10px;
    margin-left: 10px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const OutputContainer = styled.p`
  margin-top: 20px;
  text-align: justify;
  padding-right: 10px;
  line-height: 2;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
`;

const ResultsContainer = styled.div`
  display: flex;
`;

const ResultsText = styled.div`
  float: left;
  width: 80%;
`;

const ResultsSidebar = styled.div`
  float: left;
  margin-left: 5%;
  width: 25%;
`;

const ResultsTitle = styled.h4`
  text-align: left;
  font-weight: bold;
`;

const HighlightedLabel = styled.span`
  text-decoration: underline;
  padding: 4px;
  color: ${({ isHovered }) => (isHovered ? '#dd6e42' : 'black')};
  font-weight: normal;
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    text-decoration: underline;
    transform: scale(1.3);
  }
`;

const HighlightedConcept = styled.li`
  text-decoration: underline;
  color: ${({ isHovered }) => (isHovered ? '#dd6e42' : '#4f6d7a')};
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  list-style-type: none;
  text-algin: left;
`;
