import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import nfdi4ing_logo_1 from '../../../assets/img/sandbox_logos/nfdi4ing_logo_1.svg';

const useStyles = makeStyles((theme) => ({
  text: {
    [theme.breakpoints.down('xl')]: {
      display: 'none',
    },
  },
}));

export const Nfdi4ingLogo = () => {
  const classes = useStyles();
  return (
    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="body2" className={classes.text}>
        Developed within
      </Typography>
      <img src={nfdi4ing_logo_1} width="100%" height="50%" />
    </Box>
  );
};
