import React, { useState, useEffect } from 'react';
import { Tab, Tabs, LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { a11yProps, TabPanel } from '../theme/Theme';
import { DataAssessment } from './DataAssessment';
import { CommunityAssessment } from './CommunityAssessment';
import { CheckCircle, Cancel } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(3),
    minHeight: '70vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tab: {
    fontWeight: 'bolder',
    backgroundColor: 'none',
    borderRadius: '5px 5px 0px 0px',
  },
  tabs: {
    transform: 'translate(0px, 1px)',
    '& .Mui-selected': {
      borderRight: '1px solid',
      borderTop: '1px solid',
      borderLeft: '1px solid',
      borderRightColor: theme.palette.grey[400],
      borderLeftColor: theme.palette.grey[400],
      borderTopColor: theme.palette.grey[400],
    },
  },
  tabpanel: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    minHeight: '70vh',
    padding: theme.spacing(3),
    border: '1px solid',
    borderColor: theme.palette.grey[400],
    borderRadius: ' 0 0px 10px 10px',
  },
  progressBar: {
    width: '100%',
    '& .MuiLinearProgress-root': {
      height: '20px',
      color: theme.palette.primary.light,
    },
  },
}));

// Constants for calculation
const PERCENTAGE_MULTIPLIER = 33.33;

export const QualityAssessment = ({ repositoryData, githubOntologies }) => {
  const classes = useStyles();
  const [dataAssessmentRows, setDataAssessmentRows] = useState([]);
  const [communityAssessmentRows, setCommunityAssessmentRows] = useState([]);
  const [value, setValue] = useState(0);
  const [totalCount, setTotalCount] = useState({
    starsCount: 0,
    forkCount: 0,
    watchersCount: 0,
  });


  useEffect(() => {
    const dataAssessmentRows = getDataAssessmentRows();
    const communityAssessmentRows = getCommunityAssessmentRows();
    setDataAssessmentRows(dataAssessmentRows);
    setCommunityAssessmentRows(communityAssessmentRows);

    const maxStars = Math.max(...communityAssessmentRows.map((row) => row.starsCount || 0));
    const maxForks = Math.max(...communityAssessmentRows.map((row) => row.forkCount || 0));
    const maxWatchers = Math.max(...communityAssessmentRows.map((row) => row.watchersCount || 0));

    setTotalCount({
      starsCount: maxStars,
      forkCount: maxForks,
      watchersCount: maxWatchers,
    });
  }, [repositoryData, githubOntologies]);

  const RenderProgress = ({ percentage }) => (
      <div className={classes.progressBar}>
        <LinearProgress variant="determinate" value={percentage} color="primary" />
      </div>
  );

  const getDataAssessmentRows = () => {
    return repositoryData.reduce((rows, _, index) => {
      if (index % 3 === 0) {
        rows.push({
          id: index / 3 + 1,
          ontologyName: githubOntologies[index / 3]?.label,
          license: repositoryData[index].data.license || false,
          readme: repositoryData[index + 2]?.data?.files?.readme || false,
          release: repositoryData[index + 1]?.data.length ? true : false,
        });
      }
      return rows;
    }, []);
  };

  const getCommunityAssessmentRows = () => {
    return repositoryData.reduce((rows, _, index) => {
      if (index % 3 === 0) {
        rows.push({
          id: index / 3 + 1,
          ontologyName: githubOntologies[index / 3]?.label,
          forkCount: repositoryData[index].data.forks_count,
          starsCount: repositoryData[index].data.stargazers_count,
          watchersCount: repositoryData[index].data.subscribers_count,
        });
      }
      return rows;
    }, []);
  };

  const handleChange = (event, newValue) => setValue(newValue);

  const calculateCommunityPercentage = (row) => {
    let percentage = 0;
    const { starsCount, forkCount, watchersCount } = row;

    if (totalCount.starsCount > 0) {
      percentage += (starsCount / totalCount.starsCount) * PERCENTAGE_MULTIPLIER;
    }
    if (totalCount.forkCount > 0) {
      percentage += (forkCount / totalCount.forkCount) * PERCENTAGE_MULTIPLIER;
    }
    if (totalCount.watchersCount > 0) {
      percentage += (watchersCount / totalCount.watchersCount) * PERCENTAGE_MULTIPLIER;
    }

    return Math.min(percentage, 100);
  };

  const calculateDataPercentage = (row) => {
    let percentage = 0;
    if (row.license) percentage += PERCENTAGE_MULTIPLIER;
    if (row.readme) percentage += PERCENTAGE_MULTIPLIER;
    if (row.release) percentage += PERCENTAGE_MULTIPLIER;
    return percentage;
  };

  const dataAssessmentColumns = [
    {
      accessorKey: 'id',
      header: 'ID',
      size: 100,
      Cell: ({ cell }) => <div style={{ fontSize: '15px' }}>{cell.getValue()}</div>,
    },
    {
      accessorKey: 'ontologyName',
      header: 'Ontology',
      size: 250,
      Cell: ({ cell }) => <div style={{ fontSize: '15px' }}>{cell.getValue()}</div>,
    },
    {
      accessorKey: 'license',
      header: 'License',
      size: 150,
      Cell: ({ cell }) => (
          <div>
            {cell.getValue() ? <CheckCircle color="success" /> : <Cancel color="error" />}
          </div>
      ),
    },
    {
      accessorKey: 'readme',
      header: 'ReadMe',
      size: 150,
      Cell: ({ cell }) => (
          <div>
            {cell.getValue() ? <CheckCircle color="success" /> : <Cancel color="error" />}
          </div>
      ),
    },
    {
      accessorKey: 'release',
      header: 'Release',
      size: 150,
      Cell: ({ cell }) => (
          <div>
            {cell.getValue() ? <CheckCircle color="success" /> : <Cancel color="error" />}
          </div>
      ),
    },
    {
      accessorKey: 'overallMeasure',
      header: 'Overall Measure',
      Cell: ({ row }) => <RenderProgress percentage={calculateDataPercentage(row.original)} />,
      align: 'center',
    },
  ];

  const communityAssessmentColumns = [
    {
      accessorKey: 'id',
      header: 'ID',
      size: 100,
      Cell: ({ cell }) => <div style={{ fontSize: '15px' }}>{cell.getValue()}</div>,
    },
    {
      accessorKey: 'ontologyName',
      header: 'Ontology',
      size: 250,
      Cell: ({ cell }) => <div style={{ fontSize: '15px' }}>{cell.getValue()}</div>,
    },
    {
      accessorKey: 'forkCount',
      header: 'Fork Count',
      size: 150,
      Cell: ({ cell }) => <div style={{ fontSize: '15px' }}>{cell.getValue()}</div>,
    },
    {
      accessorKey: 'starsCount',
      header: 'Stars Count',
      size: 150,
      Cell: ({ cell }) => <div style={{ fontSize: '15px' }}>{cell.getValue()}</div>,
    },
    {
      accessorKey: 'watchersCount',
      header: 'Watchers Count',
      size: 160,
      Cell: ({ cell }) => <div style={{ fontSize: '17px' }}>{cell.getValue()}</div>,
    },
    {
      accessorKey: 'overallMeasure',
      header: 'Overall Measure',
      Cell: ({ row }) => <RenderProgress percentage={calculateCommunityPercentage(row.original)} />,
    },
  ];

  return (
      <div className={classes.root}>
        <Tabs
            onChange={handleChange}
            value={value}
            className={classes.tabs}
            TabIndicatorProps={{ style: { display: 'none' } }}
        >
          <Tab label="Data Assessment" className={classes.tab} {...a11yProps(0)} />
          <Tab label="Community Assessment" className={classes.tab} {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0} className={classes.tabpanel}>
          <DataAssessment dataAssessmentRows={dataAssessmentRows} dataAssessmentColumns={dataAssessmentColumns} />
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.tabpanel}>
          <CommunityAssessment communityAssessmentRows={communityAssessmentRows} communityAssessmentColumns={communityAssessmentColumns} />
        </TabPanel>
      </div>
  );
};