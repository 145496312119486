import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import logo_CoyPu from '../../../../assets/img/sandbox_logos/logo_CoyPu.svg';

const useStyles = makeStyles((theme) => ({
  text: {
    [theme.breakpoints.down('xl')]: {
      display: 'none',
    },
  },
}));

/**
 * Author Nenad Krdzavac
 * Email neand.krdzavac@tib.eu
 */
const CoypuLogo = () => {
  const classes = useStyles();
  return (
    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="body2" className={classes.text}>
        Developed within
      </Typography>
      <img src={logo_CoyPu} width="100%" height="35%" />
    </Box>
  );
};

export default CoypuLogo;
