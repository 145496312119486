import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Autocomplete,
  Grid,
  Typography,
  Paper,
  Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(6),
  },
  timeline: {
    maxHeight: "800px",
    overflowY: "auto",
  },
}));

export const CriteriaConto = ({ id }) => {
  const styles = useStyles();

  const today = new Date();
  const startOfCurrentMonth = new Date(
    today.getFullYear(),
    today.getMonth(),
    1,
  );
  const [startDate, setStartDate] = useState(new Date(startOfCurrentMonth.setUTCHours(0, 0, 0, 1)));
  const [endDate, setEndDate] = useState(new Date(new Date().setUTCHours(23, 59, 59, 999)));

  const [operationData, setOperationData] = useState([]);
  const [selectedAxiomURI, setSelectedAxiomURI] = useState(null);
  const [ontologies, setOntologies] = useState([]);
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    const fetchOperationsData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_DIFF_BACKEND_URL}/api/history/conto/operationsData?uri=${id}`,
      );
      if (response.ok) {
        const data = await response.json();
        const formattedData = data
          .filter((uri) => uri !== "http://www.w3.org/2002/07/owl#Thing")
          .map((uri) => ({
            fullUri: uri,
            lastFragment: formatUriFragment(uri),
          }));
        setOperationData(formattedData);
      }
    };
    fetchOperationsData();
  }, [id]);

  const formatUriFragment = (uri) => {
    const fragments = uri.split("/");
    let lastFragment = fragments[fragments.length - 1];
    if (lastFragment.includes("#")) {
      lastFragment = lastFragment.split("#")[1];
    }

    return lastFragment;
  };

  const handleStartDate = (date) => {
    date.toISOString()
    date.setUTCHours(0, 0, 0, 1);
    setStartDate(date);
  }

  const handleEndDate = (date) => {
    date.toISOString()
    date.setUTCHours(23, 59, 59, 999);
    setEndDate(date);
  }

  const handleClick = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_DIFF_BACKEND_URL}/api/history/conto/versionsElem?uri=${id}&resourceUri=${encodeURIComponent(selectedAxiomURI.fullUri)}&firstDate=${startDate.toISOString()}&secondDate=${endDate.toISOString()}`,
    );

    const data = await response.json();
    setOntologies(Object.entries(data).sort().reverse());
    setFetched(true);
  };

  return (
    <Grid container>
      <Grid item xs={3} className={styles.gridItem}>
        <div>
          <Autocomplete
            value={selectedAxiomURI}
            options={operationData}
            getOptionLabel={(option) => option.lastFragment}
            renderOption={(props, option) => (
              <Tooltip title={option.fullUri} arrow placement="right">
                <li {...props}>{option.lastFragment}</li>
              </Tooltip>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Select Axiom URI" />
            )}
            onChange={(event, newValue) => {
              setSelectedAxiomURI(newValue);
              setOntologies([]);
              setFetched(false);
            }}
          />
        </div>
        <div>
          <Typography varian="body2">Click or prompt the start date</Typography>
          <DatePicker
            showIcon
            selected={startDate}
            onChange={(date) => handleStartDate(date)}
            selectsStart
            maxDate={endDate}
            className={styles.datepicker}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
        </div>
        <div>
          <Typography varian="body2">Click or prompt the end date</Typography>
          <DatePicker
            showIcon
            selected={endDate}
            onChange={(date) => handleEndDate(date)}
            selectsEnd
            minDate={startDate}
            className={styles.datepicker}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
        </div>
        <div>
          <Button variant="contained" onClick={handleClick}>
            Get timeline
          </Button>
        </div>
      </Grid>
      <Grid item xs={7} className={styles.timeline}>
        {fetched === false && (
          <Typography variant="h5">
            In this view, you can see the evolution of a specific axiom in an
            ontology.
            <br />
            To see the information: first select an axiom URI from the dropdown
            list, and then define the period by selecting the start and end
            dates, during which the axiom changed.
          </Typography>
        )}
        {fetched === true && ontologies.length === 0 && (
          <Typography variant="h5">
            Searched axiom URI:{" "}
            <a href={selectedAxiomURI.fullUri} target="_blank">
              {selectedAxiomURI.fullUri}
            </a>{" "}
            was not found in a chosen period.
            <br />
            Try increasing the interval.
          </Typography>
        )}
        {fetched === true && ontologies.length > 0 && (
          <Timeline>
            {ontologies.map(([commitTime, timelineMessages], index) => (
              <TimelineItem key={index}>
                <TimelineOppositeContent>
                  <Typography variant="body2" color="textSecondary">
                    {new Date(commitTime).toLocaleString()}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} sx={{ p: 2 }}>
                    {timelineMessages.map((diff, idx) => (
                      <>
                        <Typography key={idx} variant="h6" component="h6">
                          {diff.label} {diff.predicate} {diff.object}
                        </Typography>
                        <br />
                      </>
                    ))}
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        )}
      </Grid>
    </Grid>
  );
};

CriteriaConto.propTypes = {
  id: PropTypes.string.isRequired,
};
