import React, { useEffect, useState } from "react";
import { Grid, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { DiffTable } from "./DiffTable";
import { robotService } from "../../../api/robot.service";

export const HistoryManagement = () => {
  const [ontologies, setOntologies] = useState([]);
  const [ontologiesLoaded, setOntologiesLoaded] = useState(false);

  const fetchOntologies = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DIFF_BACKEND_URL}/api/history/ontologies`,
      );
      const data = await response.json();
      setOntologies(data);
      setOntologiesLoaded(true);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchOntologies();
  }, []);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={11}>
        <div className="target-element3">
          <Typography variant="h5" gutterBottom textAlign="center">
            Semantic Diff
          </Typography>
          <Typography>
            The Semantic Diff Tool calculates Semantic Differences between
            commits of Ontologies managed by the GIT-system.
            <br />
          </Typography>
          <Typography variant="body2">
            You can: <br />
            - Search in the History;
            <br />
            - Create Reports;
            <br />
            <Typography variant="body2">
              More about the main menu find{" "}
              <Link
                component={RouterLink}
                to={"?howtouse=true&sectionId=mainView"}
              >
                here
              </Link>
            </Typography>
          </Typography>
          <br />
          <Typography>
            If you want to add further ontologies, please go to{" "}
            <Link
              component={RouterLink}
              to={
                process.env.REACT_APP_PROJECT_SUB_PATH +
                "/sandbox/semanticdiff/add"
              }
            >
              Track changes
            </Link>
          </Typography>
          <br />
          {ontologiesLoaded ? (
            <DiffTable
              ontologies={ontologies}
              apiUrl={`${process.env.REACT_APP_DIFF_BACKEND_URL}/api/history/ontologies`}
              makeDiff={robotService.robotDiff}
              onUpdated={fetchOntologies}
              onDeleted={fetchOntologies}
              onSaved={fetchOntologies}
            />
          ) : (
            <p>Loading ontologies...</p>
          )}
        </div>
      </Grid>
    </Grid>
  );
};
