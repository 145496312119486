import { Grid, Typography } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

export const FundingInfo = () => {
  const location = useLocation();
  const nfdi4ingRef = useRef(null);
  const coypuRef = useRef(null);
  const fairdsRef = useRef(null);
  const nfdi4energyRef = useRef(null);

  const jsonData = {
    NFDI4Ing: {
      id: 'NFDI4ING',
      html_id: 'NFDI4ING',
      name: 'NFDI4Ing Project',
      text: 'The aim of NFDI4Ing is to create a common environment for the safe management and reuse of engineering research data. Initiated by a bottom-up process, NFDI4Ing offers a unique method-oriented and user-centred approach using so-called archetypes. These archetypes describe research methods and processes used in engineering subdisciplines.',
      logo: '/nfdi4ing_logo.svg',
      ontology_list_url: '/ontologies?collection=NFDI4ING&and=false&page=1',
      project_homepage: 'https://nfdi4ing.de/',
      domain_ts_link: 'https://terminology.nfdi4ing.de/ts/',
      ref: nfdi4ingRef,
      funded_by: 'Deutsche Forschungsgemeinschaft (DFG)',
      project_number: '442146713',
    },
    CoyPu: {
      id: 'CoyPu',
      html_id: 'CoyPu',
      name: 'CoyPu Project',
      text: 'In an increasingly networked corporate world, the company-specific variables relevant from a crisis management perspective are in very complex relationships and dynamic interactions with a large number of external factors (e.g., location, customers, competition, suppliers, personnel market, legal and social framework conditions). These external interactions in turn lead to far-reaching internal (domino) effects and interdependencies in all areas of the company (operational, financial, strategic) (Fig. 1, right). Neither internal company expertise nor personal or local consultant knowledge is therefore sufficient to generate valid, economically usable insights or to derive concrete crisis-related entrepreneurial measures.',
      logo: '/logo_CoyPu.svg',
      ontology_list_url: '/ontologies?collection=CoyPu&and=false&page=1',
      project_homepage: 'https://coypu.org/',
      domain_ts_link: '',
      ref: coypuRef,
      funded_by: 'Bundesministerium für Wirtschaft und Klimaschutz (BMWK)',
      project_number: '01MK21007[A-L]',
    },
    'FAIR Data Spaces': {
      id: 'FAIR Data Spaces',
      html_id: 'FAIR Data Spaces',
      name: 'FAIR Data Spaces Project',
      text: 'The FAIR Data Spaces project is funded by the Federal Ministry of Education and Research (BMBF) from May 2021 to May 2024. In this project, the Gaia-X federated and secure data infrastructure and the National Research Data Infrastructure (NFDI) are connected to a common, cloud-based data space for industry and research in compliance with the FAIR Principles, i.e., to share data in a findable, accessible, interoperable, reusable way.',
      logo: '/FAIR_DS_Logo_RGB.svg',
      ontology_list_url: '/ontologies?collection=FAIR+Data+Spaces&and=false&page=1',
      project_homepage: 'https://websites.fraunhofer.de/fair-ds/',
      domain_ts_link: '',
      ref: fairdsRef,
      funded_by: 'Bundesministerium für Bildung und Forschung (BMBF)',
      project_number: '53000029',
    },
    'NFDI4Energy': {
      id: 'NFDI4Energy',
      html_id: 'NFDI4Energy',
      name: 'NFDI4Energy Project',
      text: 'The goal of the community-driven initiative NFDI4Energy is to establish a sustainable, transparent, and reproducible research infrastructure that supports the entire research and transfer cycle. This cycle encompasses everything from identifying relevant competencies and defining research scenarios to integrating models, publishing results, and identifying new research questions.The key focus areas of NFDI4Energy are centered on the creation of a shared research services for FAIR data, software, and processes. By providing best practices, competence centers, registries, and simulation tools, we are building services that enable the practical application of research findings and contributes to shaping the future of interdisciplinary energy system research.',
      logo: '/logo_NFDI4ENERGY.svg',
      ontology_list_url: 'ontologies?and=false&sortedBy=title&page=1&collection=NFDI4Energy',
      project_homepage: 'https://nfdi4energy.uol.de/',
      domain_ts_link: '',
      ref: nfdi4energyRef,
      funded_by: 'Deutsche Forschungsgemeinschaft (DFG)',
      project_number: '501865131',
    },
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const scrollTo = searchParams.get('scrollTo');

    if (scrollTo === 'nfdi4ing') {
      nfdi4ingRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (scrollTo === 'coypu') {
      coypuRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (scrollTo === 'fairds') {
      fairdsRef.current.scrollIntoView({ behavior: 'smooth' });
    }else if (scrollTo === 'nfdi4energy') {
      nfdi4energyRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location.search]);

  return (
    <Grid container spacing={5}>
      {Object.values(jsonData).map((item, index) => (
        <>
          <Grid item sm={12} md={4}>
            <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <CardMedia image={item.logo} title={item.name} />
              <CardContent style={{ display: 'flex', flexDirection: 'column', height: 'inherit' }}>
                <Typography gutterBottom variant="body1" sx={{ flexGrow: 1 }}>
                  {item.text}
                </Typography>
                <div style={{ justifySelf: 'flex-end', marginTop: 'auto' }}>
                  <Typography variant="body1">Funded By:</Typography>
                  <Typography variant="caption">{item.funded_by}</Typography>
                  <Typography variant="body1">Project Number:</Typography>
                  <Typography variant="caption">{item.project_number}</Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </>
      ))}
    </Grid>
  );
};
