import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { marked } from "marked";
import {getPreviewData} from "../../../api/queryData";
import makeStyles from "@mui/styles/makeStyles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import { jsPDF } from 'jspdf';
import PropTypes from "prop-types";
const PAGE_SIZE = 13;

const testhtml = '<!DOCTYPE html>\n' +
    '<html lang="en">\n' +
    '<head>\n' +
    '    <meta charset="utf-8">\n' +
    '    <title>\n' +
    '        Report from 2022-11-30 to 2023-12-21\n' +
    '    </title>\n' +
    '</head>\n' +
    '<body>\n' +
    '<h2>Aggregated Information</h2>\n' +
    '<h4>Overall number of added axioms is: <strong>179</strong></h4>\n' +
    '<h4>Overall number of removed axioms is: <strong>150</strong></h4>\n' +
    '<h4>Most frequent axiom is: <a href="http://purl.org/dc/terms/contributor">http://purl.org/dc/terms/contributor</a></h4>\n' +
    '<h4>Number of appearances is: <strong>7</strong></h4>\n' +
    '<h3>Ontology imports</h3>\n' +
    '<h3>Ontology annotations</h3>\n' +
    '<h3>Ontology imports</h3>\n' +
    '<h3>Ontology annotations</h3>\n' +
    '<h3>has snow? Probably not <code>https://schema.coypu.org/global#hasEmail</code></h3>\n' +
    '<h4>Added</h4>\n' +
    '<ul>\n' +
    '    <li><p><a href="https://schema.coypu.org/global#hasEmail">has snow? Probably not</a> <a href="http://www.w3.org/2000/01/rdf-schema#comment">comment</a> &quot;A bright and joyful atmosphere.&quot;@en </p>\n' +
    '    </li>\n' +
    '    <li><p><a href="https://schema.coypu.org/global#hasEmail">has snow? Probably not</a> <a href="http://www.w3.org/2000/01/rdf-schema#label">label</a> &quot;has snow? Probably not&quot;@en </p>\n' +
    '    </li>\n' +
    '    <li><p><a href="https://schema.coypu.org/global#hasEmail">has snow? Probably not</a> <a href="http://www.w3.org/2000/01/rdf-schema#seeAlso">seeAlso</a> <a href="https://schema.org/xmas">xmas</a> </p>\n' +
    '    </li>\n' +
    '    <li><p><a href="https://schema.coypu.org/global#hasEmail">has snow? Probably not</a> Domain <a href="https://schema.coypu.org/global#Feierabend">Feierabend</a></p>\n' +
    '    </li>\n' +
    '</ul>\n' +
    '<h3>Ontology imports</h3>\n' +
    '<h3>Ontology annotations</h3>\n' +
    '<h3>datetime <code>http://www.w3.org/2001/XMLSchema#datetime</code></h3>\n' +
    '<h4>Added</h4>\n' +
    '<ul>\n' +
    '    <li><a href="http://www.w3.org/2001/XMLSchema#datetime">datetime</a></li>\n' +
    '</ul>\n' +
    '<h3>Ontology imports</h3>\n' +
    '<h3>Ontology annotations</h3>\n' +
    '<h3>has phone number <code>https://schema.coypu.org/global#hasPhoneNumber</code></h3>\n' +
    '<h4>Removed</h4>\n' +
    '<ul>\n' +
    '    <li><p><a href="https://schema.coypu.org/global#hasPhoneNumber">has phone number</a> <a href="http://www.w3.org/2000/01/rdf-schema#comment">comment</a> &quot;A company&#39;s phone number (should be normalized).&quot;@en </p>\n' +
    '    </li>\n' +
    '    <li><p><a href="https://schema.coypu.org/global#hasPhoneNumber">has phone number</a> <a href="http://www.w3.org/2000/01/rdf-schema#label">label</a> &quot;has phone number&quot;@en </p>\n' +
    '    </li>\n' +
    '    <li><p><a href="https://schema.coypu.org/global#hasPhoneNumber">has phone number</a> <a href="http://www.w3.org/2000/01/rdf-schema#seeAlso">seeAlso</a> <a href="https://schema.org/telephone">telephone</a> </p>\n' +
    '    </li>\n' +
    '    <li><p><a href="https://schema.coypu.org/global#hasPhoneNumber">has phone number</a> Domain <a href="https://schema.coypu.org/global#Company">Company</a> </p>\n' +
    '    </li>\n' +
    '    <li><p><a href="https://schema.coypu.org/global#hasPhoneNumber">has phone number</a> Range: <a href="http://www.w3.org/2001/XMLSchema#string">string</a> </p>\n' +
    '    </li>\n' +
    '    <li><p>DataProperty: <a href="https://schema.coypu.org/global#hasPhoneNumber">has phone number</a> </p>\n' +
    '    </li>\n' +
    '    <li><p>Functional: <a href="https://schema.coypu.org/global#hasPhoneNumber">has phone number</a></p>\n' +
    '    </li>\n' +
    '</ul>\n' +
    '<h3>Ontology imports</h3>\n' +
    '<h3>Ontology annotations</h3>\n' +
    '<h3>has hs code <code>https://schema.coypu.org/global#hasHsCode</code></h3>\n' +
    '<h4>Removed</h4>\n' +
    '<ul>\n' +
    '    <li><p><a href="https://schema.coypu.org/global#hasHsCode">has hs code</a> <a href="http://www.w3.org/2000/01/rdf-schema#comment">comment</a> &quot;A product code as defined in the EU harmonized system.&quot;@en </p>\n' +
    '    </li>\n' +
    '    <li><p><a href="https://schema.coypu.org/global#hasHsCode">has hs code</a> <a href="http://www.w3.org/2000/01/rdf-schema#label">label</a> &quot;has hs code&quot;@en </p>\n' +
    '    </li>\n' +
    '    <li><p><a href="https://schema.coypu.org/global#hasHsCode">has hs code</a> Domain <a href="https://schema.coypu.org/global#Product">Product</a> </p>\n' +
    '    </li>\n' +
    '    <li><p><a href="https://schema.coypu.org/global#hasHsCode">has hs code</a> Range: <a href="http://www.w3.org/2001/XMLSchema#string">string</a> </p>\n' +
    '    </li>\n' +
    '    <li><p>DataProperty: <a href="https://schema.coypu.org/global#hasHsCode">has hs code</a> </p>\n' +
    '    </li>\n' +
    '    <li><p>Functional: <a href="https://schema.coypu.org/global#hasHsCode">has hs code</a></p>\n' +
    '    </li>\n' +
    '</ul>\n' +
    '<h3>Ontology imports</h3>\n' +
    '<h3>Ontology annotations</h3>\n' +
    '<h3>Ontology imports</h3>\n' +
    '<h3>Ontology annotations</h3>\n' +
    '<h3>Ontology imports</h3>\n' +
    '<h3>Ontology annotations</h3>\n' +
    '<h3>has population <code>https://schema.coypu.org/global#hasPopulation</code></h3>\n' +
    '<h4>Removed</h4>\n' +
    '<ul>\n' +
    '    <li><p><a href="https://schema.coypu.org/global#hasPopulation">has population</a> <a href="http://www.w3.org/2000/01/rdf-schema#label">label</a> &quot;has population&quot;@en </p>\n' +
    '    </li>\n' +
    '    <li><p><a href="https://schema.coypu.org/global#hasPopulation">has population</a> <a href="http://www.w3.org/2000/01/rdf-schema#comment">comment</a> &quot;The number of people inhabiting a country or city.&quot;@en </p>\n' +
    '    </li>\n' +
    '    <li><p><a href="https://schema.coypu.org/global#hasPopulation">has population</a> Domain <a href="http://www.w3.org/2002/07/owl#Thing">Thing</a> </p>\n' +
    '    </li>\n' +
    '    <li><p><a href="https://schema.coypu.org/global#hasPopulation">has population</a> Range: <a href="http://www.w3.org/2001/XMLSchema#positiveInteger">positiveInteger</a> </p>\n' +
    '    </li>\n' +
    '    <li><p>DataProperty: <a href="https://schema.coypu.org/global#hasPopulation">has population</a> </p>\n' +
    '    </li>\n' +
    '    <li><p>Functional: <a href="https://schema.coypu.org/global#hasPopulation">has population</a></p>\n' +
    '    </li>\n' +
    '</ul>\n' +
    '<h3>Ontology imports</h3>\n' +
    '<h3>Ontology annotations</h3>\n' +
    '<h3>member of <code>https://schema.coypu.org/global#memberOf</code></h3>\n' +
    '<h4>Added</h4>\n' +
    '<ul>\n' +
    '    <li><a href="https://schema.coypu.org/global#memberOf">member of</a> <a href="http://www.w3.org/2000/01/rdf-schema#label">label</a> &quot;member of&quot;@en</li>\n' +
    '</ul>\n' +
    '<h3>Ontology imports</h3>\n' +
    '<h3>Ontology annotations</h3>\n' +
    '<h3>memberOf <code>https://schema.coypu.org/global#memberOf</code></h3>\n' +
    '<h4>Added</h4>\n' +
    '<ul>\n' +
    '    <li><p>ObjectProperty: <a href="https://schema.coypu.org/global#memberOf">memberOf</a> </p>\n' +
    '    </li>\n' +
    '    <li><p><a href="https://schema.coypu.org/global#memberOf">memberOf</a> Domain <a href="https://schema.coypu.org/global#Country">Country</a> </p>\n' +
    '    </li>\n' +
    '    <li><p><a href="https://schema.coypu.org/global#memberOf">memberOf</a> Range <a href="https://schema.coypu.org/global#InternationalOrganization">InternationalOrganization</a></p>\n' +
    '    </li>\n' +
    '</ul>\n' +
    '<h3>Ontology imports</h3>\n' +
    '<h3>Ontology annotations</h3>\n' +
    '<h3>Remote explosive or landmine <code>https://schema.coypu.org/global#RemoteExplosiveOrLandmineOrIED</code></h3>\n' +
    '<h4>Removed</h4>\n' +
    '<ul>\n' +
    '    <li><p><a href="https://schema.coypu.org/global#RemoteExplosiveOrLandmineOrIED">Remote explosive or landmine</a> <a href="http://www.w3.org/2000/01/rdf-schema#label">label</a> &quot;Remote explosive or landmine&quot;@en </p>\n' +
    '    </li>\n' +
    '    <li><p>Class: <a href="https://schema.coypu.org/global#RemoteExplosiveOrLandmineOrIED">Remote explosive or landmine</a> </p>\n' +
    '    </li>\n' +
    '    <li><p><a href="https://schema.coypu.org/global#RemoteExplosiveOrLandmineOrIED">Remote explosive or landmine</a> SubClassOf <a href="https://schema.coypu.org/global#ExplosionOrRemoteViolence">Explosion or remote violence</a></p>\n' +
    '    </li>\n' +
    '</ul>\n' +
    '<h3>Remote explosive or landmine or IED <code>https://schema.coypu.org/global#RemoteExplosiveOrLandmineOrIed</code></h3>\n' +
    '<h4>Added</h4>\n' +
    '<ul>\n' +
    '    <li><p><a href="https://schema.coypu.org/global#RemoteExplosiveOrLandmineOrIed">Remote explosive or landmine or IED</a> <a href="http://www.w3.org/2000/01/rdf-schema#label">label</a> &quot;Remote explosive or landmine or IED&quot;@en </p>\n' +
    '    </li>\n' +
    '    <li><p>Class: <a href="https://schema.coypu.org/global#RemoteExplosiveOrLandmineOrIed">Remote explosive or landmine or IED</a> </p>\n' +
    '    </li>\n' +
    '    <li><p><a href="https://schema.coypu.org/global#RemoteExplosiveOrLandmineOrIed">Remote explosive or landmine or IED</a> SubClassOf <a href="https://schema.coypu.org/global#ExplosionOrRemoteViolence">Explosion or remote violence</a></p>\n' +
    '    </li>\n' +
    '</ul>\n' +
    '<h3>Ontology imports</h3>\n' +
    '<h3>Ontology annotations</h3>\n' +
    '<h4>Removed</h4>\n' +
    '<ul>\n' +
    '    <li><p><a href="http://purl.org/dc/terms/modified">modified</a> &quot;2022-02-13&quot;^^<a href="http://www.w3.org/2001/XMLSchema#date">date</a> </p>\n' +
    '    </li>\n' +
    '    <li><p><a href="http://www.w3.org/2002/07/owl#priorVersion">priorVersion</a> <a href="https://schema.coypu.org/global/2.1">2.1</a></p>\n' +
    '    </li>\n' +
    '</ul>\n' +
    '<h4>Added</h4>\n' +
    '<ul>\n' +
    '    <li><p><a href="http://purl.org/dc/terms/modified">modified</a> &quot;2023-02-13&quot;^^<a href="http://www.w3.org/2001/XMLSchema#date">date</a> </p>\n' +
    '    </li>\n' +
    '    <li><p><a href="http://www.w3.org/2002/07/owl#priorVersion">priorVersion</a> <a href="https://schema.coypu.org/global/2.2">2.2</a></p>\n' +
    '    </li>\n' +
    '</ul>\n' +
    '</body>\n' +
    '</html>'

const MARKDOWN_FILE_START_REGEX = new RegExp('# Ontology comparison\\n\\n## Left\\n- Ontology IRI: .+\\n- Version IRI: .+\\n- Loaded from: .+\\n\\n## Right\\n- Ontology IRI: .+\\n- Version IRI: .+\\n- Loaded from: .+\\n\\n');

const useStyles = makeStyles((theme) => ({
    markdownGrid: {
        minHeight: '80%',
    },
    markdownContent : {
        fontSize: '14px',
    },
    buttonContainer: {
        position: 'fixed',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        gap: theme.spacing(2),
    },
    datepicker: {
        width: '400px'
    }
}));


const Report = ({ id }) => {
    const styles = useStyles();
    const today = new Date();
    const startOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    const [startDate, setStartDate] = useState(startOfCurrentMonth);
    const [endDate, setEndDate] = useState(new Date());
    const [data, setData] = useState('');
    const [loading, setLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
    };

    const startIndex = (currentPage - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;
    const currentPageData = data.split('\n\n').slice(startIndex, endIndex).join('\n\n');

    const handleDataPreview = async () => {
        setLoading(true);
        let markdownContent = '';

        try {
            const response = await getPreviewData(id, startDate, endDate);
            if (response.ok) {
                const jsonContent = await response.json();
                const mainHeader = `## Aggregated Information\n`;
                let totalRemovedAxioms = 0, totalAddedAxioms = 0, markdownFile = '',
                    maxFrequentAxiom = '';
                for (const diff of jsonContent) {
                    totalRemovedAxioms += diff.axioms.removed.length;
                    totalAddedAxioms += diff.axioms.added.length;
                    markdownFile += diff.markdown.file.split(MARKDOWN_FILE_START_REGEX)[1] + '\n\n';
                }

                if (jsonContent.length > 1) {
                    let mergedAxioms = [];
                    jsonContent.forEach((diff) => {
                        mergedAxioms.push(...diff.axioms.added.map((axiom) => axiom.axiomURI));
                    })
                    maxFrequentAxiom = getMode(mergedAxioms);
                    console.log('maxfreq: ', maxFrequentAxiom);
                }
                markdownContent = mainHeader +
                    `#### Overall number of added axioms is: **${totalAddedAxioms}**\n` +
                    `#### Overall number of removed axioms is: **${totalRemovedAxioms}**\n`;

                if (maxFrequentAxiom.value !== undefined) {
                    markdownContent += '#### Most frequent axiom is: ' + maxFrequentAxiom.value + '\n' +
                        `#### Number of appearances is: **${maxFrequentAxiom.count}**\n`;
                }

                markdownContent += markdownFile;
                setData(markdownContent);
            } else {
                console.error('Error:', response.status);
            }
        } finally {
            setLoading(false);
        }
    }
    const getMode = (array) => {
        const buildFrequencyMap = (array) => {
            return array.reduce((acu, number) => {
                acu[number] = acu[number] !== undefined ? acu[number] + 1 : 0
                return acu;
            }, {})
        }

        const maxFrequency = (frequencyMap) => {
            let maxFreq = -1, result = -1;
            Object.entries(frequencyMap).forEach(([key, value]) => {
                if (value > maxFreq) {
                    maxFreq = value;
                    result = key;
                }
            });
            return { value: result, count: maxFreq };
        }

        const frequencyMap = buildFrequencyMap(array);
        return maxFrequency(frequencyMap);
    }

    const generateHTMLFile = async (htmlContent, pdfStartDate, pdfEndDate) => {
        const htmlContentWithDate = `Report from ${pdfStartDate.toISOString().split('T')[0]} to ${pdfEndDate.toISOString().split('T')[0]}\n\n` + htmlContent;
        const htmlMarked = await marked.parse(htmlContentWithDate)
        const blob = new Blob([htmlMarked], {type: 'text/html'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `report - ${pdfStartDate.toISOString().split('T')[0]} - ${pdfEndDate.toISOString().split('T')[0]}.html`;
        link.click();
    };

    const generatePdfFile = async (htmlContent, pdfStartDate, pdfEndDate) => {
        const pdf = new jsPDF();
        console.log(testhtml);
        pdf.html(testhtml, {
            callback: function (pdf) {
                pdf.save('report.pdf');
            },
            x: 10,
            y: 10,
        })
    }

    return (
        <Card sx={{ padding: 2 }}>
            <CardContent>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={4}>
                        <Typography varian='body2'>
                            Click or prompt the start date
                        </Typography>
                        <DatePicker
                            showIcon
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            className={styles.datepicker}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography varian='body2'>
                            Click or prompt the end date
                        </Typography>
                        <DatePicker
                            showIcon
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            className={styles.datepicker}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Button
                            variant="contained"
                            onClick={handleDataPreview}
                            disabled={loading}
                            fullWidth
                        >
                            Preview Data
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Button
                            variant="contained"
                            onClick={() => generateHTMLFile(data, startDate, endDate)}
                            disabled={data.length === 0 || loading}
                            fullWidth
                        >
                            Generate Report
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => generatePdfFile(data, startDate, endDate)}
                            disabled={data.length === 0 || loading}
                            fullWidth
                            style={{display: 'none'}}
                        >
                            Generate PDF-Report
                        </Button>
                    </Grid>
                </Grid>

                {currentPageData && !loading && (
                    <Grid className={styles.markdownGrid}>
                        <ReactMarkdown className={styles.markdownContent}>{currentPageData}</ReactMarkdown>
                    </Grid>
                )}

                {data.split('\n\n').length > PAGE_SIZE && (
                    <Grid container direction="column" alignItems="center" sx={{ marginTop: 2 }}>
                        <Grid item container justifyContent="space-between">
                            <Grid className={styles.buttonContainer}>
                                <Button
                                    variant="contained"
                                    onClick={handlePrevPage}
                                    disabled={currentPage === 1}
                                >
                                    Previous Page
                                </Button>
                                <Typography variant="subtitle1">
                                    Page {currentPage} of {Math.ceil(data.split('\n\n').length / PAGE_SIZE)}
                                </Typography>
                                <Button
                                    variant="contained"
                                    onClick={handleNextPage}
                                    disabled={endIndex >= data.split('\n\n').length}
                                >
                                    Next Page
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                )}
            </CardContent>
        </Card>
    );
}

Report.propTypes = {
    id: PropTypes.string.isRequired
}
export default Report;
