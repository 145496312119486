import { ForceGraph2D } from 'react-force-graph';
import { SizeMe } from 'react-sizeme';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { Box, Modal, Backdrop, Typography, Button, Grid, Link } from '@mui/material';
import TableForRowTargetOntologies from '../searchMappings/TableForRowTargetOntologies';

const MappingGraphBetweenOntologies = () => {

  const style = {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '90%',
    backgroundColor: 'white',
    border: '1px solid #d9dcdd',
    borderRadius: '4px',
    overflowY: 'scroll',
    overflowX: 'hidden',
  };

  const closeBtnStyle = {
    position: 'absolute',
    top: '10px',
    right: '20px',
    fontWeight: 'bold',
    cursor: 'pointer',
  };

    /**
     * This boolean variable controls whether default value is deselected in order to remove graph from dashboard.
     * Solution is proposed by Farfar, Kheir Eddine <Kheir.Farfar@tib.eu> .
     */
  const[isUsingDefaultValue, setIsUsingDefaultValue] = useState(true);

  const [mappings, setMappings] = useState([]);
  const [select, setSelect] = useState([]);
  const [graph, setGraph] = useState([]);

    /**
     * default values for select components.
     */
  const[initialDefaultCollection, setInitialDefaultCollection] = useState({value:'NFDI4ING',label:'NFDI4ING'})

  const[initialDefaultOntologyIds, setInitialDefaultOntologyIds] = useState([
      { value: 'physh', label: 'physh'  }, //physh
      { value: 'kitmtxx', label: 'kitmtxx' } //kitmtxx
  ])

  /**
   * Const variable saves ontology id assigned to source ontology
   */
  const [selectNodeId, setSelectNodeId] = useState(1);
  const [open, setOpen] = useState(false);

  /**
   * selected source ontology ids list.
   */
  const [selectSourceNodes, setSelectSourceNodes] = useState([]);

  const handleModalToggle = () => {
    setOpen(!open);
  };

  //${process.env.REACT_APP_MAPPING_BACKEND_URL}
  useEffect(() => {
    fetch(`${process.env.REACT_APP_MAPPING_BACKEND_URL}/api/ontology/groupedmappings`)
      .then((response) => response.json())
      .then((data) => {
        setMappings(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const handleOpen = (node) => {
    if (containsSourceNode(graph, node.id) === true) {
      setOpen(true);
      setSelectNodeId(node.id);
    }
  };

  const onChange = (selectedOptions) => {

 setSelect(selectedOptions);

 setIsUsingDefaultValue(false);

 console.log("selected options: ", selectedOptions);

  };

  /**
   * selected collection to be
   * @type {*[]}
   */
  const selectedCollections = [];

  for (const element of select) {

  selectedCollections.push(element.value);

   }

  /**
   * crates a unique JSON array of ontology collections
   * @type {*[]}
   */
  const collections = createCollections(mappings).filter(() => (thing, index, self) => index === self.findIndex((t) => t.id === thing.id));

    /**
     * sort collection names in alphabetical ascending order.
     */

  collections.sort((a,b)=>a.value.localeCompare(b.value));

  /**
   * Filters graph generated from selected collection to extract only source ontology ids (source nodes).
   * @type {*[]}
   */
  const sourceNodes = mappings.filter(() => (thing, index, self) => index === self.findIndex((t) => containsSourceNode(graph, thing.id) === true));

  const onChangeSourceOntologies = (selectedSourceNodeOptions) => {

  setSelectSourceNodes(selectedSourceNodeOptions);

  };

  const selectedOntology = [];

  const selectedSourceOntologies = [];
  const selectedSourceOntologiesTemp = [];

  /**
   * Creates an array of source ontology ids based on selected collections
   */
  sourceNodes.forEach((item) => {
    item.sourceOntology.forEach((sourceItem) => {
      select.forEach((item) => {
        /**
         * stores in React Select of source ontology ids that belond to selected list of collections.
         */
        if (sourceItem.collection.includes(item.value)) {
          selectedSourceOntologiesTemp.push({ value: sourceItem.ontologyId, label: sourceItem.ontologyId });
        }
      });
    });

  return selectedSourceOntologiesTemp;

  });

  selectedSourceOntologiesTemp.forEach((item) => {

  let findOntologyId = selectedSourceOntologies.find((x) => x.value === item.value);

  if (!findOntologyId) selectedSourceOntologies.push({ value: item.value, label: item.value });


  /**
   * sorting ontology ids in alphabetical ascending order.
   */
  selectedSourceOntologies.sort((a,b)=>a.value.localeCompare(b.value));

  });

  let countOntology = 0;

  for (const elementNode of selectSourceNodes) {

  countOntology = countOntology + 1;

  selectedOntology.push(elementNode.value);

  }

  // const [defaultValues, setDefaultValues] = useState(null);

    //`http://localhost:8080/api/ontology/groupedmappings/filterby?collection=`+selectedJSON.join(",")
  useEffect(() => {
        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                setGraph(data);
            })
            .catch((err) => {
                console.log(err.message);
            });

    }, [selectedCollections.join(','), selectedOntology.join(',')]);

  let url = [];

  if (selectedCollections.join(',').length === 0 && selectedOntology.join(',').length === 0) {

  /**
  * If - else statement is proposed by Farfar, Kheir Eddine <Kheir.Farfar@tib.eu> in order to remove initial graph
   * when a user deselects default collection name and default ontology ids.
  */
  if(isUsingDefaultValue) {

  url = `${process.env.REACT_APP_MAPPING_BACKEND_URL}/api/ontology/groupedmappings/filterby/source?collection=NFDI4ING&sourceontologyids=physh,kitmtxx`;

  } else {

  url = `${process.env.REACT_APP_MAPPING_BACKEND_URL}/api/ontology/groupedmappings/filterby/source?collection=&sourceontologyids=`;

  }

  }

  if (selectedCollections.join(',').length > 0 && selectedOntology.join(',').length === 0) {
    url = `${process.env.REACT_APP_MAPPING_BACKEND_URL}/api/ontology/groupedmappings/filterby?collection=` + selectedCollections.join(',');
  }

 if (selectedCollections.join(',').length > 0 && selectedOntology.join(',').length > 0) {
    url = `${process.env.REACT_APP_MAPPING_BACKEND_URL}/api/ontology/groupedmappings/filterby/source?collection=` + selectedCollections.join(',') + '&sourceontologyids=' + selectedOntology.join(',');
 }

  /**
   * select all ontology collection names from generated JSON from backend code.
   * @param obj
   * @returns {*[]}
   *
   */
  function createCollections(obj) {

    const collectionTemp = [];

    for (const key in obj) {
      const value = obj[key];

      if (key === 'collection') {
        for (const key1 in obj[key]) {
          /**
           * selects all members of collection object
           */
          collectionTemp.push({ value: value[key1], label: value[key1] });
        }
      } else if (typeof value === 'object' && value !== null) {
        collectionTemp.push(...createCollections(value));
      }
    }

    const collectionNames = [];

    /**
     * get unique collection names.
     */
    collectionTemp.forEach((item) => {

      let findCollection = collectionNames.find((x) => x.value === item.value);

      if (!findCollection) collectionNames.push({ value: item.value, label: item.value });

      return collectionNames;
    });

    return collectionNames;
  }

  /**
   * crates a unique JSON array of objects after filtering JSON array created by
   * crateNodes function
   * @type {*[]}
   */
  const nodes = createNodes(graph).filter((thing, index, self) => index === self.findIndex((t) => t.id === thing.id));

  /**
   * recursive function that creates a simple JSON array of ontology titles from
   * complex nested JSON that is responded by the backend code (REST API).
   * @returns {*[]}
   */
  function createNodes(obj) {

    const result = [];

    for (const key in obj) {

      const value = obj[key];

      if (typeof value === 'object' && value !== null) {
        result.push(...createNodes(value));
      } else {
        if (key === 'ontologyId') {
          result.push({ id: obj.ontologyId, uri: obj.uri, title: obj.title, collection: obj.collection });
        }
      }
    }

  return result;

  }

  /**
   * create links between ontology titles from nested JSON object
   *
   * @type {*[]}
   */
  const links = [];

  {
    graph.forEach((outerItem) => {
      outerItem.sourceOntology.forEach((innerSourceItem) => {
        const source = innerSourceItem.ontologyId;

        outerItem.targetOntologyList.forEach((innerTargetOntologyList) => {
          innerTargetOntologyList.targetOntology.map((innerTargetOntology) => {
            const target = innerTargetOntology.ontologyId;

            const object = {
              source: innerSourceItem.ontologyId,
              target: innerTargetOntology.ontologyId,
              mapping: innerTargetOntologyList.numberOfMappings,
              cmapping: innerTargetOntologyList.numberOfConflictiveMappings,
              label:
                '( ' +
                source +
                ' --> ' +
                target +
                ') ' +
                '(mappings, conflictive mappings): ( ' +
                innerTargetOntologyList.numberOfMappings +
                ' , ' +
                innerTargetOntologyList.numberOfConflictiveMappings +
                ' )',
            };
            /**
             * excluded arcs that contains as label zero mappings OR zero conflictive mappings .
             */
            if (innerTargetOntologyList.numberOfMappings > 0 || innerTargetOntologyList.numberOfConflictiveMappings > 0) {
            links.push(object);
            }
          });
        });
      });
    });
  }

  /**
   * if node in a graph represents source ontology then
   * the color assigned to that node is red. otherwise
   * color of that node is #dd6e42 (for target ontologies).
   *
   * A node can be assigned also  to target and source
   * ontology at the same time. In that case that node
   * is coloured in #dd6e42.
   *
   * @param graph
   * @param node
   * @returns {boolean}
   */
  function containsSourceNode(graph, node) {
    let containsNode = false;

    graph.forEach((outerItem) => {
      outerItem.sourceOntology.forEach((innerSourceItem) => {
        const source = innerSourceItem.ontologyId;

        if (source === node) {
          containsNode = true;

          return containsNode;
        }
      });
    });

    return containsNode;
  }

  /**
   *
   * For a given source ontology id, this function return mapping id.
   * Pair mapping id and source ontology id are unique pair in JSON as a result of mappings
   * in preprocessing step.
   *
   * @param mappings
   * @param sourceOntologyId
   */
  function getMappingRowId(sourceOntologyId) {
    let id = [];

    mappings.forEach((innerItem) => {
      innerItem.sourceOntology.forEach((outerItem) => {
        if (outerItem.ontologyId === sourceOntologyId) {
          id = innerItem.id;
          return id;
        }
      });
    });

    return id;
  }
    const defaultOption = 'NFDI4ING';
  return (
    <SizeMe monitorHeight={true} monitorWidth={true}>
      {({ size }) => (
        /**
         * style={{height:'100px', width: '100px'}}
         * @param size
         * @returns {JSX.Element}
         */
        <Grid container justifyContent="center">
          <Grid item xs={10} md={11}>
            <Typography variant="h5" gutterBottom textAlign="center">
              Mappings Graph
            </Typography>
            <Typography variant="body2" gutterBottom style={{ marginBottom: '1rem' }}>
              This page shows mappings graph between ontologies. Users can explore and view different mapping graphs between pair of ontologies produced by
              <Link href="https://www.cs.ox.ac.uk/isg/tools/LogMap/" target="_blank">
                {' '}
                LogMap
              </Link>{' '}
              ontology matching tool. Nodes in graphs represent ontology ids, directed arrows between nodes denote mapping between source and target ontology ids. Nodes with{' '}
              <a href="https://www.color-hex.com/color/dd6e42" target="_blank" style={{ color: '#dd6e42' }}>
                light orange{' '}
              </a>
              color denote source ontology ids, and{' '}
              <a href="https://www.color-hex.com/color/4f6d7a" target="_blank" style={{ color: '#4f6d7a' }}>
                dark blue{' '}
              </a>
              color of nodes denotes target ontology ids. Light orange nodes change color to dark blue if they become a target node in a graph after selecting or deselecting the ontology id. Label on
              each arrow denotes number of mappings and number of conflictive mappings respectively. Users can use filtering mechanism in order to select specific mappings between ontologies by
              seleting one or more collection name and source ontology ids that belong to selected collections. Users can also filter mappings graph using only collection names.
            </Typography>
            <div>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '650px' }}>
                  Collections (initial filter is set, please adapt to your needs):
                  <Select
                    defaultValue={initialDefaultCollection}
                    isMulti={true}
                    name="collections"
                    options={collections}
                    onChange={onChange}
                    autoFocus={true}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select one or more collection names..."
                  />
                </div>
                <div style={{ width: '650px', marginLeft: '10px' }}>
                  Source ontology ids:
                  <Select
                    defaultValue={initialDefaultOntologyIds}
                    isMulti={true}
                    name="ontologyId"
                    options={selectedSourceOntologies}
                    onChange={onChangeSourceOntologies}
                    autoFocus={true}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select one or more ontology ids..."
                  />
                </div>
              </div>

              <ForceGraph2D
                graphData={{ nodes, links }}
                showNavInfo={true}
                autoPauseRedraw={true}
                linkDirectionalArrowLength={1.1}
                linkDirectionalArrowRelPos={1}
                linkCurvature={0.02}
                linkWidth={2}
                enableNodeDrag={true}
                nodeVal={0.05}

                // nodeVisibility={(node) =>{
                //
                //   if(valCollection.length > 0 && valOntId.length > 0) {
                //     return true;
                //   } else {
                //     return false;
                //   }
                //
                // }}

                nodeColor={(node) => {
                  /**
                   * Colors of source ontology nodes are as same as release info background color.
                   */
                  if (containsSourceNode(graph, node.id) === true) {
                    return '#dd6e42';
                  } else {
                    return '#4f6d7a';
                  }
                }}

                linkColor={() => {
                  return '';
                }}

                // linkVisibility={(link)=>{
                //
                //     if(valCollection.value === null && valOntId.value === null) {
                //         return true;
                //     } else {
                //         return false;
                //     }
                // }}

                nodeOpacity={1}
                minZoom="6"
                maxZoom="20"
                backgroundColor="white"
                height={850}
                width={1400}
                warmUpTicks={100}
                cooldownTicks={0}
                /**
                 * shows ontology uri when we hover (point) on node using mouse
                 */
                nodeLabel="title"
                linkLabel="label"
                // nodeColor = {(node,links)=> "red"}
                /**
                 * customization view of nodes that includes fonts, node size, node label
                 * alignment, node colour, etc.
                 */
                nodeCanvasObjectMode={() => 'after'}
                nodeCanvasObject={(mpg_node, mpg_crc, mpg_number) => {
                  const label = mpg_node.id;
                  const fontSize = 18 / mpg_number;
                  mpg_crc.font = `${fontSize}px Times`;
                  mpg_crc.textAlign = 'left';
                  mpg_crc.textBaseline = 'middle';
                  mpg_crc.fillStyle = 'black';
                  mpg_crc.fillText(label, mpg_node.x, mpg_node.y - 3);
                }}
                /**
                 * Opens a new modal (window) that shows mappings data related to
                 * source ontology.
                 **/
                onNodeClick={(node) => handleOpen(node)}
                /**
                 * customization view of links between nodes.
                 */
                linkCanvasObjectMode={() => 'after'}
                linkCanvasObject={(mpg_link, mpg_crc) => {
                  const start = mpg_link.source;
                  const end = mpg_link.target;

                  // calculating label position
                  const textPos = Object.assign(
                    ...['x', 'y'].map((c) => ({
                      [c]: start[c] + (end[c] - start[c]) / 2, // calc middle point
                    }))
                  );

                  const label = '(' + mpg_link.mapping + ',' + mpg_link.cmapping + ')';

                  //fontSize to fit in link length
                  mpg_crc.font = '1px Times';

                  // text label
                  mpg_crc.save();
                  mpg_crc.translate(textPos.x, textPos.y);
                  mpg_crc.textAlign = 'center';
                  mpg_crc.textBaseline = 'middle';
                  mpg_crc.fillStyle = 'black';
                  mpg_crc.fillText(label, 0, 0);
                  mpg_crc.restore();
                }}
              />

              {/* Pop-up a window that displays mappings between selected source ontology and target ontologies*/}
              {selectNodeId && (
                <>
                  <Modal
                    open={open}
                    onClose={handleModalToggle}
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      invisible: true,
                      onClick: null,
                    }}
                  >
                    <Box sx={style}>
                      <div style={{ position: 'sticky', top: '0', zIndex: 1, background: '#d1d3d9', padding: '10px' }}>
                        <Typography variant="h5" style={{ textAlign: 'center' }}>
                          Mapping Details
                        </Typography>
                        <span style={closeBtnStyle} onClick={handleModalToggle}>
                          X
                        </span>
                      </div>
                      <div style={{ overflowY: 'auto', padding: '1rem', flex: 1 }}>
                        <Box>
                          <TableForRowTargetOntologies mappings={mappings} row={getMappingRowId(selectNodeId)} table={selectNodeId} />
                        </Box>
                      </div>
                      <div style={{ position: 'sticky', bottom: '0', padding: '10px', zIndex: 1, background: '#d1d3d9', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="contained" color="secondary" onClick={handleModalToggle}>
                          Close
                        </Button>
                      </div>
                    </Box>
                  </Modal>
                </>
              )}
            </div>
          </Grid>
        </Grid>
      )}
    </SizeMe>
  );
};
export default MappingGraphBetweenOntologies;