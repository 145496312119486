
export const gitlabService = {
    getFileCommits,
    getSinceFileCommits,
    getUserFromUrl,
    getRepoFromUrl,
    getBranchFromUrl,
    getEncodedPath,
    getRawFileByCommit,
    getGitFileContent,
    checkGitFileUpdated,
    getGitlabApiRawFileByCommit,

    getRawFileByCommitURL,
};

const headers = {
    'PRIVATE-TOKEN': process.env.REACT_APP_GITLAB_ACCESS_TOKEN
};

function getUserFromUrl(gitlabUrl) {
    return new URL(gitlabUrl).pathname.split('/')[1];
}

function getRepoFromUrl(gitlabUrl) {
    const splitURL = new URL(gitlabUrl).pathname.split('/');
    const hyphenIndex = splitURL.findIndex((elem, index) => index > 0 && elem === "-");

    return splitURL.slice(2, hyphenIndex).join("%2F");
}

function getBranchFromUrl(gitlabUrl) {
    return new URL(gitlabUrl).pathname.split('/')[5];
}

function getEncodedPath(gitlabUrl) {
    return new URL(gitlabUrl).pathname.split("/").splice(6).join("%2F");
}

async function getFileCommits(gitlabUrl) {
    const user = getUserFromUrl(gitlabUrl);
    const repo = getRepoFromUrl(gitlabUrl);
    const encodedPath = getEncodedPath(gitlabUrl);
    let commits = [];
    let page = 1;

    while (true) {
        const response = await fetch(`${process.env.REACT_APP_GITLAB_API_BASE_URL}/projects/${user}%2F${repo}/repository/commits?path=${encodedPath}&page=${page}`, { headers });
        const data = await response.json();
        commits = commits.concat(data);

        const nextPage = response.headers.get('x-next-page');
        if (!nextPage) {
            break;
        }

        page = parseInt(nextPage);
    }

    return commits;
}

async function getSinceFileCommits(gitlabUrl, sinceDate) {
    const user = getUserFromUrl(gitlabUrl);
    const repo = getRepoFromUrl(gitlabUrl);
    const encodedPath = getEncodedPath(gitlabUrl);

    const response = await fetch(`${process.env.REACT_APP_GITLAB_API_BASE_URL}/projects/${user}%2F${repo}/repository/commits?path=${encodedPath}&since=${sinceDate}`, { headers });
    return await response.json();
}

async function getRawFileByCommit(gitlabUrl, commitId) {
    const user = getUserFromUrl(gitlabUrl);
    const repo = getRepoFromUrl(gitlabUrl);
    const encodedPath = getEncodedPath(gitlabUrl);

    const response = await fetch(`${process.env.REACT_APP_GITLAB_API_BASE_URL}/projects/${user}%2F${repo}/repository/files/${encodedPath}/raw?ref=${commitId}`, { headers });

    return await response.text();
}

function getRawFileByCommitURL(gitlabUrl, commitId) {
    const user = getUserFromUrl(gitlabUrl);
    const repo = getRepoFromUrl(gitlabUrl);
    const encodedPath = getEncodedPath(gitlabUrl);

    return `https://gitlab.com/${user}/${repo}/-/raw/${commitId}/${encodedPath}`;
}

function getGitlabApiRawFileByCommit(gitlabUrl, commitId) {
    const user = getUserFromUrl(gitlabUrl);
    const repo = getRepoFromUrl(gitlabUrl);
    const filePath = getEncodedPath(gitlabUrl);

    return `${process.env.REACT_APP_GITLAB_API_BASE_URL}/projects/${user}%2F${repo}/repository/files/${filePath}/raw?ref=${commitId}`;
}

async function getGitFileContent(gitlabUrl) {
    const branch = getBranchFromUrl(gitlabUrl);
    const filePath = getEncodedPath(gitlabUrl);
    const user = getUserFromUrl(gitlabUrl);
    const repo = getRepoFromUrl(gitlabUrl);

    const response = await fetch(`${process.env.REACT_APP_GITLAB_API_BASE_URL}/projects/${user}%2F${repo}/repository/files/${filePath}?ref=${branch}`,{ headers });
    const data = await response.json();

    return btoa(data['content']);
}

const getUserRepoByName = async gitlabUrl => {
    const user = getUserFromUrl(gitlabUrl);
    const repo = getRepoFromUrl(gitlabUrl);

    const userRepo = await fetch(`${process.env.REACT_APP_GITLAB_API_BASE_URL}/projects/${user}%2F${repo}`, { headers });
    return await userRepo.json();
};

export const getGitlabLatestCommit = async gitlabUrl => {
    const userRepo = await getUserRepoByName(gitlabUrl);
    const branch = getBranchFromUrl(gitlabUrl) || 'main';

    const response = await fetch(`${process.env.REACT_APP_GITLAB_API_BASE_URL}/projects/${userRepo['id']}/repository/commits/${branch}?per_page=1`, { headers });
    const data = await response.json();
    return data.id;
};

async function checkGitFileUpdated(gitlabUrl, lastFetchedFileSha){
    return {
        status: 'latest',
        commitsBehind: 0,
        branch: 'main'
    }
}


export const getGitLabListOfCommits = async (gitlabApiUrl, lastCommit) => {
    const owner = getUserFromUrl(gitlabApiUrl)
    const repo = getRepoFromUrl(gitlabApiUrl)
    const gitlabAccessToken = process.env.REACT_APP_GITLAB_ACCESS_TOKEN;
    const pathSegments = new URL(gitlabApiUrl).pathname.split('/');
    let branch;
    // Determine if the URL is a standard GitLab project URL or a raw URL
    if (pathSegments[5] === 'raw') {
        branch = pathSegments[6]; // Extract the branch
    } else {
        branch = pathSegments[5] || 'main'; // Branch can be omitted in some cases
    }

    try {
        // Construct the API URL with query parameters
        const apiUrl = new URL(`https://gitlab.com/api/v4/projects/${encodeURIComponent(owner + '/' )}${(repo)}/repository/commits`);
        apiUrl.searchParams.append('ref_name', branch);
        apiUrl.searchParams.append('since', new Date(lastCommit).toISOString());

        // Fetch the list of commits since the last known commit
        const response = await fetch(apiUrl.toString(), {
            headers: {
                'Authorization': `Bearer ${gitlabAccessToken}`,
            }
        });

        // Check for a successful response
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const commits = await response.json(); // Parse the response as JSON

        // Return appropriate status based on commit activity
        if (commits.length === 0) {
            return { status: 'latest', branch };  // No new commits
        } else {
            return { status: commits.length, branch };  // Return number of new commits
        }
    } catch (error) {
        return { status: 'error', branch };  // Return an error status
    }
};
