import React from 'react';
import { Button, Card, CardContent, Grid, Typography, CardActions } from '@mui/material';
import PropTypes from 'prop-types';



const ButtonCard = ({ title, text, helper, onClick }) => {
    return (
        <Grid item sm={12} md={4}>
            <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent style={{ display: 'flex', flexDirection: 'column', height: 'inherit' }}>
                    <Typography gutterBottom variant="body1" sx={{ flexGrow: 1 }}>
                        {text}
                    </Typography>
                    <div style={{ justifySelf: 'flex-end', marginTop: 'auto' }}>
                        <Button variant="contained" onClick={onClick}>
                            {title}
                        </Button>
                    </div>
                </CardContent>
                <CardActions>
                    <Button size="small">{helper}</Button>
                </CardActions>
            </Card>
        </Grid>
    );
};

ButtonCard.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
}
export default ButtonCard;
