import React, { useEffect, useRef, useState } from "react";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { selectService } from "../../../api/queryData";
import { robotService } from "../../../api/robot.service";
import {
  Button,
  TextField,
  Grid,
  Typography,
  Link,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { ondetService } from "../../../api/ondet.service";
import CircularProgress from "@mui/material/CircularProgress";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import IconButton from "@mui/material/IconButton";
import ConfirmationDialog from "./ConfirmationDialog";

export const AddForm = () => {
  const ONTOLOGY_ADD_TIMEOUT = 180000;

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    type: "",
    url: "",
  });

  const [loading, setLoading] = useState(false);
  const [commitsFetched, setCommitsFetched] = useState(false);
  const history = useHistory();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showAbortMessage, setShowAbortMessage] = useState(false);
  const [abortMessageTimeout, setAbortMessageTimeout] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [countdown, setCountdown] = useState(3);
  const addingAbortRef = useRef(false);

  const EXAMPLE_URL =
    "https://raw.githubusercontent.com/OpenEnergyPlatform/ontology/refs/heads/dev/src/ontology/imports/iao-extracted.owl";

  const getDefaultValuesForMakeDiff = () => {
    return {
      dataset: "",
      type: "github",
      url: "",
    };
  };

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: getDefaultValuesForMakeDiff(),
  });

  const setUrlValue = () => {
    setValue("url", EXAMPLE_URL);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (formSubmitted === true) {
        setCommitsFetched(true);
      }
    };
    fetchData();
  }, [formSubmitted]);

  useEffect(() => {
    const handleCreate = async () => {
      if (addingAbortRef.current) return;

      setLoading(true);

      try {
        await ondetService.ondetDiff(formData);
        setLoading(false);
        const countdownInterval = setInterval(() => {
          setCountdown((prevCountdown) => {
            if (prevCountdown <= 1) {
              clearInterval(countdownInterval);
              history.push({
                pathname: "/ts/sandbox/ondet",
                state: { itemClicked: true },
              });
            }
            return prevCountdown - 1;
          });
        }, 1000);
      } catch (error) {
        setHasError(true);
        setErrorMessage(error.message);
        setLoading(false);
      }
    };

    if (commitsFetched && !addingAbortRef.current) {
      handleCreate();

      const timeout = setTimeout(() => {
        if (!addingAbortRef.current) {
          setShowAbortMessage(true);
        }
      }, ONTOLOGY_ADD_TIMEOUT);
      setAbortMessageTimeout(timeout);
    }
  }, [commitsFetched, addingAbortRef]);

  const handleAbortAdding = () => {
    addingAbortRef.current = true;
    history.push("/ts/sandbox/semanticdiff");
  };

  const validURL = (str) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i",
    ); // fragment locator
    return !!pattern.test(str);
  };

  const checkUrl = async (url) => {
    if (validURL(url)) {
      if (new URL(url).host === "raw.githubusercontent.com") {
        try {
          const responce = await fetch(url, { method: "GET" });
          if (responce.status === 404) {
            return {
              valid: false,
              error:
                "Resource not found. Make sure the address is correct and the page has not moved",
            };
          }
          if (!responce.ok) {
            return { valid: false, error: responce.message };
          }
          return { valid: true, error: null };
        } catch (error) {
          return { valid: false, error: "We were not able to fetch your ontology from git." };
        }
      }
      if (new URL(url).host === "gitlab.com") {
        return { valid: true, error: null };
      } else {
        return { valid: false, error: "Not supported ontology type." };
      }
    } else {
      return { valid: false, error: "URL is invalid." };
    }
  };

  const onSubmit = async (data) => {
    const url = await checkUrl(data.url);
    if (url.valid === true) {
      setFormData(data);
      clearTimeout(abortMessageTimeout);
      setFormSubmitted(true);
    } else {
      setError("url", {
        type: "manual",
        message: url.error,
      });
    }
  };

  const handleOpenDialog = () => {
    setErrorDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setErrorDialogOpen(false);
  };

  return (
    <Grid
      container
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {loading && (
        <Grid item xs={12} md={11}>
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
            <Typography
              variant="h6"
              style={{ marginBottom: 16, color: "#555555", fontWeight: 500 }}
            >
              Calculating semantic diffs...
            </Typography>
            <Typography
              variant="body1"
              style={{ marginBottom: 24, color: "#888888" }}
            >
              This may take a moment. Sit back and relax.
            </Typography>
            {showAbortMessage && (
              <Typography variant="body1" style={{ color: "#FF0000" }}>
                Operation takes too much time. You can wait, or you can{" "}
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleAbortAdding}
                >
                  Abrupt ontology adding
                </Button>
              </Typography>
            )}
          </div>
        </Grid>
      )}
      {!loading && hasError && (
        <Grid item xs={8} md={11} style={{ textAlign: "center" }}>
          <Typography
            variant="h6"
            style={{
              marginBottom: "16px",
              color: "#FF0000",
              fontWeight: "500",
            }}
          >
            We have encountered some issues while processing your ontology
          </Typography>
          <Typography variant="body1">
            We are sorry to inform you, but some error(s) happened during
            ontology creation. We are unsure on which side it happened - ours or
            yours, here are the steps you can try to do.
          </Typography>
          <ol style={{ textAlign: "left", paddingLeft: "20px" }}>
            <li>
              <Typography variant="body1">
                You can see the reason behind this issue by clicking the button
                below:
              </Typography>

              <Button
                variant="outlined"
                color="secondary"
                style={{ margin: "16px" }}
                onClick={handleOpenDialog}
              >
                View error message
              </Button>
            </li>
            <li>
              <Typography variant="body1">
                You can create an issue. Under <i>File an issue</i> menu on the
                right.
              </Typography>
            </li>
          </ol>
        </Grid>
      )}
      {!hasError && !loading && commitsFetched && (
        <Grid item xs={8} md={11} style={{ textAlign: "center" }}>
          <Typography
            variant="h6"
            style={{
              marginBottom: "16px",
              color: "#000000",
              fontWeight: "500",
            }}
          >
            Your ontology was successfully added into the system. You will now
            be redirected to the table view.
          </Typography>
          <Typography variant="body1" style={{ color: "#888888" }}>
            Redirecting in {countdown} seconds...
          </Typography>
        </Grid>
      )}
      {!loading && !commitsFetched && (
        <Grid item xs={8} md={11}>
          <Typography variant="h5" gutterBottom textAlign="center">
            Ontology Development Tracker (OnDeT)
          </Typography>
          <Typography>
            Small changes to an ontology can have a major impact on its
            validity. These changes can be syntactic (e.g. grammatical errors in
            an rdfs:comment) or semantic (e.g. adding, editing or removing
            concepts). Both types of changes are difficult to recognise. It
            becomes even more complex when a specific change needs to be found.
            Especially if it is not known from the outset in which version it
            was made.
          </Typography>
          <br />
          <Typography>
            If you want to see already processed ontologies, please go to{" "}
            <Link
              component={RouterLink}
              to={
                process.env.REACT_APP_PROJECT_SUB_PATH + "/sandbox/ondet"
              }
            >
              List of Ontologies
            </Link>
          </Typography>
          <br />

          <form onSubmit={handleSubmit(onSubmit)} style={{ textAlign: "left" }}>
            <div>
              <Typography variant="h6">
                Process version information with OnDeT
              </Typography>
              <Controller
                name="url"
                control={control}
                rules={{ required: "URL is required" }}
                render={({ field, fieldState }) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "left",
                      flexDirection: "column",
                    }}
                  >
                    <TextField
                      id="standard-basic"
                      helperText={
                        <Tooltip
                          title={
                            <>
                              Please add the ontology permanent GIT URL to
                              create the version information with OnDeT.
                              <br />
                              The permanent GIT link is the link to the
                              unformatted or raw ontology file in the main GIT
                              branch. You can obtain a raw URL by following
                              these steps:
                              <ol type="a">
                                <li>
                                  Navigate to the file you are interested in
                                  within the GitHub repository.
                                </li>
                                <li>
                                  Click on the 'RAW' button, which is typically
                                  found at the top right of the file view.
                                </li>
                                <li>
                                  This will open the raw version of the file in
                                  your browser. The URL in the browser's address
                                  bar is the raw URL.
                                </li>
                                <li>
                                  The format of a raw URL is usually:
                                  https://raw.git.com/username/repository/reference/branch-or-tag/path/to/file
                                </li>
                              </ol>
                            </>
                          }
                        >
                          {EXAMPLE_URL}
                        </Tooltip>
                      }
                      variant="outlined"
                      type="text"
                      name="name"
                      onChange={field.onChange}
                      style={{ width: "100%" }}
                      value={field.value}
                      error={!!fieldState.error}
                      {...field}
                    />
                    <IconButton
                      onClick={setUrlValue}
                      style={{ alignSelf: "start" }}
                    >
                      <FileCopyOutlinedIcon />
                    </IconButton>
                    {errors.url && (
                      <p style={{ color: "#FF0000" }}>{errors.url.message}</p>
                    )}
                  </div>
                )}
              />
            </div>
            <br />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" type="submit" sx={{ ml: 1 }}>
                Submit
              </Button>
            </div>
          </form>
        </Grid>
      )}
      <ConfirmationDialog
        open={errorDialogOpen}
        onCancel={handleCloseDialog}
        title="Error during semantic diff calculation"
        message={errorMessage}
      />
    </Grid>
  );
};

const Container = styled("div")`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const HelperText = styled("div")``;
