import { Accordion, AccordionDetails, AccordionSummary, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import webprotege_create_account from '../../../assets/img/sandbox_logos/webprotege_create_account.png';
import webprotege_create_new_project from '../../../assets/img/sandbox_logos/webprotege_create_new_project.png';
import webprotege_personal_access_token from '../../../assets/img/sandbox_logos/webprotege_personal_access_token.png';
import further_cloning from '../../../assets/img/sandbox_logos/further_cloning.png';
import commit_changes_1 from '../../../assets/img/sandbox_logos/commit_changes_1.png';
import commit_changes_2 from '../../../assets/img/sandbox_logos/commit_changes_2.png';
import commit_changes_3 from '../../../assets/img/sandbox_logos/commit_changes_3.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DirectionsIcon from '@mui/icons-material/Directions';

export const WebprotegeExplanation = () => {
  return (
    <div style={{ padding: '1rem' }}>
      <Typography variant="h5" gutterBottom>
        Usage of Git Integrated WebProtege
      </Typography>
      <Typography gutterBottom>
        With this Git WebProtege extension you can load your ontolgy from a Git repository directly into WebProtege, process it via WebProtege and then commit it to the same or another Git repository.
        The supported Git systems are Github and Gitlab based servers.
      </Typography>
      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography>1 Preconditions</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: '1rem' }}>
          <Typography gutterBottom>
            You need to register with WebProtege to be able to work with the tool in general. To be able to use the Git extension in WebProtege, you also need read/write access to the Git where your
            ontology is located. In this Git you need to create a personal access token with which you verify your rights to Git. Instructions on how to generate these tokens from GitLab and GitHub
            can be found in the following chapter.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br />

      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography>2 Generate GitLab or GitHub tokens</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: '1rem' }}>
          <Typography variant="h6" gutterBottom>
            2.1 GitLab
          </Typography>
          <Typography gutterBottom>
            GitLab provides a description on how to create the token on its{' '}
            <a href="https://docs.gitlab.com/ee/user/profile/personal_access_tokens.html" target="_blank">
              webpage
            </a>
            . For your convenience, we have copied the important parts:
            <br /> You can create as many personal access tokens as you like.
            <br />
            <br /> 1. On the left sidebar, select your avatar.
            <br /> 2. Select Edit profile.
            <br /> 3. On the left sidebar, select Access Tokens.
            <br /> 4. Select Add new token.
            <br /> 5. Enter a name and expiry date for the token.
            <br /> 6. The token expires on that date at midnight UTC.
            <br /> 7. If you do not enter an expiry date, the expiry date is automatically set to 365 days later than the current date.
            <br /> 8. By default, this date can be a maximum of 365 days later than the current date.
            <br /> 9. Select the desired scopes. (API Read Access is necessary for cloning repositories that include particular ontologies and API Write Access is necessary for committing the changes
            in the ontologies to repositories)
            <br /> 10. Select Create personal access token.
          </Typography>
          <Typography variant="h6" gutterBottom>
            2.2 GitHub
          </Typography>
          <Typography gutterBottom>
            GitHub provides a description on how to create the token on its{' '}
            <a href="https://docs.github.com/en/enterprise-server@3.6/authentication/keeping-your-account-and-data-secure/managing-your-personal-access-tokens" target="_blank">
              webpage
            </a>
            . For your convenience, we have copied the important parts:
            <br />
            <br /> 1. In the upper-right corner of any page, click your profile photo, then click Settings.
            <br /> 2. In the left sidebar, click Developer settings.
            <br /> 3. In the left sidebar, click Personal access tokens.
            <br /> 4. Click Generate new token.
            <br /> 5. In the "Note" field, give your token a descriptive name.
            <br /> 6. To give your token an expiration, select Expiration, then choose a default option or click Custom to enter a date.
            <br /> 7. Select the scopes you'd like to grant this token. To use your token to access repositories from the command line, select repo. A token with no assigned scopes can only access
            public information. For more information, see{' '}
            <a href="https://docs.github.com/en/enterprise-server@3.6/apps/oauth-apps/building-oauth-apps/scopes-for-oauth-apps#available-scopes" target="_blank">
              Scopes for OAuth apps
            </a>
            . (API Read Access is necessary for cloning repositories that include particular ontologies and API Write Access is necessary for committing the changes in the ontologies to repositories)
            <br /> 8. Click Generate token.
            <br /> 9. Optionally, to copy the new token to your clipboard, click
            <br />
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br />

      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography>3 Login to WebProtege and Configuration</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: '1rem' }}>
          <Typography gutterBottom>
            In order to create a new WebProtege project for a git-managed ontology, you have to first create an account with user name, e-mail address, password and personal access token in the
            following window.
          </Typography>
          <img src={webprotege_create_account} style={{ width: '100%', height: '100%', margin: '1rem 0' }}></img>
          <Typography gutterBottom>
            You can always change your user information regarding user name, e-mail address, password and personal access token from the right top menu in the landing page as seen in the following
            window. Changing a personal access token might be beneficial for having git functionality on multiple projects that are accessed by different git users or servers.
          </Typography>
          <img src={webprotege_personal_access_token} style={{ width: '100%', height: '100%', margin: '1rem 0' }}></img>
        </AccordionDetails>
      </Accordion>
      <br />
      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography>4 Create new Project from Git</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: '1rem' }}>
          <Typography gutterBottom>
            Next, please click on the purple "Create new project" button on the left panel of the landing page. A new dialogue box opens. You have two options to provide the ontology source. One
            option is to provide a local file and the other is cloning from the repository. If you choose "Clone from repo" it will be mandatory to provide a valid repository url. You may then
            optionally provide relative path of the source file in the repo and the respective branch. Public repositories do not require API Access or a personal access token to clone. Others would
            need your convenient API Read Access to clone that your recorded personal access token should possess. Below you can see an example of a public repository:
          </Typography>
          <img src={webprotege_create_new_project} style={{ width: '30%', height: '30%', margin: '1rem 0' }}></img>
          <br />
          <b>Git Related Fields:</b>
          <br />
          <br />
          <ul>
            <li>
              <b>Relative Path in repo:</b>
              It is the path of the specific ontology file relative to the repository root directory. Automatic ontology search will be carried out throughout the file tree if the specified file does
              not exist.
            </li>
            <li>
              <b>Branch:</b>
              It is the specific branch of the repository that should contain the ontology file. The default master/main branch will be used ifthe specified branch does not exist.
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <br />
      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography>5 Commit Changes to Git</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: '1rem' }}>
          <Typography gutterBottom>
            After creating your project, you can always send your ontology modeling changes to your collaborators by committing your project state to a repo. If you have created your project by
            cloning from repository, then your default destination to commit is this repo. But you can always change this from your project settings (right top of project page after clicking the
            project from the landing page) as shown below:
          </Typography>
          <img src={commit_changes_1} style={{ width: '100%', height: '100%', margin: '1rem 0' }}></img>
          <Typography gutterBottom>
            Before committing you should ensure that you have a personal access token that possesses API Write access to the repository that you would like to commit. In order to initiate the commit
            process, you should right click on a particular project on the landing page and choose the commit option as shown below:
          </Typography>
          <img src={commit_changes_2} style={{ width: '100%', height: '100%', margin: '1rem 0' }}></img>
          <Typography>After then, you will come across a new dialogue to input your commit options as seen below:</Typography>
          <img src={commit_changes_3} style={{ width: '30%', height: '30%', margin: '1rem 0' }}></img>
          <Typography gutterBottom>
            Commit Options: <br />
            <br /> <b>a) Branch:</b> The existing branch to commit to if the „New Branch from Selection“ field is empty.
            <br /> <b>b) New Branch from Selection:</b> Branches from the original branch specified in the dropdown list of the „Branch“ field. It can be left blank. There should be no blanks in text.
            <br /> <b>c) Relative Path in Repo:</b> This is the directory path that the modeled ontology will be stored. If the path does not exist, unexisting directories will automatically created
            along with the ontology file.
            <br /> <b>d) Ontology Name:</b> Ontology file name that requires no extension. There shouldn’t be spaces or special characters in the name .
            <br /> <b>e) Ontology Format:</b> This is the file extension under which the ontology model will be stored.
            <br /> <b>f) Import Paths in Repository:</b> Directory path of the imported ontologies. Unexisting directories will automatically be created.
            <br /> <b>g) Commit Message:</b> Message to be displayed in the respective commit of the repo.
            <br />
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br />
      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography>6 Further cloning functionalities</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: '1rem' }}>
          <Typography gutterBottom>
            There are further cloning options in addition to the „Upload from File“ in some functions in the Project menu of the project page. These functions can be seen with Apply „External Edits“
            and „Merge Ontologies“ in the Project menu. These options can be used with a similar approach to the usage in cloning the ontology from repository during project creation. They both
            require the repo url of the reference ontology and check automatically for a matching ontology in the file tree of main/master branch of the repository if a valid branch and valid relative
            path in the repo are not provided. If the provided values are valid, then they are used for identifying the reference ontology. It should be noted that the „Relative Path“ field should
            include the ontology file name and extension in the end of the string
          </Typography>
          <img src={further_cloning} style={{ width: '100%', height: '100%', margin: '1rem 0' }}></img>
        </AccordionDetails>
      </Accordion>
      <br />
      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography>7 Troubleshooting the Usage of The Software</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: '1rem' }}>
          <Typography gutterBottom>
            <List>
              <ListItem>
                <ListItemIcon>
                  <DirectionsIcon />
                </ListItemIcon>
                <ListItemText>It is important to use personal access tokens that are not expired.</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <DirectionsIcon />
                </ListItemIcon>
                <ListItemText>
                  It is also important to understand that a personal access token is only valid in the Git instance that it is created. You cannot use a Github created token in Gitlab and vice versa.
                  Furthermore, you cannot use a Gitlab created token in another Gitlab instance. (For instance, a gitlab.com token can not be used in git.tib.eu)
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <DirectionsIcon />
                </ListItemIcon>
                <ListItemText>
                  It should also be kept in mind that your personal access token has proper API access rights to the repository you want to clone from (Read rights) or commit into (Write rights).
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <DirectionsIcon />
                </ListItemIcon>
                <ListItemText>If you are not a member of a repository, you can only clone from that repository given that it is a public one.</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <DirectionsIcon />
                </ListItemIcon>
                <ListItemText>
                  If a public repository does not provide you write access, you can fork from it and commit into the forked repository using WebProtege. You can later make a pull request from your
                  forked repository to the original one.{' '}
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <DirectionsIcon />
                </ListItemIcon>
                <ListItemText>The repo URI that you have to specify should be the http address of the repository (not the ontology file).</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <DirectionsIcon />
                </ListItemIcon>
                <ListItemText>
                  You must also specify the relative location of the ontology file in the repository with the branch, the respective directory in the file tree, and the ontology file name if the
                  ontology file name cannot be inferred from the repository name automatically.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <DirectionsIcon />
                </ListItemIcon>
                <ListItemText>
                  If you want to create a new branch while committing, choose the original branch that you want to start from and specify the new branch's name in the text box below it.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <DirectionsIcon />
                </ListItemIcon>
                <ListItemText>
                  If you specify an existing file name (without extension), its extension, and its relative path in the file tree of the branch that you are committing into, that particular ontology
                  file will be overwritten. Otherwise, it will be created.
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <DirectionsIcon />
                </ListItemIcon>
                <ListItemText>Please, have a careful look at the examples provided in the project creation and commit menus.</ListItemText>
              </ListItem>
            </List>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
