
export const gitOnem2mService = {
    getFileCommits,
    getSinceFileCommits,
    getUserFromUrl,
    getRepoFromUrl,
    getBranchFromUrl,
    getEncodedPath,
    getRawFileByCommit,
    getGitFileContent,
    checkGitFileUpdated,
    getGitlabApiRawFileByCommit,

    getRawFileByCommitURL,
};

const headers = {
    'PRIVATE-TOKEN': process.env.REACT_APP_GITONEM2M_ACCESS_TOKEN
};

function getUserFromUrl(gitOnem2m) {
    return new URL(gitOnem2m).pathname.split('/')[1];
}

function getRepoFromUrl(gitOnem2m) {
    const splitURL = new URL(gitOnem2m).pathname.split('/');
    const hyphenIndex = splitURL.findIndex((elem, index) => index > 0 && elem === "-");

    return splitURL.slice(2, hyphenIndex).join("%2F");
}

function getBranchFromUrl(gitOnem2m) {
    return new URL(gitOnem2m).pathname.split('/')[5];
}

function getEncodedPath(gitOnem2m) {
    return new URL(gitOnem2m).pathname.split("/").splice(6).join("%2F");
}

async function getFileCommits(gitOnem2m) {
    const user = getUserFromUrl(gitOnem2m);
    const repo = getRepoFromUrl(gitOnem2m);
    const encodedPath = getEncodedPath(gitOnem2m);
    let commits = [];
    let page = 1;

    while (true) {
        const response = await fetch(`${process.env.REACT_APP_GITONEM2M_API_BASE_URL}/projects/${user}%2F${repo}/repository/commits?path=${encodedPath}&page=${page}`);
        const data = await response.json();
        commits = commits.concat(data);

        const nextPage = response.headers.get('x-next-page');
        if (!nextPage) {
            break;
        }

        page = parseInt(nextPage);
    }

    return commits;
}

async function getSinceFileCommits(gitOnem2m, sinceDate) {
    const user = getUserFromUrl(gitOnem2m);
    const repo = getRepoFromUrl(gitOnem2m);
    const encodedPath = getEncodedPath(gitOnem2m);

    const response = await fetch(`${process.env.REACT_APP_GITONEM2M_API_BASE_URL}/projects/${user}%2F${repo}/repository/commits?path=${encodedPath}&since=${sinceDate}`);
    return await response.json();
}

async function getRawFileByCommit(gitOnem2m, commitId) {
    const user = getUserFromUrl(gitOnem2m);
    const repo = getRepoFromUrl(gitOnem2m);
    const encodedPath = getEncodedPath(gitOnem2m);

    const response = await fetch(`${process.env.REACT_APP_GITONEM2M_API_BASE_URL}/projects/${user}%2F${repo}/repository/files/${encodedPath}/raw?ref=${commitId}`);

    return await response.text();
}

function getRawFileByCommitURL(gitOnem2m, commitId) {
    const user = getUserFromUrl(gitOnem2m);
    const repo = getRepoFromUrl(gitOnem2m);
    const encodedPath = getEncodedPath(gitOnem2m);

    return `https://gitlab.com/${user}/${repo}/-/raw/${commitId}/${encodedPath}`;
}

function getGitlabApiRawFileByCommit(gitOnem2m, commitId) {
    const user = getUserFromUrl(gitOnem2m);
    const repo = getRepoFromUrl(gitOnem2m);
    const filePath = getEncodedPath(gitOnem2m);

    return `${process.env.REACT_APP_GITONEM2M_API_BASE_URL}/projects/${user}%2F${repo}/repository/files/${filePath}/raw?ref=${commitId}`;
}

async function getGitFileContent(gitOnem2m) {
    const branch = getBranchFromUrl(gitOnem2m);
    const filePath = getEncodedPath(gitOnem2m);
    const user = getUserFromUrl(gitOnem2m);
    const repo = getRepoFromUrl(gitOnem2m);

    const response = await fetch(`${process.env.REACT_APP_GITONEM2M_API_BASE_URL}/projects/${user}%2F${repo}/repository/files/${filePath}?ref=${branch}`,{ headers });
    const data = await response.json();

    return btoa(data['content']);
}

const getUserRepoByName = async gitOnem2m => {
    const user = getUserFromUrl(gitOnem2m);
    const repo = getRepoFromUrl(gitOnem2m);

    const userRepo = await fetch(`${process.env.REACT_APP_GITONEM2M_API_BASE_URL}/projects/${user}%2F${repo}`);
    return await userRepo.json();
};

export const getGitlabLatestCommit = async gitOnem2m => {
    const userRepo = await getUserRepoByName(gitOnem2m);
    const branch = getBranchFromUrl(gitOnem2m) || 'main';

    const response = await fetch(`${process.env.REACT_APP_GITONEM2M_API_BASE_URL}/projects/${userRepo['id']}/repository/commits/${branch}?per_page=1`);
    const data = await response.json();
    return data.id;
};

async function checkGitFileUpdated(gitOnem2m, lastFetchedFileSha){
    return {
        status: 'latest',
        commitsBehind: 0,
        branch: 'main'
    }
}
