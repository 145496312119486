import { AddForm } from "./AddForm";
import { robotService } from "../../../api/robot.service";

export const AddRobotDiff = () => {
  return (
    <AddForm
      apiUrl={`${process.env.REACT_APP_DIFF_BACKEND_URL}/api/history/ontologies`}
      makeDiff={robotService.robotDiff}
    />
  );
};
