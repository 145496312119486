import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { Box, Divider, Typography } from '@mui/material';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import { ListItems } from './ListItems';

const drawerWidth = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
    overflowY: 'hidden',
    overflowX: 'hidden',
    flexShrink: 0,
  },
  drawerHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  iconButton: {
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const MiniDrawer = ({ props }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const handleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Drawer
      variant="permanent"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
      sx={{
        display: {
          sm: 'none',
          md: 'block',
        },
      }}
    >
      <DrawerHeader title="Toggle Sidebar" className={classes.drawerHeader}>
        <IconButton onClick={handleDrawer} className={classes.iconButton}>
          {open ? (
            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <KeyboardDoubleArrowLeftIcon /> <Typography variant="body1">&nbsp;&nbsp;&nbsp;&nbsp;Collapse sidebar</Typography>
            </Box>
          ) : (
            <KeyboardDoubleArrowRightIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider className={classes.divider} />
      <ListItems isOpen={open} />
    </Drawer>
  );
};
