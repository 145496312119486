import { GetGithubOntologies } from '../../../api/queryData';
import QualityAssessmentData from './QualityAssessmentData';
import { CircularProgress, Grid } from '@mui/material';
import { Stars } from '../common/buttons/Stars';

export const GithubDataColumns = () => {
  const { isLoading, isError, error, data, isFetching } = GetGithubOntologies();
  console.log(data)
  return (
    <>
      <Grid container>
        <Grid>
          {isLoading ? (
            <div>
              <CircularProgress />
            </div>
          ) : isError ? (
            <div>Error: {error.message}</div>
          ) : (
            <QualityAssessmentData githubOntologies={data} />
          )}
        </Grid>
      </Grid>
    </>
  );
};
