import { createTheme, responsiveFontSizes } from '@mui/material';

export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: '#4f6d7a',
        light: '#c0d6df',
      },
      secondary: {
        main: '#dd6e42',
      },
      grey: {
        300: '#d1d3d9',
      },
    },
    components: {
      MuiListItemButton: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 0,
            color: theme.palette.common.black,
            '&.Mui-selected, &.Mui-selected:hover': {
              color: theme.palette.common.black,
              backgroundColor: theme.palette.grey[300],
              borderRadius: 5,
            },
            '&:hover': {
              color: theme.palette.common.black,
              backgroundColor: theme.palette.grey[300],
              borderRadius: 5,
            },
          }),
        },
      },
      MuiLink: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            '&:hover': {
              color: theme.palette.secondary.main,
            },
          }),
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            textAlign: 'justify',
          }),
        },
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            fontSize: '15px',
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {},
        },
      },
      MuiTable: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            border: `1px solid ${theme.palette.grey[300]}`,
          }),
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({}),
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            height: 30,
          }),
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            padding: '0 10px',
          }),
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            paddingTop: 0,
            paddingBottom: 0,
            backgroundColor: theme.palette.primary.light,
            borderTopLeftRadius: theme.spacing(1),
            borderTopRightRadius: theme.spacing(1),
          }),
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            padding: 5,
          }),
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            color: theme.palette.primary.main,
          }),
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            color: theme.palette.primary.secondary,
          }),
          selected: ({ ownerState, theme }) => ({
            backgroundColor: theme.palette.primary.secondary,
          }),
        },
      },
      MuiTab: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.grey[300]}`,
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            marginRight: '5px',
            backgroundColor: theme.palette.common.white,
            '&.Mui-selected': {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
              borderTop: `1px solid ${theme.palette.grey[300]}`,
              borderRight: `1px solid ${theme.palette.grey[300]}`,
              borderLeft: `1px solid ${theme.palette.grey[300]}`,
            },
          }),
        },
      },
      MuiTabPanel: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            border: 'none',
          }),
        },
      },
      MuiCardMedia: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            [theme.breakpoints.down('xl')]: {
              height: '100px',
              width: '200px',
              alignSelf: 'center',
            },
            [theme.breakpoints.up('xl')]: {
              height: '180px',
              width: '310px',
              alignSelf: 'center',
            },
          }),
        },
      },
      MuiFab: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            borderRadius: '4px',
            justifyContent: 'left',
            width: '150px',
            [theme.breakpoints.down('xl')]: {
              maxWidth: '50px',
            },
          }),
        },
        defaultProps: {
          variant: 'extended',
          size: 'small',
          color: 'secondary',
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            color: theme.palette.primary.main,
          }),
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: ({ ownerState, theme }) => ({
            // color: theme.palette.primary.main,
            '&.Mui-checked': {
              color: theme.palette.secondary.main,
            },
          }),
        },
      },
    },
  })
);
