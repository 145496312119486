import React, {useEffect, useState} from 'react';
import {Typography, Grid} from '@mui/material';
import MaterialReactTable from "material-react-table";
import { getGitHubListOfCommits } from '../../../api/networkGit/github.service'
import { getGitLabListOfCommits } from '../../../api/networkGit/gitlab.service'

export const GItOntologyStatusCheck = () => {
    const [data, setData] = useState([]);

    const KEYWORDS = ["github.com", "raw.githubusercontent", "gitlab.com"];

    // Helper function to determine the source of the URL
    const getStatusFunction = (url) => {
        if (url.includes("github.com") || url.includes("raw.githubusercontent")) {
            return getGitHubListOfCommits;
        } else if (url.includes("gitlab.com")) {
            return getGitLabListOfCommits;
        }
        return null; // Return null if no matching source is found
    };

    useEffect(() => {
        const fetchOntology = async () => {
            const API_URL = `${process.env.REACT_APP_API_BASE_URL}?size=2000`;
            try {
                // Fetch data from the API
                const response = await fetch(API_URL);

                // Check for HTTP response errors
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const jsonData = await response.json();
                const ontologies = jsonData?._embedded?.ontologies || [];


                // Map and filter the ontologies
                const formattedData = await Promise.all(ontologies.map(async (data) => {
                    const url = data?.config?.fileLocation;

                    if (url && KEYWORDS.some(keyword => url.includes(keyword))) {
                        const lastUpdatedDate = data?.updated;
                        const ontologyName = data?.ontologyId;
                        const statusFunction = getStatusFunction(url);

                        if (statusFunction) {
                            const status = await statusFunction(url, lastUpdatedDate);
                            return {
                                url,
                                lastUpdatedDate,
                                ontologyName,
                                status,
                            };
                        }
                    }
                    return null; // Return null for items that do not match
                }));
                // Filter out null values from the formatted data
                setData(formattedData.filter(Boolean));

            } catch (error) {
                console.error('Error fetching ontology data:', error);
            }
        };

        fetchOntology();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const flattenedData = data
        ? data
            .filter(ont => ont.status.status !== 'error') // Filter out entries with error
            .map(ont => ({
                ontologyId: ont.ontologyName,
                OntologyBranch: ont.status.branch,
                ontologyStatus: ont.status.status
            }))
        : [];

    const columns = [
        { header: 'Ontology ID', accessorKey: 'ontologyId' },
        { header: 'Ontology Git Branch', accessorKey: 'OntologyBranch' },
        { header: 'Ontology Status', accessorKey: 'ontologyStatus' }
    ];

    return (
        <Grid container justifyContent="center">
            <Grid item xs={10} md={11}>
                <Typography variant="h5" gutterBottom textAlign="center">
                    Git Ontology Status Checker Service
                </Typography>
                <Typography variant="body2" gutterBottom style={{marginBottom: '1rem'}}>
                    The Git Ontology Status Checker Service is designed to ensure that public ontologies on GitHub and GitLab remain current. It checks for any commits made after the last update of the ontology in the Terminology Service platform. If the ontology is not up-to-date, the service will indicate how many commits are behind; otherwise, it will confirm that the latest version is available.
                </Typography>
                <MaterialReactTable
                    columns={columns}
                    enableRowExpansion={true}
                    initialState={{ pagination: { pageSize: 10 } }}
                    enableColumnResizing={true}
                    autoResetPageIndex={false}
                    enableStickyHeader={true}
                    data={flattenedData}
                />
            </Grid>
        </Grid>
    );
};