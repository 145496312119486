import { Grid } from '@mui/material';
import { ModelResultDisplay } from './ModelResultDisplay';

export const ModelSelector = () => {
  return (
    <Grid container>
      <Grid item md={12} style={{ padding: '2% 5% 2% 5%' }}>
        <ModelResultDisplay modelApi={`${process.env.REACT_APP_SEMANTICSEARCH_BACKEND_URL}/api/sbert`} />
      </Grid>
    </Grid>
  );
};
