import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

export const useSearchMappingsStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    border: '0.1em solid #D3D3D3',
    '& .MuiTableCell-root': {
      borderBottom: '0.1em solid #D3D3D3',
      borderRight: '0.1em solid #D3D3D3',
      borderLeft: '0.1em solid #D3D3D3',
      borderTop: '0.1em solid #D3D3D3',
    },
  },
  typo: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  typography: {
    paddingTop: theme.spacing(4),
  },
  formControlLabel: {
    display: 'flex',
    flexDirection: 'column',
  },
  box: {
    marginLeft: theme.spacing(0), //3
  },
  tab: {
    fontWeight: 'bolder',
    backgroundColor: theme.palette.primary.main /*white*/,
    color: 'white',
    margin: '0 5px 0 0',
    borderRadius: '5px 5px 2px 2px',
    border: '0.1em solid #D3D3D3',
  },
  /**class, object property, datatype property, individual mapping tabs **/
  codiTab: {
    fontWeight: 'bolder',
    backgroundColor: 'white' /*#A0B8C2*/,
    color: 'black' /*white*/,
    margin: '0 5px 0 0',
    borderRadius: '5px 5px 2px 2px',
    border: '0.1em solid #D3D3D3',
  },
  /**mapping, conflictive mappings, mappings graph tabs **/
  mcpmgTab: {
    fontWeight: 'bolder',
    backgroundColor: 'white' /*#7295A4*/,
    color: 'black' /*white*/,
    margin: '0 5px 0 0',
    borderRadius: '5px 5px 2px 2px',
    border: '0.1em solid #D3D3D3',
  },
  tabs: {
    '& .Mui-selected': {
      background: theme.palette.primary.main, //, white
      color: 'black', //theme.palette.common.white,
      border: '0.1em solid #D3D3D3',
    },
  },
  tabsLight: {
    '& .Mui-selected': {
      background: '#7295A4' /**'white',**/,
      color: 'black' /**theme.palette.common.white,**/,
      border: '0.1em solid #D3D3D3',
    },
  },
  tabsLighter: {
    '& .Mui-selected': {
      background: '#A0B8C2', //'white',
      color: 'black', //theme.palette.common.white,
      border: '0.1em solid #D3D3D3',
    },
  },
  pagination: {
    '& .Mui-selected': {
      backgroundColor: '#D3D3D3',
    },
  },
  tableBackgroundColor: {
    // backgroundColor: '#efefef',
    marginLeft: theme.spacing(-2),
    marginTop: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    marginBottom: theme.spacing(-2),
  },
  tabsBackgroundColor: {
    // backgroundColor: '#efefef',
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(0.3),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  tableHeaderBackgroundColor: {
    backgroundColor: 'white', //'#A0B8C2', //'#CFDBE0',
    color: 'black',
  },
}));

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
