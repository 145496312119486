import MaterialReactTable from 'material-react-table';
import {React, useEffect, useMemo, useRef, useState} from 'react';
import {Button, Dialog, DialogTitle, Grid, Link, MenuItem} from "@mui/material";
import { faGithub, faGitlab } from '@fortawesome/free-brands-svg-icons';
import gitTibLogo from '../../../assets/img/GIT_TIB_Logo_WB_RGB_100px.png';
import gitOnem2mLogo from '../../../assets/img/git_one2m_org_logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TimelineOntology from "./TimelineOntology";
import {Criteria} from "./Criteria";
import {faCheckCircle, faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import CodeMirror from '@uiw/react-codemirror';
import { json } from '@codemirror/lang-json';
import {selectService} from "../../../api/queryData";
import Report from "./Report";
import Box from "@mui/material/Box";
import {ToggleCloseButton} from "./ToggleCloseButton";
import IconButton from "@mui/material/IconButton";
import ButtonCard from "./ButtonCard";
import PropTypes from "prop-types";
import EditDialog from "./EditDialog";
import ConfirmationDialog from "./ConfirmationDialog";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import {Link as RouterLink} from "react-router-dom";

export const DiffTable = ({ontologies,
                              apiUrl,
                              makeDiff,
                              onUpdated,
                              onSaved,
                              onDeleted}) => {

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [copen, setCopen] = useState(false);
    const [sopen, setSopen] = useState(false);
    const [ropen, setRopen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [updatedOntologies, setUpdatedOntologies] = useState([]);
    const [isDeleting, setIsDeleting] = useState(false);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingRow, setEditingRow] = useState(null);
    let newCommits = useRef(null);

    // useEffect(() => {
    //     const updatedOntologies = [];
    //     const updateOntologies = async () => {
    //         for (let ontology of ontologies) {
    //             newCommits.current = await selectService(ontology['type']).getSinceFileCommits(ontology['url'], ontology['atime']);
    //             if (newCommits.current.length > 1) {
    //                 const updatedOntology = {
    //                     ...ontology,
    //                     commitStatus: {
    //                         branch: 'main',
    //                         commitsBehind: newCommits.current.length,
    //                         status: 'behind',
    //                     }
    //                 };
    //                 updatedOntologies.push(updatedOntology);
    //             } else {
    //                 updatedOntologies.push(ontology);
    //             }
    //         }
    //         setUpdatedOntologies(updatedOntologies);
    //     }
    //
    //     updateOntologies();
    // }, [ontologies]);

    const handleOpenPopup = (ontology) => {
        setSelectedRow(ontology);
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const openRowFunctions = (row) => {
        setSelectedRow(row);
        setOpen(true);
    }

    const handleDelete = (row) => {
        setSelectedRow(row);
        setConfirmationDialogOpen(true);
    }

    const confirmDelete = async () => {
        setConfirmationDialogOpen(false);
        setIsDeleting(true);
        await fetch(apiUrl + `${selectedRow.original.id}`, {
            method: 'DELETE',
            headers: {'Content-Type': 'application/json'},
        });
        setIsDeleting(false);
        onDeleted();
    }

    const cancelDelete = () => {
        setConfirmationDialogOpen(false);
    }

    const handleEdit = async (row) => {
        setIsEditing(true);
        setEditingRow(row.original);
    }

    const handleAllUpdateClick = async () => {
        for (const ontology of ontologies) {
            const diffCommits = await selectService(ontology['type']).getSinceFileCommits(ontology['url'], ontology['atime']);
            const diffs = await makeDiff(diffCommits.reverse(), ontology['type'], ontology['url']);
            console.log('diffs from handleupdateclick', diffs);
            await fetch(apiUrl + `/update/${ontology['id']}`, {
                method: 'PUT',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(diffs.reverse()),
            });
        }
        onUpdated();
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: 'open',
                header: 'Open row functions',
                Cell: ({ cell }) => (
                    <IconButton onClick={() => openRowFunctions(cell.row)}>
                        <ExpandCircleDownOutlinedIcon />
                    </IconButton>
                ),
            },
            {
                accessorKey: 'name',
                header: 'Name for ontologies',
            },
            {
                accessorKey: 'type',
                header: 'Git Type',
                filterVariant: 'select',
                Cell: ({ cell }) => (
                    <>
                        {cell.row.original.type === 'gitlab' && (
                            <>
                                <FontAwesomeIcon icon={faGitlab} />
                                {' '}Gitlab
                            </>
                        )}

                        {cell.row.original.type === 'gitAachen' && (
                            <>
                                <FontAwesomeIcon icon={faGitlab} />
                                {' '}Git Aachen
                            </>
                        )}

                        {cell.row.original.type === 'gitTIB' && (
                            <>
                                <img src={gitTibLogo} alt="Git TIB Logo" style={{width: '64px', height: '32px'}}/>
                            </>
                        )}
                        {cell.row.original.type === 'gitOnem2m' && (
                            <>
                                <img src={gitOnem2mLogo} alt="Git Onem2m Logo" style={{width: '64px', height: '32px'}}/>
                            </>
                        )}

                        {cell.row.original.type === 'github' && (
                            <>
                                <FontAwesomeIcon icon={faGithub} />
                                {' '}Github
                            </>
                        )}
                    </>
                ),
            },
            {
                accessorKey: 'description',
                header: 'Description for ontologies'
            },
            // {
            //     accessorKey: 'invalidDiffs',
            //     header: 'Errors',
            //     Cell: ({ cell }) => (
            //         <>
            //             {cell.row.original.invalidDiffs.length > 0 ? (
            //                 <div onClick={() => handleOpenPopup(cell.row.original)}>
            //                     <FontAwesomeIcon icon={faExclamationCircle} style={{ color: '#dd6e42', cursor: 'pointer' }} />
            //                     <span style={{ marginLeft: '5px' }}>{cell.row.original.invalidDiffs.length}</span>
            //                 </div>
            //             ) : (
            //                 <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#4f6d7a' }} />
            //             )}
            //         </>
            //     ),
            // },
            // {
            //     accessorFn: (row) => {
            //         const commitsBehind = row.commitStatus.commitsBehind;
            //         const versionText = `${commitsBehind-1} commits behind`
            //         console.log('commitsbehind and versiontest', row);
            //         const handleUpdateClick = async () => {
            //             const diffCommits = await selectService(row.type).getSinceFileCommits(row.url, row.atime);
            //             const diffs = await makeDiff(diffCommits.reverse(), row.type, row.url);
            //
            //             const requestData = {
            //                 diffs: diffs.reverse(),
            //             }
            //             console.log('diffs from handleupdateclick', diffs);
            //             await fetch(apiUrl + `/update/${row.id}`, {
            //                 method: 'PUT',
            //                 headers: {'Content-Type': 'application/json'},
            //                 body: JSON.stringify(requestData),
            //             });
            //             onUpdated();
            //         }
            //
            //         return (
            //             <>
            //                 Git branch: <strong>{row.commitStatus.branch}</strong>
            //                 <br />
            //                 Version: <strong>{commitsBehind === '0' ? 'latest' : versionText}</strong>
            //                 <br />
            //                 {commitsBehind !== '0' && <Button variant='contained' onClick={handleUpdateClick}>
            //                     Update
            //                 </Button>}
            //             </>
            //         );
            //     },
            //     id: 'h',
            //     header: 'Commit status'
            // },
        ], [],);

    console.log('props from material table', updatedOntologies);

    return (
        <>
            {selectedRow && (

                <Dialog style={{ marginTop: '20px' }} open={open} onClose={handleClose}
                        PaperProps={{style: {
                                minHeight: '35%',
                                maxHeight: '35%',
                                minWidth: '80%',
                                maxWidth: '80%',
                                position: 'relative',
                            }}}>

                    <DialogTitle>Ontology functions</DialogTitle>

                    <Grid container spacing={5}>
                        <ButtonCard
                            title="Query full ontology history"
                            text="With this function, you can visualise all changes to your Ontology,
                            beyond all Git commits. This functionality will give you a comprehensive
                            overview of the development history of your ontology"
                            helper={<Link component={RouterLink} to={'?howtouse=true&sectionId=timeline'}>Instructions for use</Link>}
                            onClick={() => {
                                setCopen(true);
                            }}
                        />
                        <ButtonCard
                            title="Axiom specific changes"
                            text="With this function, you can visualise all changes to one axiom from your Ontology,
                                    in a your chosen Git commit period. This functionality will give you a comprehensive
                                    overview of the development history of your ontology"
                            helper={<Link component={RouterLink} to={'?howtouse=true&sectionId=axiom'}>Instructions for use</Link>}
                            onClick={() => {
                                setSopen(true);
                            }}
                        />
                        <ButtonCard
                            title="Create change report"
                            text="With this function, you can create reports, that includes aggregated information
                                about your Ontology (number of added, removed axioms, most frequent axiom) in a given
                                period of time on top of history development information of your Ontology.
                                "
                            helper={<Link component={RouterLink} to={'?howtouse=true&sectionId=rawUri'}>Instructions for use</Link>}
                            onClick={() => {
                                setRopen(true);
                            }}
                        />
                    </Grid>
                    <ToggleCloseButton onClose={handleClose} />
                </Dialog>
            )}
            {copen && (
                <Dialog
                    style={{ marginTop: '20px' }}
                    open={copen}
                    onClose={() => {
                        setCopen(false);
                    }}
                    PaperProps={{
                        style: {
                            minHeight: '95%',
                            maxHeight: '95%',
                            minWidth: '90%',
                            maxWidth: '90%',
                            position: 'relative',
                        },
                    }}
                >
                    <DialogTitle>Timeline of the whole ontology</DialogTitle>
                    <TimelineOntology id={selectedRow?.original.id} />
                    <ToggleCloseButton onClose={() => {
                        setCopen(false);
                    }} />
                </Dialog>
            )}

            {sopen && (
                <Dialog
                    style={{ marginTop: '20px' }}
                    open={sopen}
                    onClose={() => {
                        setSopen(false);
                    }}
                    PaperProps={{
                        style: {
                            minHeight: '95%',
                            maxHeight: '95%',
                            minWidth: '90%',
                            maxWidth: '90%',
                            position: 'relative',
                        },
                    }}
                >
                    <DialogTitle>Timeline of the whole ontology</DialogTitle>
                    <Criteria id={selectedRow?.original.id} />
                    <ToggleCloseButton onClose={() => {
                        setSopen(false);
                    }} />
                </Dialog>
            )}

            {ropen && (
                <Dialog
                    style={{ marginTop: '20px' }}
                    open={ropen}
                    onClose={() => {
                        setRopen(false);
                    }}
                    PaperProps={{
                        style: {
                            minHeight: '95%',
                            maxHeight: '95%',
                            minWidth: '90%',
                            maxWidth: '90%',
                            position: 'relative',
                        },
                    }}
                >
                    <DialogTitle>Timeline of the whole ontology</DialogTitle>
                    <Report id={selectedRow.original.id} />
                    <div>
                        <ToggleCloseButton onClose={() => {
                            setRopen(false);
                        }} />
                    </div>
                </Dialog>
            )}
            <MaterialReactTable
                renderTopToolbarCustomActions={({ table }) => (
                    <Box
                        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                    >
                        <Button variant='contained' onClick={handleAllUpdateClick}>Update all ontologies</Button>
                    </Box>
                )}
                enableRowActions
                positionActionsColumn="last"
                renderRowActionMenuItems={({ row }) => [
                    <MenuItem key="edit" onClick={() => handleEdit(row)}>
                        Edit
                    </MenuItem>,
                    <MenuItem key="edit" onClick={() => handleDelete(row)}>
                        Delete
                    </MenuItem>,
                ]}

                columns={columns}
                data={ontologies}
                enableFacetedValues

            />
            <Dialog open={isPopupOpen} onClose={handleClosePopup} maxWidth={'xl'}>
                <>
                    {selectedRow?.original.invalidDiffs.map((diff) => (
                        <CodeMirror
                            key={diff.id}
                            value={JSON.stringify(diff, null, "\t")}
                            extensions={[json()]}
                            maxHeight="100%"
                            maxWidth="100%"
                            readOnly={true}
                            basicSetup={{
                                highlightActiveLine: false,
                            }}
                            style={{whiteSpace: 'pre-wrap'}}
                        />
                    ))}
                </>
            </Dialog>
            {isEditing && <EditDialog
                open={isEditing}
                onClose={() => setIsEditing(false)}
                onSave={() => onSaved()}
                data={editingRow}
            />}

            <ConfirmationDialog
                open={confirmationDialogOpen}
                onCancel={cancelDelete}
                onConfirm={confirmDelete}
                title="Delete ontology"
                message="Are you sure you want to delete this ontology?"
            />
        </>
    )
}

DiffTable.propTypes = {
    ontologies: PropTypes.array.isRequired
}