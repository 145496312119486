import {React, useEffect, useRef, useState} from 'react';
import {
    Card,
    CardContent,
    CardMedia,
    Link,
    List,
    ListItem, ListItemText,
    Typography
} from '@mui/material';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import OntologyTable from "../../../assets/img/sandbox_logos/diff/ontology-table-view.png"

import GithubOntologyFileClick from "../../../assets/img/sandbox_logos/diff/github-ontology-file-clicking.png"
import GithubOntologyRawButton from "../../../assets/img/sandbox_logos/diff/github-ontology-raw-button.png"
import GithubOntologyAfterButtonClick from "../../../assets/img/sandbox_logos/diff/github-ontology-raw-after-button-click.png"
import GitlabOntologyRawButton from "../../../assets/img/sandbox_logos/diff/gitlab-ontology-raw-button.png"
import GitlabOntologyAfterButtonClick from "../../../assets/img/sandbox_logos/diff/gitlab-ontology-raw-after-button-click.png"

import FullOntologyHistory from "../../../assets/img/sandbox_logos/diff/full-ontology-history.png"

import SpecificAxiomFirstStep from "../../../assets/img/sandbox_logos/diff/specific-axiom-first-step.png"
import SpecificAxiomSecondtStep from "../../../assets/img/sandbox_logos/diff/specific-axiom-second-step.png"
import SpecificAxiomThirdStep from "../../../assets/img/sandbox_logos/diff/specific-axion-third-step.png"

import ReportPreview from "../../../assets/img/sandbox_logos/diff/report-component-preview.png"
import ReportGeneratedFile from "../../../assets/img/sandbox_logos/diff/report-component-generated-file.png"


export const DiffExplanation = ({sectionId}) => {
    const [expanded, setExpanded] = useState(false);
    const mainRef = useRef();
    const rawRef = useRef();
    const timeRef = useRef();
    const axiomRef = useRef();
    const repoRef = useRef();
    const biblRef = useRef();

    useEffect(() => {
        if (sectionId) {
            switch (sectionId) {
                case 'mainView':
                    mainRef.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 'rawUri':
                    rawRef.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 'timeline':
                    timeRef.current.scrollIntoView({ behavior: 'smooth'});
                    break;
                case 'axiom':
                    axiomRef.current.scrollIntoView({ behavior: 'smooth'});
                    break;
                case 'preview':
                    repoRef.current.scrollIntoView({ behavior: 'smooth'});
                    break;
                case 'references':
                    biblRef.current.scrollIntoView({ behavior: 'smooth'});
                    break;
                default:
                    break;
            }
        }
    }, [sectionId]);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div style={{ padding: '1rem' }}>
            <Typography variant="h5" gutterBottom>
                Explore the usage of Semantic Diff tool
            </Typography>
            <Typography>
                This page explains how to manage, explore and create history of ontologies created by {' '}
                <Link href="http://robot.obolibrary.org/" target="_blank">
                    Robot
                </Link>
                {' '} robot obolibrary tool. [1]
            </Typography>
            <br />
            <Accordion disableGutters
                       expanded={sectionId === 'mainView' || expanded === 'mainView'}
                       onChange={handleChange('mainView')}
                       id="mainView"
                       ref={mainRef}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>1. Ontology main view</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '2rem', padding: '1rem' }}>
                    <Typography>
                        Figure 1 shows a main ontology table view, where a set of functionalities is grouped together. Box <b>A</b> show three buttons to deal with ontology history
                        such as <i>Query Full Ontology History, Query Specific Axiom and Report Form</i>. By default buttons are not shown, you need to expand one of the items in the
                        box <b>B</b>. Box <b>C</b> shows the functionality of update, i.e. what happens when there are new version on the remote Git server your ontology was uploaded from.
                        You can then update just one ontology, or update all ontologies with the button on top.
                    </Typography>

                    <Card style={{ height: '100%', width: '100%', alignSelf: 'center', display: 'flex', flexDirection: 'column', padding: '1rem' }}>
                        <CardMedia component="img" src={OntologyTable} alt="Mapping Table" style={{ height: '100%', width: '100%' }} />
                        <CardContent style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
                            <Typography variant="body2">
                                <b>Figure 1</b>. Ontology main view with main functionality boxes.
                            </Typography>
                        </CardContent>
                    </Card>
                </AccordionDetails>
            </Accordion>
            <br />

            <Accordion disableGutters
                       expanded={sectionId === 'rawUri' || expanded === 'rawUri'}
                       onChange={handleChange('rawUri')}
                       id="rawUri"
                       ref={rawRef}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>2. How to get Ontology raw URI</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '2rem', padding: '1rem' }}>
                    <Typography variant="body1">
                        Let's say you have found an ontology hosted on the Git-based system, that you want to use.
                        All you have to do, is to click on the Ontology file, then find the button that would say {' '}
                        <i>Raw</i> or <i>Open raw file</i>, click on that button and then copy the link from the
                        browser search - that will be you link for the raw Ontology file (raw Ontology URI).
                        Examples are provided for Github and Gitlab accordingly.
                    </Typography>

                    <Typography variant="body1">
                        Click on the Ontology file.
                    </Typography>
                    <Card style={{ height: '300px', width: '1250px', alignSelf: 'center', display: 'flex', flexDirection: 'column' }}>
                        <CardMedia component="img" src={GithubOntologyFileClick} alt="Mappings Graph" style={{ height: '600px', width: '1200px' }} />
                        <CardContent style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
                            <Typography variant="body2">
                                <b>Figure 2</b>. Ontology file
                            </Typography>
                        </CardContent>
                    </Card>
                    <Typography variant="body1">
                        Click on the <i>Raw</i> button to get Raw file.
                    </Typography>
                    <Card style={{ height: '300px', width: '1250px', alignSelf: 'center', display: 'flex', flexDirection: 'column' }}>
                        <CardMedia component="img" src={GithubOntologyRawButton} alt="Mappings Graph" style={{ height: '600px', width: '1200px' }} />
                        <CardContent style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
                            <Typography variant="body2">
                                <b>Figure 3</b>. Raw button in the Github.
                            </Typography>
                        </CardContent>
                    </Card>
                    <Typography variant="body1">
                        Copy the raw Ontology file URI.
                    </Typography>
                    <Card style={{ height: '300px', width: '1250px', alignSelf: 'center', display: 'flex', flexDirection: 'column' }}>
                        <CardMedia component="img" src={GithubOntologyAfterButtonClick} alt="Mappings Graph" style={{ height: '600px', width: '1200px' }} />
                        <CardContent style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
                            <Typography variant="body2">
                                <b>Figure 4</b>. Copying raw Ontology file URI
                            </Typography>
                        </CardContent>
                    </Card>
                    <br />
                    <Typography variant="body1">
                        Same for Gitlab. <br />First step is to click <i>Open raw</i> button
                    </Typography>
                    <Card style={{ height: '300px', width: '1250px', alignSelf: 'center', display: 'flex', flexDirection: 'column' }}>
                        <CardMedia component="img" src={GitlabOntologyRawButton} alt="Mappings Graph" style={{ height: '600px', width: '1200px' }} />
                        <CardContent style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
                            <Typography variant="body2">
                                <b>Figure 5</b>. Open raw button in the Gitlab
                            </Typography>
                        </CardContent>
                    </Card>
                    <Typography variant="body1">
                        Copy the raw Ontology file URI.
                    </Typography>
                    <Card style={{ height: '300px', width: '1250px', alignSelf: 'center', display: 'flex', flexDirection: 'column' }}>
                        <CardMedia component="img" src={GitlabOntologyAfterButtonClick} alt="Mappings Graph" style={{ height: '600px', width: '1200px' }} />
                        <CardContent style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
                            <Typography variant="body2">
                                <b>Figure 6</b>. Copying raw Ontology file URI in the Gitlab
                            </Typography>
                        </CardContent>
                    </Card>
                </AccordionDetails>
            </Accordion>
            <br />

            <Accordion disableGutters
                       expanded={sectionId === 'timeline' || expanded === 'timeline'}
                       onChange={handleChange('timeline')}
                       id="timeline"
                       ref={timeRef}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>3. Full Ontology History</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '2rem', padding: '1rem' }}>
                    <Typography variant="body1">
                        Figure 7 shows a snapshot of a result of the button <i>Query Full Ontology History</i> clicked in the previous menu. On the left side it has date time of when the version has being made,
                        with a timeline that shows version message (taken as a commit message) to the right. When one of the element is selected from the timeline a result will be shown in markdown format to the right
                        that shows semantic difference for that version. You can check which files were used in that comparison and what have changed there.
                    </Typography>

                    <Card style={{ height: '700px', width: '1250px', alignSelf: 'center', display: 'flex', flexDirection: 'column' }}>
                        <CardMedia component="img" src={FullOntologyHistory} alt="Mappings Graph" style={{ height: '600px', width: '1200px' }} />
                        <CardContent style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
                            <Typography variant="body2">
                                <b>Figure 7</b>. Full ontology history
                            </Typography>
                        </CardContent>
                    </Card>
                </AccordionDetails>
            </Accordion>
            <br />

            <Accordion disableGutters
                       expanded={sectionId === 'axiom' || expanded === 'axiom'}
                       onChange={handleChange('axiom')}
                       id="axiom"
                       ref={axiomRef}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>4. Specific Axiom History</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '2rem', padding: '1rem' }}>
                    <Typography>
                        Figure 8 shows a first step in Specific Axiom History, after corresponding button <i>Query Specific Axiom</i> has been clicked. On the first step You need to choose from the
                        predefined set of OWL types from the select menu below.
                    </Typography>

                    <Card style={{ height: '100%', width: '100%', alignSelf: 'center', display: 'flex', flexDirection: 'column', padding: '1rem' }}>
                        <CardMedia component="img" src={SpecificAxiomFirstStep} alt="Mapping Table" style={{ height: '100%', width: '100%' }} />
                        <CardContent style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
                            <Typography variant="body2">
                                <b>Figure 8</b>. Specific axiom history first step.
                            </Typography>
                        </CardContent>
                    </Card>

                    <Typography>
                        Figure 9 shows a second step, where you need to pick the axiom URI from the select menu below. These are the URIs, which have the OWL type, you have chosen on the previous step.
                    </Typography>

                    <Card style={{ height: '100%', width: '100%', alignSelf: 'center', display: 'flex', flexDirection: 'column', padding: '1rem' }}>
                        <CardMedia component="img" src={SpecificAxiomSecondtStep} alt="Mapping Table" style={{ height: '100%', width: '100%' }} />
                        <CardContent style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
                            <Typography variant="body2">
                                <b>Figure 9</b>. Specific axiom history second step.
                            </Typography>
                        </CardContent>
                    </Card>

                    <Typography>
                        Figure 10 shows a third step, where you need to choose the period of commits between which you are interested to see the axiom history. After choosing the first and second commit,
                        you need to click the button <i>Get diff</i>, after which you will receive the result in a timeline on the right side, that shows how one axiom changed over time between your defined
                        period of commits.
                    </Typography>

                    <Card style={{ height: '100%', width: '100%', alignSelf: 'center', display: 'flex', flexDirection: 'column', padding: '1rem' }}>
                        <CardMedia component="img" src={SpecificAxiomThirdStep} alt="Mapping Table" style={{ height: '100%', width: '100%' }} />
                        <CardContent style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
                            <Typography variant="body2">
                                <b>Figure 10</b>. Specific axiom history third step.
                            </Typography>
                        </CardContent>
                    </Card>
                </AccordionDetails>
            </Accordion>
            <br />

            <Accordion disableGutters
                       expanded={sectionId === 'preview' || expanded === 'preview'}
                       onChange={handleChange('preview')}
                       id="preview"
                       ref={repoRef}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>5. Create change report</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '2rem', padding: '1rem' }}>
                    <Typography>
                        Figure 11 shows a report form with the preview data. In this form you need to define period between <i>Start Date</i> and <i>End Date</i>, after that you will click the
                        button <b>Preview Data</b> you will receive result in a markdown format text, where on top an aggregated information about whole ontology semantic differences in that
                        period of time will be added, such as <i>Total number of axioms added and removed, Most frequent axiom URI and Number of times it occurred</i>.
                        After reviewing the data you can click the button <b>Generate Report</b> to get the output file with the data you have seen in the preview.
                    </Typography>

                    <Card style={{ height: '100%', width: '100%', alignSelf: 'center', display: 'flex', flexDirection: 'column', padding: '1rem' }}>
                        <CardMedia component="img" src={ReportPreview} alt="Mapping Table" style={{ height: '100%', width: '100%' }} />
                        <CardContent style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
                            <Typography variant="body2">
                                <b>Figure 11</b>. Create change report - data preview.
                            </Typography>
                        </CardContent>
                    </Card>

                    <Typography>
                        Figure 12 shows an output of the generate file. Currently the format of the file is html, further the pdf and other will be added. All the links are clickable, since the styling
                        are left the same.
                    </Typography>

                    <Card style={{ height: '100%', width: '100%', alignSelf: 'center', display: 'flex', flexDirection: 'column', padding: '1rem' }}>
                        <CardMedia component="img" src={ReportGeneratedFile} alt="Mapping Table" style={{ height: '100%', width: '100%' }} />
                        <CardContent style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
                            <Typography variant="body2">
                                <b>Figure 12</b>. Create change report - generated file .
                            </Typography>
                        </CardContent>
                    </Card>

                </AccordionDetails>
            </Accordion>
            <br />

            <Accordion disableGutters
                       expanded={sectionId === 'references' || expanded === 'references'}
                       onChange={handleChange('references')}
                       id="references"
                       ref={biblRef}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>6. References</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <List>
                        <ListItem>
                            <ListItemText
                                primary="1. Robot Obolibrary tool. Last accessed November 2023. Online:"
                                secondary={
                                    <Link href="http://robot.obolibrary.org/" target="_blank">
                                        http://robot.obolibrary.org/
                                    </Link>
                                }
                            />
                        </ListItem>
                    </List>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}