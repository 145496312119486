import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import PropTypes from 'prop-types';

function ConceptTimeline({ diffs, axiomType, axiomURI }) {
    const renderTimelineItems = () => {
        const timelineItems = [];

        const hasDifferentValues = diffs.some(diff => {
            const conceptValue = getAxiomValue(diff, axiomType, axiomURI);
            return conceptValue !== "Value is same";
        });

        if (!hasDifferentValues) {
            return (
                <Container>
                    <Typography variant="h5">
                        Axiom not present in selected commit period
                    </Typography>
                </Container>
            );
        }

        for (const diff of diffs) {
            const timestamp = diff.timestamp;
            const conceptValue = getAxiomValue(diff, axiomType, axiomURI);

            const timelineItem = (
                <TimelineItem key={diff.sha1}>
                    <TimelineOppositeContent>
                        <Typography variant="body2" color="textSecondary">
                            {new Date(timestamp).toLocaleString()}
                        </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent style={{ margin: '15px' }}>
                        <Paper elevation={3} sx={{ p: 3 }} style={{ width: '500px', overflow: 'auto' }}>
                            <Typography variant="h6" component="h1">
                                {conceptValue}
                            </Typography>
                        </Paper>
                    </TimelineContent>
                </TimelineItem>
            );

            timelineItems.push(timelineItem);
        }

        return timelineItems;
    };

    return (
        <Container>
            <Timeline>
                {renderTimelineItems()}
            </Timeline>
        </Container>
    );
}

ConceptTimeline.propTypes = {
    diffs: PropTypes.array.isRequired,
    axiomType: PropTypes.string.isRequired,
    axiomURI: PropTypes.string.isRequired,
};

const getAxiomValue = (diff, axiomType, axiomURI) => {
    const matchingAxiom = diff.axioms.added.find((axiom) => axiom.axiomType === axiomType && axiom.axiomURI === axiomURI);

    if (matchingAxiom) {
        return matchingAxiom.axiomValue;
    }

    return "Value is same";
};

export default ConceptTimeline;
