import { getCallSetting } from "./constants";

export async function getSemanticSearchResult(query: string): Promise<string[]> {
  try {
    const url = `${process.env.REACT_APP_SEMANTICSEARCH_BACKEND_URL}/api/sbert/nfdi4ing?query=${encodeURIComponent(query)}`;
    const result = await fetch(url);
    const data = await result.json();

    if (result.ok) {
      return data.similar_labels;
    } else {
      console.error('Semantic search error:', data.error);
      return [];
    }
  } catch (error) {
    console.error('Error fetching semantic search result:', error);
    return [];
  }
}
