export const OWLTypes = [
    "Annotation",
    "AnnotationAssertion",
    "AnnotationPropertyDomain",
    "AnnotationPropertyRangeOf",
    "AsymmetricObjectProperty",
    "ClassAssertion",
    "DataPropertyAssertion",
    "DataPropertyDomain",
    "DataPropertyRange",
    "DatatypeDefinition",
    "Declaration",
    "DifferentIndividuals",
    "DisjointClasses",
    "DisjointDataProperties",
    "DisjointObjectProperties",
    "DisjointUnion",
    "EquivalentClasses",
    "EquivalentDataProperties",
    "EquivalentObjectProperties",
    "FunctionalDataProperty",
    "FunctionalObjectProperty",
    "HasKey",
    "InverseFunctionalObjectProperty",
    "InverseObjectProperties",
    "IrreflexiveObjectProperty",
    "NegativeDataPropertyAssertion",
    "NegativeObjectPropertyAssertion",
    "ObjectPropertyAssertion",
    "ObjectPropertyDomain",
    "ObjectPropertyRange",
    "ReflexiveObjectProperty",
    "SameIndividual",
    "SubAnnotationPropertyOf",
    "SubDataPropertyOf",
    "SubObjectPropertyOf",
    "SubPropertyChainOf",
    "SubClassOf",
    "Rule",
    "SymmetricObjectProperty",
    "TransitiveObjectProperty"
]