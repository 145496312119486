import { Button } from "@mui/material";
import PropTypes from "prop-types";

export const ToggleCloseButton = ({ onClose }) => {
    return (
        <div style={{ position: 'absolute', bottom: '0', right: '0' }}>
            <Button variant="contained" color="secondary" onClick={onClose}>
                Close
            </Button>
        </div>
    );
};

ToggleCloseButton.propTypes = {
    onClose: PropTypes.func.isRequired
}