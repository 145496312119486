import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const YearAccordion = ({ year, releases }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion expanded={expanded} onChange={handleChange(year)} disableGutters style={{ marginTop: '10px' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${year}-content`} id={`${year}-header`}>
        <Typography sx={{ width: '33%', flexShrink: 0 }}>{year}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {releases.map((item, index) => (
          <div key={index} style={{ margin: '1rem' }}>
            <Typography variant="h5">{item.title}</Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Changes</TableCell>
                    <TableCell>Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item.changes.map((element, i) => (
                    <TableRow key={i}>
                      <TableCell style={{ width: '30%' }}>{element.label}</TableCell>
                      <TableCell style={{ width: '70%' }}>{element.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
