import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  tab: {
    color: theme.palette.primary.light,
    fontWeight: 'bolder',
    backgroundColor: 'none',
    borderRadius: '5px 5px 0px 0px',
  },
  tabs: {
    transform: 'translate(0px, 1px)',
    '& .Mui-selected': {
      backgroundColor: 'white',
      borderRight: '1px solid',
      borderTop: '1px solid',
      borderLeft: '1px solid',
      borderRightColor: theme.palette.grey[300],
      borderLeftColor: theme.palette.grey[300],
      borderTopColor: theme.palette.grey[300],
    },
  },
  tabpanel: {
    borderTop: '1px solid',
    borderColor: theme.palette.grey[300],
  },
  grid: {
    padding: theme.spacing(2),
  },
  tableHeadBackground: {
    backgroundColor: 'red',
  },
}));
