import { Typography } from '@mui/material';

export const Introduction = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h5" gutterBottom>
        Semantic Search
      </Typography>
      <Typography>Welcome to the Semantic Search! Simply type a word, phrase, or sentence into the search bar below and get the similar entities.</Typography>
      <Typography variant="body2">
        Press <b>Enter</b> to fetch <b>similar labels.</b> Click on any word displayed below to explore further options. Press <b>Esc</b> to clear the search bar.
      </Typography>
      <br />
    </div>
  );
};
