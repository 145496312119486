import React, { useState } from 'react';
import { Tab, Tabs } from '@mui/material/';
import { TabPanel, a11yProps } from '../../theme/Theme';
import { makeStyles } from '@mui/styles';
import MappingOverview from './MappingOverview';

const useStyles = makeStyles((theme) => ({
  tabpanel: {
    border: `1px solid ${theme.palette.grey[300]}`,
  },
}));

const SearchMappingsPage = ({ mappings, id, targetOntologyId, columns, mappingType, typeOfMapping }) => {
  const [value, setValue] = useState(0);

  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Tabs onChange={handleChange} value={value} TabIndicatorProps={{ style: { display: 'none' } }}>
        <Tab label="class mappings" {...a11yProps(0)} />
        <Tab label="object property mappings" {...a11yProps(1)} />
        <Tab label="datatype property mappings" {...a11yProps(2)} />
        <Tab label="individual mappings" {...a11yProps(3)} />
      </Tabs>

      <TabPanel value={value} index={0} className={classes.tabpanel}>
        <MappingOverview mappings={mappings} id={id} targetOntologyId={targetOntologyId} type="class" columns={columns} mappingType={mappingType} typeOfMapping={typeOfMapping} />
      </TabPanel>

      <TabPanel value={value} index={1} className={classes.tabpanel}>
        <MappingOverview mappings={mappings} id={id} targetOntologyId={targetOntologyId} type="object" columns={columns} mappingType={mappingType} typeOfMapping={typeOfMapping} />
      </TabPanel>

      <TabPanel value={value} index={2} className={classes.tabpanel}>
        <MappingOverview mappings={mappings} id={id} targetOntologyId={targetOntologyId} type="datatype" columns={columns} mappingType={mappingType} typeOfMapping={typeOfMapping} />
      </TabPanel>

      <TabPanel value={value} index={3} className={classes.tabpanel}>
        <MappingOverview mappings={mappings} id={id} targetOntologyId={targetOntologyId} type="individual" columns={columns} mappingType={mappingType} typeOfMapping={typeOfMapping} />
      </TabPanel>
    </div>
  );
};
export default SearchMappingsPage;
