import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import DigitalReferenceRawFile from '../../../../assets/img/sandbox_logos/dr-raw-file-github-repo.png';
import DigitalReferenceRawFileLocation from '../../../../assets/img/sandbox_logos/dr-ontology-raw-file-location-github-repo.png';
import DigitalReferenceGithub from '../../../../assets/img/sandbox_logos/dr-ontology-github-repo.png';
import MappingTable from '../../../../assets/img/sandbox_logos/mapping-table.png';
import MappingExample from '../../../../assets/img/sandbox_logos/mapping-between-two-ontologies-example.png';
import ExpandingRow from '../../../../assets/img/sandbox_logos/expand-row-mapping-between-abcd-and-materials-ontology.png';
import MappingsGraph from '../../../../assets/img/sandbox_logos/mappings-between-ontologies-graph.png';
import MappingsGraphForOneRow from '../../../../assets/img/sandbox_logos/mappings-graph-for-one-row.png';
import ConflictiveMappingsGraphForOneRow from '../../../../assets/img/sandbox_logos/conflictive-mappings-graph.png';
import ExternalMappingStartingPage from '../../../../assets/img/sandbox_logos/external-mapping-start-page.png';
import ExternalMappingCoyDrExtr from '../../../../assets/img/sandbox_logos/external-mapping-page-coypu-dr-extr.png';
import ExternalMappingDetailsCoyDrExtruOnt from '../../../../assets/img/sandbox_logos/external-mapping-details-page-coypu-dr-extr.png';
import MappingReasoningAndExceptionExplanation from '../../../../assets/img/sandbox_logos/mapping-reasoning-and-exception-explanation.png';

import { Card, CardContent, CardMedia, Link, List, ListItem, ListItemText, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const MappingsExplanation = () => {
  return (
    <div style={{ padding: '1rem' }}>
      <Typography variant="h5" gutterBottom>
        Explore mappings between ontologies
      </Typography>
      <Typography>
        This page provides instruction on how to explore and search for mappings between pairs of ontologies using
        <Link href="https://www.cs.ox.ac.uk/isg/tools/LogMap/" target="_blank">
          {' '}
          LogMap
        </Link>{' '}
        ontology matching tool.
        <br />
        <br />
        <b>Important note</b>:
        <br />"
        <i style={{ color: 'red' }}>
          LogMap tool implements a sound but incomplete reasoning algorithm for checking class unsatisfiability. Consequently, there is no guarantee that all classes in union of <b>O1</b> ∪ <b>O2</b>{' '}
          ∪ <b>M</b> will be satisfiable
        </i>
        " <Link href="https://cs.ox.ac.uk/files/4788/paperLogMapORE2012.pdf">[4]</Link>, where <b>O1</b> represents source ontology, <b>O2</b> represents target ontology, and <b>M</b> represents set
        of mappings.
        <br />
        <br />
        Users can explore mapping results using combination of tables, and grpahs.
        <br />
        <br />
      </Typography>

      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>1. Table View Mappings</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '2rem', padding: '1rem' }}>
          <Typography>
            Figure 1 shows a part of a table (part A) that we use to visualize mapping results between ontologies. The table has three columns. The first column (part B) is an action that users can
            take in order to explore more details about the mapping listed in a row. The second column shows the name of the source ontology. Third column in the table shows{' '}
            <i> the number of target ontologies </i>
            that have mappings to the source ontology.
          </Typography>

          <Card style={{ height: '100%', width: '100%', alignSelf: 'center', display: 'flex', flexDirection: 'column', padding: '1rem' }}>
            <CardMedia component="img" src={MappingTable} alt="Mapping Table" style={{ height: '100%', width: '100%' }} />
            <CardContent style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
              <Typography variant="body2">
                <b>Figure 1</b>. Source ontology and number of target ontologies related to source ontologies over mappings.
              </Typography>
            </CardContent>
          </Card>

          <Typography>
            The Table 1 displays several options for searching and filtering mappings. These functions are displayed in the box <b> C </b> located in the upper right corner of the Table 1. Users can
            filter data in all columns of the table using the <b>Show/Hide Search</b> button. Users can also filter data in each column separately using the <b>Show/Hide Filters</b> button. To control
            the density of rows in the table, we recommend users to use <b>Toggle Density</b> button. If users need full screen to explore mapping results listed the table,
            <b>Toggle Full Screen</b> option is available for that task.
          </Typography>

          <Card style={{ height: '100%', width: '100%', alignSelf: 'center', display: 'flex', flexDirection: 'column', padding: '1rem' }}>
            <CardMedia component="img" src={MappingExample} alt="Mapping Example" style={{ height: '100%', width: '100%' }} />
            <CardContent style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
              <Typography variant="body2">
                <b>Figure 2</b>. Mappings between ABCD Base Ontology and target ontologies.
              </Typography>
            </CardContent>
          </Card>

          <Typography>
            In Figure 2 shows the mappings between the <i>ABCD Base Ontology</i> and the target ontologies. The table displays the number of mappings and the number of conflictive mappings computed in
            this case. In the second case, the PROV ontology, there are no computed conflictive mappings. Figure 2 displays box <b>B</b>, wchich contains buttons for exploring additional details about
            the mappings between the source ontology (<i>ABCD Base Ontology</i>, in this case ) and the selected target ontology.
          </Typography>

          <ul>
            <li>
              <i>Number of mappings:</i>
              <b>
                Mappings that grow from anchors to the final set of output mappings
                <a
                  href="https://github.com/ernestojimenezruiz/logmap-matcher/blob/dependabot/maven/org.json-json-20230227/src/main/java/uk/ac/ox/krr/logmap2/mappings/MappingManager.java"
                  target="_blank"
                >
                  {' '}
                  [1]
                </a>
                .
              </b>
            </li>
            <li>
              <i>Number of connflictive mappings: </i>{' '}
              <b>
                Mappings when occurring make a class unsatisfiable{' '}
                <a href="https://doi.org/10.1186/2041-1480-2-S1-S2" target="_blank">
                  [3]
                </a>
                .
              </b>
              <b>
                {' '}
                Mappings that cause an error with Dowling-Gallier algorithm or are in conflict with exact mappings wrt interval labelling schema. Conflictive mappings can make some classes
                unsatisfiable <a href="https://github.com/ernestojimenezruiz/logmap-matcher">[1]</a> <a href="https://cs.ox.ac.uk/files/4788/paperLogMapORE2012.pdf"> [4] </a>.{' '}
              </b>
            </li>
          </ul>

          <Card style={{ height: '100%', width: '100%', alignSelf: 'center', display: 'flex', flexDirection: 'column', padding: '1rem' }}>
            <CardMedia component="img" src={ExpandingRow} alt="Expanding Row" style={{ height: '100%', width: '100%' }} />
            <CardContent style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
              <Typography variant="body2">
                <b>Figure 3</b>. Mappings details between <i>ABCD Base Ontology</i> and <i>Materials Design Ontology - Full</i> ontologies.
              </Typography>
            </CardContent>
          </Card>

          <Typography>
            When users select a row action (refer to box B in Figure 2), they can access additional information about a specific mapping between the target and source ontologies. Figure 3 provides
            more details about the mappings between the <i> ABCD Base Ontology </i> as a source ontology and the <i>Materials Design Ontology - Full </i> as target ontology. Part A of Figure 3
            displays the metrics of the mappings between these ontologies, while Part C of Figure 3 shows two levels of tab buttons and mapping details in table form connected to each button. The
            first two tab buttons at level one are named as <i>'Mappings'</i>, <i>'Conflictive Mappings' </i>, respectively. They provide details about the mappings and conflictive mappings between
            elements in the source and target ontologies. The third tab button displays all of these mappings as a graph. The second group of tab button at level 3 in Figure 3 shows information about
            the produced mappings using a table view, which depends on the selected tabs at level one. The partial text describes class mappings, property mappings, and mappings between individuals.
            Tables connected to each tab located at level two have the same structure, including identical column names and the same number of columns (refer to rectangle B in Figure 3). Column names
            in tables follows a consistent naming convention.
            <ul>
              <li>
                <b>Source IRI</b> column denotes class, property of individual IRIs from source ontology. For example, if users select <i>mappings</i> tab at level one and then select{' '}
                <i>class mappings</i> tab at level two then source column in the table should contain class IRI. The same holds for <i>object property mappings</i>, <i>datatype property mappings</i>,
                <i>individual mappings</i> tabs.
              </li>
              <li>
                <b>Mapping Direction</b> column denotes relation type between source and target resorces. Depends on tab selection at level two, it could be equivalence or subsumption relations
                between classes or properties respectively.{' '}
              </li>
              <li>
                <b>Target IRI</b> column denotes class, property of individual IRIs from target ontology. For example, if users select <i>mappings</i> tab at level one and then select{' '}
                <i> object property mappings</i> tab at level two then <i>Target</i> column in the table should contain object property IRI. The same holds for <i>class mappings</i>,{' '}
                <i>datatype property mappings</i>,<i>individual mappings</i> tabs.
              </li>
              <li>
                <b style={{ color: 'red' }}>Structural Confidence Mapping : not defined yet</b>{' '}
              </li>
              <li>
                <b>Confidence </b> column denotes a <i>score of similarity</i> that is between 0 and 1 ( [0,1] ). A mapping between two entities is measured by <i>score of similarity</i>. This value
                is computed automatically by LogMap matcher [2].
              </li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br />

      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>2. Mapping Graphs</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '2rem', padding: '1rem' }}>
          <Typography variant="body1">
            Figure 4 shows a snapshot of a concise graph of mappings between ontologies. Users can explore the grap by clicking the <i>Graph View</i> button located in the sidebar. Nodes in the graph
            represent ontology IDs. Arrows in the graph connect source and target ontologies. Arrows are labeled with a pair of numbers. The first number represents the sum of mappings between the
            source and target ontologies, and the second number represents the sum of conflictive mappings between pairs of ontologies. Users can filter the graph by selecting a collection, such as{' '}
            <i>CoyPu</i>, which is the default value in the drop-down list. Further filtering of the graph is possible by selecting one or more source ontology ids from the selected ontology
            collections.
            <a href="https://www.color-hex.com/color/dd6e42" target="_blank" style={{ color: '#dd6e42' }}>
              {' '}
              Light orange{' '}
            </a>{' '}
            nodes on the graph denote source ontology IDs, while{' '}
            <a href="https://www.color-hex.com/color/4f6d7a" target="_blank" style={{ color: '#4f6d7a' }}>
              dark blue{' '}
            </a>
            nodes denote target ontology IDs. In a special case, if a
            <a href="https://www.color-hex.com/color/dd6e42" target="_blank" style={{ color: '#dd6e42' }}>
              {' '}
              light orange{' '}
            </a>{' '}
            node is assigned to both the target and source ontologies, that node is also colored in{' '}
            <a href="https://www.color-hex.com/color/dd6e42" target="_blank" style={{ color: '#dd6e42' }}>
              light orange
            </a>
            . When users click on a{' '}
            <a href="https://www.color-hex.com/color/dd6e42" target="_blank" style={{ color: '#dd6e42' }}>
              light orange{' '}
            </a>{' '}
            node, pops up showing more details about the mappings using a table view approach (see Figure 3 in Table View Mappings section). The table displays the name of target ontology name, the
            number of mappings, and the number of conflictive mappings related to the selected node representing the source ontology in the mapping.
          </Typography>

          <Card style={{ height: '700px', width: '1200px', alignSelf: 'center', display: 'flex', flexDirection: 'column' }}>
            <CardMedia component="img" src={MappingsGraph} alt="Mappings Graph" style={{ height: '700px', width: '1200px' }} />
            <CardContent style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
              <Typography variant="body2">
                <b>Figure 4</b>. Graph that shows mappings between selected ontologies within TIB terminology service
              </Typography>
            </CardContent>
          </Card>

          <Typography variant="body1">
            When users place the mouse cursor on every node on the graph, the full ontology name will be visible. For example, when users place the mouse cursor on <i>CoyPu</i> node, they will observe
            the name of the <i>CoyPu ontology</i> name.
          </Typography>

          <Card style={{ height: '400px', width: '900px', alignSelf: 'center', display: 'flex', flexDirection: 'column' }}>
            <CardMedia component="img" src={MappingsGraphForOneRow} alt="Mappings Graph for one Row" style={{ height: '360px', width: '900px' }} />
            <CardContent style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
              <Typography variant="body2">
                <b>Figure 5</b>. Graph that shows mappings between CIDOC and CoyPu ontologies
              </Typography>
            </CardContent>
          </Card>

          <Typography variant="body1">
            Figure 5 shows different types of mappings between <i>CIDOC</i> and <i>CoyPu</i> ontologies on the graph. Links are colored based on the type of mappings. Green color denotes mappings
            between classes, pink color denotes mappings between object properties, and blue color denotes mappings between datatype properties. When the user holds the mouse pointer over a link, more
            details about nodes and links will be presented to the user, such as the source node ID, the type of relation between nodes, and the target node ID. For example, in Figure 5, it is
            observable that <i>P4_has_time-span</i> is an equivalent object property to <i>global#hasTimespan</i> object property.
          </Typography>

          <Card style={{ height: '400px', width: '900px', alignSelf: 'center', display: 'flex', flexDirection: 'column' }}>
            <CardMedia component="img" src={ConflictiveMappingsGraphForOneRow} alt="Conflictive Mappings Graph for One Row" style={{ height: '350px', width: '900px' }} />
            <CardContent style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
              <Typography variant="body2">
                <b>Figure 6</b>. Graph that shows conflictive mapping between Digital Reference and Modular Ontology Modeling for Food Supply Chains ontologies
              </Typography>
            </CardContent>
          </Card>

          <Typography variant="body1">
            The arrows shown in Figure 5 and Figure 6 are annotated with a 4-tuple. Users can access the graphs shown in Figure 5 and Figure 6 from the mappings graph tab shown at level 2 in Figure 3.
            The first number in the tuple represents the type of mapping, which can be a class mapping expressed by the number zero. Number one is assigned to mappings between datatype properties. The
            number two is used to denote mappings between object properties. The number three denotes mappings between individuals. The second number in the 4-tuple denotes the direction of mapping.
            For example, the number <i>-2</i> denotes <i>equivalence</i>. If the type of mapping is zero and the mapping direction is equal to -2, then we recognize a mapping as a class equivalence
            between classes. The number zero (0) denotes <i>sub of</i> the mapping direction, and the number -1 denotes <i>super of</i> the mapping direction. For example, if the mapping type is equal
            to zero and the mapping direction is equal to -1, then we can say that the source node is a superclass of the target node in the graph. As shown in Figure 6, in the case of a conflictive
            mapping between the <i>End_Event</i> and <i>Event</i> classes, the 4-tuple is colored in red.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br />

      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>3. Structure of data stored in JSON format</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '2rem', padding: '1rem' }}>
          <Typography>
            Result of mappings between a pair of ontoloiges from NFDI4Ing terminology service are stored in JSON format. Such data are classified as follows:
            <ul>
              <li>id</li>
              <li>mapping id</li>
              <li>source ontology</li>
              <ul>
                <li>id</li>
                <li>ontology id</li>
                <li> uri </li>
                <li>title</li>
                <li>collection</li>
              </ul>
              <li>number of target ontologies</li>
              <li>target ontology list</li>
              <ul>
                <li>id</li>
                <li>number of mappings</li>
                <li>number of conflictive mappings</li>
                <li>target ontology</li>
                <ul>
                  <li>id</li>
                  <li>ontology id</li>
                  <li>uri</li>
                  <li>title</li>
                  <li>collection</li>
                </ul>
                <li>mapping list</li>
                <ul>
                  <li>source iri</li>
                  <li>mapping direction</li>
                  <li>target iri </li>
                  <li>type of mapping</li>
                  <li>structural confidence mapping</li>
                  <li>confidence</li>
                </ul>
                <li>conflictive mapping list</li>
                <ul>
                  <li>source iri</li>
                  <li>mapping direction</li>
                  <li>target iri </li>
                  <li>type of mapping</li>
                  <li>structural confidence mapping</li>
                  <li>confidence</li>
                </ul>
              </ul>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br />

      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>4. External Mappings</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '2rem', padding: '1rem' }}>
          <Typography>
            Figure 6 displays the start page for an external mappings service. In the Figure 6, part A is the place where users should add a resolvable URL for an external ontology as a raw file
            hosted on Github or Gitlab. Part B refers to a specific component that contains a list of ingested ontologies into the TIB Terminology Service (TS). Component C denotes the submit button.
            It is enabled if and only if a resolvable URL for the external ontology file hosted on Github or Gitlab, is provided, and at least one ontology from the TIB TS is selected. Otherwise, the
            submit button remains disabled. The component D in Figure 6 shows a switcher button that is used to enable or disable checking classes satisfiability when perfom mappings. Users can choose
            to enable or disable the check for unsatisfiability of classes in the integrated ontology when performing mappings between an external ontology and selected TIB TS ontologies.
          </Typography>

          <Card style={{ height: '100%', width: '100%', alignSelf: 'center', display: 'flex', flexDirection: 'column', padding: '1rem' }}>
            <CardMedia component="img" src={ExternalMappingStartingPage} alt="Mapping Table" style={{ height: '100%', width: '100%' }} />
            <CardContent style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
              <Typography variant="body2">
                <b>Figure 6 </b>. External mappinngs service starting page.
              </Typography>
            </CardContent>
          </Card>

          <Typography>
            The Figure 7 shows the results of external mappings between the <i>Coy</i> ontology as source and the <i>Supply Chain Operation Reference</i>,<i>ExtruOnt</i>, <i>FTOnto</i> ontologies as
            target ontologies. Parts A, B, C, and D shown in the Figure 7 have already been explained in the previous paragraph. Part E in Figure 7 shows the metrics of the external mappings. It shows
            the URL of the source ontology, the number of selected target ontologies and a hyperlink to download the result of the mappings in JSON. Part F in Figure 7 shows a list of target ontology
            names and their URLs, the number of mappings and the number of conflictive mappings. Part G within Part F of Figure 7 shows the Actions column, which allows users to get more information
            about specific mappings. If the number of mappings or the number of conflictive mappings is greater than zero, users should press the &nbsp; <ExpandCircleDownOutlinedIcon /> button to view
            more details about the mappings created. The <i>line blank icon</i> in the Actions column indicates that the mapping details are not available because both the number of mappings and the
            number of conflictive mappings are equal to zero. To view the exception explanation or the resoning explanation, the user should click the &nbsp; <InfoOutlinedIcon />
            button in the Actions column shown in Part G within Part F of Figure 7.
          </Typography>

          <Card style={{ height: '100%', width: '100%', alignSelf: 'center', display: 'flex', flexDirection: 'column', padding: '1rem' }}>
            <CardMedia component="img" src={ExternalMappingCoyDrExtr} alt="Mapping Example" style={{ height: '100%', width: '100%' }} />
            <CardContent style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
              <Typography variant="body2">
                <b>Figure 7</b>. External mapping results between <i>Coy</i> ontology as a source and <i>Supply Chain Operation Reference</i>, <i>ExtruOnt</i>, <i>FTOnto</i> ontologies as target.
              </Typography>
            </CardContent>
          </Card>

          <Typography>
            In Figure 8 illustrates the mapping details between between the <i>Coy</i> ontology as the source and the <i>Digital Reference</i> ontologies. The Metrics section presents the source
            ontology URL, target ontology URL and name, number of mappings, and number of conflictive mappings are presented. Part C in Table 3 presents information about mapping details and mappings
            graph between source and target ontologies in a similar manner.
          </Typography>

          <Card style={{ height: '100%', width: '100%', alignSelf: 'center', display: 'flex', flexDirection: 'column', padding: '1rem' }}>
            <CardMedia component="img" src={ExternalMappingDetailsCoyDrExtruOnt} alt="Expanding Row" style={{ height: '100%', width: '100%' }} />
            <CardContent style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
              <Typography variant="body2">
                <b>Figure 8</b>. External mapping details between <i>Coy</i> ontology as a srouce and <i>Digital Reference</i> ontology as a target.
              </Typography>
            </CardContent>
          </Card>

          <Typography>
            In Figure 9 illustrates the external mapping explanation between the <i>Coy</i> ontology as the source and the <i>ExtruOnt</i> ontologies. The Metrics section includes the source ontology
            URL, target ontology URL and name, number of mappings, and number of conflictive mappings are presented. The Explanation section provides users with information about reasoning results,
            such as unsatisfiability classes, ontology inconsistency, or exceptions that may occur during mappings.
          </Typography>

          <Card style={{ height: '100%', width: '100%', alignSelf: 'center', display: 'flex', flexDirection: 'column', padding: '1rem' }}>
            <CardMedia component="img" src={MappingReasoningAndExceptionExplanation} alt="Mapping Explanation" style={{ height: '100%', width: '100%' }} />
            <CardContent style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
              <Typography variant="body2">
                <b>Figure 9</b>. External mapping exception explanation and reasoning explanation.
              </Typography>
            </CardContent>
          </Card>

          <h5>The structure of external mappings data stored in JSON format </h5>
          <Typography>
            Result of external mappings between a source ontology and at least one target ontology has been ingested in TIB TS are stored in JSON format. Such data are classified as follows:
            <ul>
              <li>mapping id</li>
              <li>source ontology url</li>
              <li>number of target ontologies</li>
              <li>target ontology list</li>
              <ul>
                <li>id</li>
                <li>number of mappings</li>
                <li>number of conflictive mappings</li>
                <li>target ontology</li>
                <ul>
                  <li>id</li>
                  <li>ontology id</li>
                  <li>uri</li>
                  <li>title</li>
                  <li>collection</li>
                </ul>
                <li>mapping list</li>
                <ul>
                  <li>source iri</li>
                  <li>mapping direction</li>
                  <li>target iri </li>
                  <li>type of mapping</li>
                  <li>structural confidence mapping</li>
                  <li>confidence</li>
                </ul>
                <li>conflictive mapping list</li>
                <ul>
                  <li>source iri</li>
                  <li>mapping direction</li>
                  <li>target iri </li>
                  <li>type of mapping</li>
                  <li>structural confidence mapping</li>
                  <li>confidence</li>
                </ul>
              </ul>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br />

      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>5. References</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            <ListItem>
              <ListItemText
                primary="LogMap Matcher source code available on Github. Last accessed June 2023. Online:"
                secondary={
                  <Link href="https://github.com/ernestojimenezruiz/logmap-matcher" target="_blank">
                    https://github.com/ernestojimenezruiz/logmap-matcher
                  </Link>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText primary="Laadhar, A., Ghozzi, F., Bousarsar, I.M., Ravat, F., Teste, O. and Gargouri, F., 2017, November. Pomap: An effective pairwise ontology matching system. In IC3K 2017: 9th International Joint Conference on Knowledge Discovery, Knowledge Engineering and Knowledge Management (pp. 161-168)." />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Jiménez-Ruiz, E., Grau, B.C., Horrocks, I. et al. Logic-based assessment of the compatibility of UMLS ontology sources. J Biomed Semant 2 (Suppl 1), S2 (2011). Online:"
                secondary={
                  <Link href="https://doi.org/10.1186/2041-1480-2-S1-S2" target="_blank">
                    https://doi.org/10.1186/2041-1480-2-S1-S2
                  </Link>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Jiménez-Ruiz, E., Grau, B.C. and Horrocks, I., 2012. On the Feasibility of Using OWL 2 DL Reasoners for Ontology Matching Problems. In ORE. Online:"
                secondary={
                  <Link href="https://cs.ox.ac.uk/files/4788/paperLogMapORE2012.pdf" target="_blank">
                    https://cs.ox.ac.uk/files/4788/paperLogMapORE2012.pdf
                  </Link>
                }
              />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
      <br />

      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>6. How to obtain desired ontology raw file hosted on Github or Gitlab repositories</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '2rem', padding: '1rem' }}>
          <Typography variant="body1">
            Suppose you have discovered an ontology that is hosted on a Git-based system, and you want to use the
            ontology as a source in this external mapping service. To do this, all you need to do is to select the
            ontology file and then find the button labeled Raw or Open Raw File.  Figure 10 shows a
            snapshot of the Digital Reference ontology hosted on the Github repository. The red rectangle contains the
            ontology file name.
          </Typography>

          <Card style={{ height: '700px', width: '1200px', alignSelf: 'center', display: 'flex', flexDirection: 'column' }}>
            <CardMedia component="img" src={DigitalReferenceGithub} alt="Digital Reference Git repository" style={{ height: '400px', width: '1200px' }} />
            <CardContent style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
              <Typography variant="body2">
                <b>Figure 10</b>. Digital reference ontology hosted on Github repository.
              </Typography>
            </CardContent>
          </Card>

          <Typography variant="body1">
            Continue by clicking on the Digital Reference ontology file name <i>DigitalReference.ttl</i>, and then
            to the location of the raw ontology file, as it is shown in Figure 11.
          </Typography>


          <Card style={{ height: '300px', width: '1200px', alignSelf: 'center', display: 'flex', flexDirection: 'column' }}>
            <CardMedia component="img" src={DigitalReferenceRawFileLocation} alt="Digital Reference Git repository raw file location" style={{ height: '200px', width: '1200px' }} />
            <CardContent style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
              <Typography variant="body2">
                <b>Figure 11</b>. Digital Reference ontology raw file location on Github repository.
              </Typography>
            </CardContent>
          </Card>


          <Typography variant="body1">
            To access the raw ontology file please click the "Raw" button located inside red rectangle that is shown in Figure 11.
            As a result, you should see a page in your browser as shown in Figure 12. Please, copy the link from the
            browser's search bar located inside the red rectangle in Figure 12. This should serve as your link to the raw ontology file.
          </Typography>

          <Card style={{ height: '600px', width: '1200px', alignSelf: 'center', display: 'flex', flexDirection: 'column' }}>
            <CardMedia component="img" src={DigitalReferenceRawFile} alt="Digital Reference Git repository raw file location" style={{ height: '520px', width: '1000px' }} />
            <CardContent style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center' }}>
              <Typography variant="body2">
                <b>Figure 12</b>. Digital Reference ontology raw file URL.
              </Typography>
            </CardContent>
          </Card>

        </AccordionDetails>
      </Accordion>
      <br />

    </div>
  );
};

export default MappingsExplanation;
