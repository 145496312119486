import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';
import {Grid, Paper, Typography, styled, CircularProgress} from '@mui/material';
import {useEffect, useState} from 'react';
import { getOntology } from '../../../api/queryData';
import makeStyles from '@mui/styles/makeStyles';
import { marked } from 'marked';
import PropTypes from "prop-types";

const MARKDOWN_FILE_START_REGEX = new RegExp('# Ontology comparison\\n\\n## Left\\n- Ontology IRI: .+\\n- Version IRI: .+\\n- Loaded from: .+\\n\\n## Right\\n- Ontology IRI: .+\\n- Version IRI: .+\\n- Loaded from: .+\\n\\n');

const CustomTimelineSeparator = styled(TimelineSeparator)({
  minHeight: '200px', // Adjust the height of the connector to make it longer
});

const useStyles = makeStyles((theme) => ({
  cont: {
    minHeight: 'calc(100vh - 405px)',
  },
  grid: {
    backgroundColor: 'white',
    borderRight: '1px solid',
    borderLeft: '1px solid',
    borderRightColor: theme.palette.grey[300],
    borderLeftColor: theme.palette.grey[300],
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  rootPair: {
    marginLeft: theme.spacing(3),
    minHeight: '70vh',
    backgroundColor: 'white',
    borderRadius: ' 10px 10px 10px 10px',
    border: '1px solid',
    borderColor: theme.palette.grey[400],
  },
  timeline: {
    maxHeight: '70vh',
    overflowY: 'auto',
  },
  timelineItem: {
    marginBottom: theme.spacing(20),
  },
  timelineContent: {
    paddingTop: '20px', // Adjust the distance between Timeline items' content
    cursor: 'pointer',

  },
  commitMessage: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  timelinePaper: {

  },
}));

const renderer = {
    link(href, title, text) {
      return `<a href="${href}" style="font-size: 14px;">${text}</a>`;
    },
    heading(text, level) {
      const fontSize = level === 1 ? '24px' : level === 2 ? '20px' : '16px';
      return `<h${level} style="font-size: ${fontSize};">${text}</h${level}>`;
    }
};

marked.use({
  async: true,
  renderer
});

const TimelineOntology = ({ id }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [ontology, setOntology] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [html, setHtml] = useState('');
  const MAX_MARKDOWN_LINES = 100;
  const [renderedLines, setRenderedLines] = useState(MAX_MARKDOWN_LINES);

  useEffect(() => {
    async function fetchOntology() {
      const response = await getOntology(id);
      if (response.ok) {
        const ontology = await response.json();
        console.log(ontology);
        setOntology(ontology);
      }
    }

    fetchOntology();
  }, [id]);

  useEffect(() => {
    if (ontology.diffs && ontology.diffs.length > 0 && selectedIndex === -1) {
      handleItemClick(ontology.diffs[0], 0);
    }
  }, [ontology.diffs, selectedIndex]);

  const handleItemClick = async (item, index) => {
    setLoading(true);
    setSelectedItem(item);
    setSelectedIndex(index);
    setRenderedLines(MAX_MARKDOWN_LINES);

    const renderedHtml = document.getElementById('renderedHtml');
    if (renderedHtml) {
      renderedHtml.scrollTop = 0;
    }

    const initialSubset = item.markdown.file.split('\n').slice(0, MAX_MARKDOWN_LINES).join('\n').split(MARKDOWN_FILE_START_REGEX)[1];

    await new Promise((resolve) => setTimeout(resolve, 800));

    const htmlResult = await marked(initialSubset);
    setHtml(htmlResult);

    setLoading(false);
  };

  const handleScroll = async (e) => {
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
      await loadMoreLines();
    }
  }

  const loadMoreLines = async () => {
    const additionalLines = selectedItem?.markdown.file
        .split('\n')
        .slice(renderedLines, renderedLines + MAX_MARKDOWN_LINES)
        .join('\n');

    setRenderedLines((prevLines) => prevLines + MAX_MARKDOWN_LINES);
    const htmlResult = await marked(additionalLines);
    setHtml((prevHtml) => prevHtml + htmlResult);
  };


  return (
      <Grid container>
        <Grid item xs={12} md={6} className={classes.grid}>
          <Timeline className={classes.timeline}>
            {ontology?.diffs && ontology.diffs.map((diff, index) => (
                    <TimelineItem key={diff.id} onClick={() => handleItemClick(diff, index)}
                                  className={classes.timelineItem}>
                      <TimelineOppositeContent>
                        <Typography variant="body2" color="textSecondary">
                          {new Date(diff.timestamp).toLocaleString()}
                        </Typography>
                      </TimelineOppositeContent>
                      <CustomTimelineSeparator>
                        <TimelineDot/>
                        <TimelineConnector sx={{width: '2px', height: '50px'}}/>
                      </CustomTimelineSeparator>
                      <TimelineContent className={classes.timelineContent}>
                        <Paper
                            elevation={3}
                            className={classes.timelinePaper}
                            sx={{p: 2}}
                            style={
                              selectedIndex === index
                                  ? {
                                    backgroundColor: 'lightblue',
                                    width: '350px',
                                  }
                                  : {width: '350px'}
                            }
                        >
                          <Typography variant="h6" component="h6" className={classes.commitMessage}>
                            {diff.message}
                          </Typography>
                        </Paper>
                      </TimelineContent>
                    </TimelineItem>
                ))}
          </Timeline>
        </Grid>
        <Grid item xs={12} md={6} className={classes.grid}>
          {selectedItem && loading && (
              <div>
                <p>
                  Loading...
                </p>
                <CircularProgress/>
              </div>
          )}
          {selectedItem && !loading && (
              <div className={classes.timeline} onScroll={handleScroll} id='renderedHtml'>
                <div
                    dangerouslySetInnerHTML={{__html: html}}
                />
              </div>
          )}
          {!selectedItem && (
              <div>
                <p>
                  After you choose one of the items from the timeline on the left
                  <br/>
                  You will see it's value here.
                </p>
              </div>
          )}
        </Grid>
      </Grid>
  );
};

TimelineOntology.propTypes = {
  id: PropTypes.string.isRequired
}

export default TimelineOntology;
