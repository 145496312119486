import React, { useState } from "react";
import {
  Alert,
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const test_subjects = [
  {
    code: "gnd:4205811-9",
    name: "Mikromechanik",
  },
  {
    code: "gnd:4017233-8",
    name: "Finite-Elemente-Methode",
  },
  {
    code: "gnd:4774350-5",
    name: "Mikrometerbereich",
  },
  {
    code: "gnd:4198914-4",
    name: "Aktor",
  },
];

const AnnotationForm = () => {
  const [formData, setFormData] = useState({
    title: "",
    abstract: "",
  });
  const [subjects, setSubjects] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [isAddingSubject, setIsAddingSubject] = useState(false);
  const [newSubject, setNewSubject] = useState({ code: "", name: "" });
  const [newSubjectError, setNewSubjectError] = useState({ message: "" });
  const [finalSubmit, setFinalSubmit] = useState(false);
  const [helpDialog, setHelpDialog] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      // const response = await fetch("http://example.org/api/annotate1", {
      //   method: "POST",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify(formData),
      // });
      // const data = await response.json();
      setSubjects(formData.title === "test" ? test_subjects : null);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
      setSubmitted(true);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubjectsChange = (event) => {
    setSelectedSubjects(event.target.value);
  };

  const isSubjectsArray = () => {
    return subjects?.length;
  };

  const handleReset = () => {
    setFormData({ title: "", abstract: "" });
    setSubjects(null);
    setSelectedSubjects([]);
    setSubmitted(false);
  };

  const handleNewSubjectChange = (event) => {
    const { name, value } = event.target;
    setNewSubject((prev) => ({ ...prev, [name]: value }));
  };

  const saveNewSubject = () => {
    if (subjects.find((subject) => subject.code === newSubject.code)) {
      setNewSubjectError({ message: "Subject already exists" });
      return;
    }
    if (newSubject.name && newSubject.code) {
      const obj = { ...newSubject };
      setSubjects((prev) => [...prev, obj]);
      setSelectedSubjects((prev) => [...prev, obj]);
      setNewSubject({ code: "", name: "" });
      setIsAddingSubject(false);
    }
  };

  const cancelNewSubject = () => {
    setNewSubject({ code: "", name: "" });
    setNewSubjectError(null);
    setIsAddingSubject(false);
  };

  const handleFinalSubmit = async () => {
    const finalData = {
      title: formData.title,
      abstract: formData.abstract,
      subjects: selectedSubjects,
    };

    try {
      // const response = await fetch(`https://example.org/api/annotate2`, {
      //   method: "POST",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify(finalData),
      // });
      // const data = await response.json();
      const data = "Success";
      handleReset();
      setFinalSubmit(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  let content;
  if (isLoading) {
    content = (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress color="primary" />
      </div>
    );
  }
  if (subjects) {
    content = (
      <div>
        <Typography variant="h6">
          Proposed subjects were returned from the LLM
        </Typography>
        <FormControl fullWidth style={{ marginTop: "20px" }}>
          <InputLabel>Select Subjects *</InputLabel>
          <Select
            multiple
            displayEmpty
            value={selectedSubjects}
            onChange={handleSubjectsChange}
            renderValue={(selected) =>
              selected
                .filter((item) => item && item.name)
                .map((item) => item.name)
                .join(", ")
            }
            variant="outlined"
          >
            <Button onClick={() => setIsAddingSubject(true)}>
              Add new subject
            </Button>
            {subjects.map((subject) => (
              <MenuItem key={subject.code} value={subject}>
                {subject.name} - {subject.code}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Dialog open={isAddingSubject} fullWidth maxWidth="lg">
          <DialogTitle>Add new subject</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You can add a new subject here. If your new subject will have the
              same subject code as in a list of suggested subjects you will see
              an error <i>Subject already exists</i>.
              <br />
              It is expected that if you add a new subject you want it to be
              selected, this is done by default.
            </DialogContentText>
            <br />
            <form onSubmit={saveNewSubject} onChange={handleNewSubjectChange}>
              <div style={{ display: "flex", gap: "30px" }}>
                <TextField
                  label="Subject Name"
                  name="name"
                  value={newSubject.name}
                  onChange={handleNewSubjectChange}
                  variant="outlined"
                  fullWidth
                  size="medium"
                  required
                  error={newSubjectError}
                />
                <TextField
                  label="Subject Code"
                  name="code"
                  value={newSubject.code}
                  onChange={handleNewSubjectChange}
                  variant="outlined"
                  fullWidth
                  size="medium"
                  required
                  error={newSubjectError}
                />
                <Button
                  variant="contained"
                  size="small"
                  onClick={saveNewSubject}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={cancelNewSubject}
                >
                  Cancel
                </Button>
              </div>
            </form>
            {newSubjectError && (
              <Typography variant="h6" style={{ color: "red" }}>
                {newSubjectError.message}
              </Typography>
            )}
          </DialogContent>
        </Dialog>
        <div
          style={{
            textAlign: "center",
            marginTop: "20px",
            display: "flex",
            gap: "10px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleFinalSubmit}
          >
            Submit
          </Button>
          <Button variant="contained" color="primary" onClick={handleReset}>
            Reset
          </Button>
        </div>
      </div>
    );
  }
  if (!isSubjectsArray() && submitted === true) {
    content = (
      <div>
        <Typography variant="h6">
          No subjects were found for the provided title and abstract
        </Typography>
      </div>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom textAlign="center">
          Annotation Service ORKG (for explanation click this button -&gt;{" "}
          <Button
            variant="contained"
            color="info"
            onClick={() => setHelpDialog(true)}
          >
            Explanation
          </Button>
        </Typography>

        <Dialog open={helpDialog} fullWidth maxWidth="lg">
          <DialogTitle>
            How to work with the ORKG annotation service frontend
          </DialogTitle>
          <DialogContent>
            Currently since we don't have any backend, I have added the static
            test data from your Postman scripts. I have already implemented some
            checks and alerts for the user, to understand different responses
            from the service. First of all, if you provide any title except{" "}
            <i><b>test</b></i> there will be a message below the abstract text field
            that no subjects were found - I thought this could happen is some
            cases.
            <br />
            <br />
            If you provide title <i><b>test</b></i> and any abstract, you will see a
            new select menu below the abstract text field, where you would be
            able to select from suggested subjects.
            <br />
            <br />
            If you click the button in the expanded select list called{" "}
            <i><b>Add new subject</b></i> a dialog will open, where you would need to
            provide subject name and subject code. I have also added a small
            validation for the uniqueness of the subject code - if you try to
            add a new subject with subject code that already exists in a list
            there will be an error message.
            <br />
            <br />
            I decided to make the title and abstract fields after receiving a
            list of recommended subject read-only, as I think they mustn't
            change after LLM had annotated them.
            <br />
            <br />
            If you click <i><b>Reset</b></i> button after receiving a list of recommended subjects, you will be dropped
            to the start with title and abstract values being emptied.
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => setHelpDialog(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Typography variant="body1" gutterBottom>
          Service should return a number of subjects from the Large Language
          Model for the provided article's title and abstract.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Grid item xs={12}>
            <TextField
              label={isSubjectsArray() ? "Title (Read only)" : "Enter title"}
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              required
              variant={isSubjectsArray() ? "filled" : "outlined"}
              fullWidth
              inputProps={{
                readOnly: isSubjectsArray(),
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <TextField
              label={
                isSubjectsArray() ? "Abstract (Read only)" : "Enter abstract"
              }
              name="abstract"
              value={formData.abstract}
              onChange={handleInputChange}
              required
              variant={isSubjectsArray() ? "filled" : "outlined"}
              multiline
              rows={6}
              fullWidth
              inputProps={{
                readOnly: isSubjectsArray(),
              }}
            />
          </Grid>
          <div>{content}</div>
          {!isSubjectsArray() && (
            <div style={{ marginTop: "20px" }}>
              <Button variant="contained" color="primary" type="submit">
                Annotate
              </Button>
            </div>
          )}
        </form>
      </Grid>
      {finalSubmit && (
        <Alert severity="success" onClose={() => setFinalSubmit(false)}>
          Uploaded successfully to the server.
        </Alert>
      )}
    </Grid>
  );
};

export default AnnotationForm;
