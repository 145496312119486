import React from 'react';
import {styled} from "@mui/system";
import {Typography} from "@mui/material";

const Container = styled('div')({
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    maxWidth: '800px',
    margin: 'auto',
});

export const GitOntologyStatusCheckExplanation = () => {
    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Git Ontology Status Checker
            </Typography>
            <Typography variant="body1" gutterBottom>
                The Git Ontology Status Checker is a service that monitors the freshness of public ontologies on GitHub and GitLab. It verifies if there have been any commits since the last update on the Terminology Service platform. If the ontology is outdated, the service will specify the number of commits that are pending; if it's up-to-date, it will confirm that the latest version is available.
            </Typography>
            <Typography variant="h6" gutterBottom>
                Service Report Overview
            </Typography>
            <Typography variant="body1" gutterBottom>
                Our service generates a detailed report featuring the following key information:
            </Typography>
            <ul>
                <li><strong>Ontology ID:</strong> The unique identifier for the ontology.</li>
                <li><strong>Ontology Git Branch:</strong> The specific Git branch from which this ontology is derived.</li>
                <li><strong>Ontology Status:</strong> Indicates whether the ontology is current or how many commits it is behind based on the last update in the Terminology Service.</li>
            </ul>
        </Container>

    );
};