import React, { useState, useEffect, useMemo } from 'react';
import { TableContainer, Table, TableRow, TableBody, TableCell, TextField, Button, Grid, Typography, Box, IconButton, Switch, Link } from '@mui/material';

import Select from 'react-select';
import MaterialReactTable from 'material-react-table';
import GenerateMappingDetails from './GenerateMappingDetails';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MappingReport from './MappingReport';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';

import { Link as RouterLink } from 'react-router-dom';

const GenerateMapping = () => {
  const [loading, setLoading] = useState(false);
  const [ontologyList, setOntologyList] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [uri, setUri] = useState('');
  const [selected, setSelected] = useState([]);
  const [result, setResult] = useState('');

  const [checkedSAT, setCheckedSAT] = React.useState(true);

  const handleSATChange = (event) => {
    setCheckedSAT(event.target.checked);
  };

  const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
  `;

  let spinnerContainer = (
    <LoadingContainer>
      <CircularProgress color="secondary" />
    </LoadingContainer>
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: 'targetOntologyTitle',
        header: 'Target Ontology Name',
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
        },
      },
      {
        accessorKey: 'targetOntologyUrl',
        header: 'Target Ontology URL',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'left',
        },
      },
      {
        accessorKey: 'numberOfMappings',
        header: 'Number of Mappings',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
      {
        accessorKey: 'numberOfConflictiveMappings',
        header: 'Number of Conflictive Mappings',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
      },
    ],
    []
  );

  /**
   * Enable submit button when both url and ontology ids are selected.
   * Ontology URL should pass validation chekcpoint
   *    - Ontology should be hosted on Github or Gitlab
   *    - Ontology should be available as a raw file via ontology url
   * @param uri
   * @param selectedIds
   * @returns {boolean}
   */
  function buttonDisabled(uri, selectedIds) {
    return !!(isValidUrl(uri) && selectedIds !== '' && inputUriValidation(uri));
  }

  const inputUriValidation = (url) => {
    if (url.includes('raw.githubusercontent.com')) {
      return 'github';
    } else if (url.includes('gitlab.com')) {
      return 'gitlab';
    } else if (url.includes('git.tib.eu')) {
      return 'gitTIB';
    }
    return '';
  };

  // ${process.env.REACT_APP_MAPPING_BACKEND_URL} http://localhost:9191
  useEffect(() => {
    fetch(`${process.env.REACT_APP_MAPPING_BACKEND_URL}/api/ontology/list`)
      .then((response) => response.json())
      .then((data) => {
        setOntologyList(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log('error when fetch data:' + err.message);
        setLoading(false);
      });
  }, []);

  /**
   * stores all ontology ids from TIB TS into tibOntologyIds and
   * populates Select component with ids
   * @type {*[]}
   */
  const tibOntologyIds = [];

  ontologyList.map((item) => {
    tibOntologyIds.push({ value: item.ontologyId, label: item.title });
  });

  const handleUriChange = (event) => {
    setUri(event.target.value);
  };

  const handleChangeOntologyIds = (selectedOptions) => {
    setSelected(selectedOptions);
  };

  /**
   * ckecks whether file url is type of URL
   * @param fileUrl
   * @returns {boolean}
   */
  const isValidUrl = (fileUrl) => {
    try {
      new URL(fileUrl);
      return true;
    } catch (_) {
      return false;
    }
  };

  const selectedIds = [];

  for (const element of selected) {
    selectedIds.push(element.value);
  }

  let serviceUrl = [];

  useEffect(() => {
    //${process.env.REACT_APP_MAPPING_BACKEND_URL}
    serviceUrl = `${process.env.REACT_APP_MAPPING_BACKEND_URL}/api/ontology/mapping/external/list?uri=` + uri + '&ids=' + selectedIds.join(',') + '&sat=' + checkedSAT;
  }, [uri, selectedIds.join(','), checkedSAT]);

  let totalRequestCount = 0;

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    totalRequestCount += 1;

    console.log('SAT is selected (Within handleFormSubmit): ' + checkedSAT);

    console.log('event.currentTarget.elements.usersUrlInput.value: ' + event.currentTarget.elements.usersUrlInput.value);
    /**
     * when nothing is selected from the list of ontology ids then make submit button desibaled.
     * check on the web about a solution.
     */
    console.log('selected tib ontology ids: ' + selectedIds.join(','));
    console.log('selected ontology url: ' + uri);
    console.log('service Url: ' + serviceUrl);

    async function fetchData(uri, selectedIds, serviceUrl) {
      await fetch(serviceUrl)
        .then((res) => res.json())
        .then((data) => {
          setResult(data);
          console.log('form submitted (fetch): ' + formSubmitted);
          console.log('data: ' + data);
          setFormSubmitted(true);
          setLoading(false);
        })
        .catch((err) => {
          /**
           * if an exception occurs than spinner is turned to off (it is not shown on screen).
           */
          setLoading(false);
          console.log('error when fetch server url:' + err.message);
        });

    console.log('totalRequestCount: ' + totalRequestCount);

    }

    fetchData(event.currentTarget.elements.usersUrlInput.value, selectedIds.join(','), serviceUrl);
  };

  /**
   * form is submitted in useEffect
   */
  useEffect(() => {
    console.log('form is submitted: ' + formSubmitted);

    if (formSubmitted) {
      setFormSubmitted(false);
    }
  }, [formSubmitted]);

  let errorMessage = '';
  let jsonObject1 = '';

  try {
    jsonObject1 = JSON.parse(JSON.stringify(result));
  } catch (error) {
    errorMessage = 'the same data are already submitted';

    console.log('error message: ' + errorMessage);
  }

  let mappingSummary = [];

  if (Object.keys(result).length > 0) {
    Array(jsonObject1._embedded).forEach((item) => {
      item.externalmappings.forEach((item2) => {
        const sourceOntologyAndNumberOfMappings = {
          mappingId: `${item2.mappingId}`,
          sourceOntologyURI: `${item2.sourceOntologyURI}`,
          numberOfTargetOntologies: `${item2.numberOfTargetOntologies}`,
        };

        mappingSummary.push(sourceOntologyAndNumberOfMappings);
      });

      return mappingSummary;
    });
  }

  let mappings = [];

  if (Object.keys(result).length > 0) {
    Array(jsonObject1._embedded).forEach((item) => {
      item.externalmappings.forEach((item2) => {
        /**
         * Creates an object (mappings) that is used to dicplay mappings in tabs and graph (mapping details)
         */
        mappings.push({ id: item2.mappingId, targetOntologyList: item2.targetOntologyList });
      });

      return mappings;
    });
  }

  let tables = [];
  let count = 0;

  if (Object.keys(result).length > 0) {
    Array(jsonObject1._embedded).forEach((item) => {
      item.externalmappings.forEach((item2) => {
        if (item2.numberOfTargetOntologies > 0) {
          count = count + 1;
        }

        console.log('item2.numberOfTargetOntologies: ' + item2.numberOfTargetOntologies);

        item2.targetOntologyList.forEach((item3) => {
          item3.targetOntology.forEach((item4) => {
            const ontologylink = item4.uri;

            let targetOntologyList = {
              id: `${item2.mappingId}`,
              targetOntologyId: `${item4.ontologyId}`,
              targetOntologyTitle: `${item4.title}`,
              targetOntologyUrl: (
                <a href={ontologylink} target="_blank" rel="noopener noreferrer">
                  {ontologylink}
                </a>
              ),
              numberOfMappings: `${item3.numberOfMappings}`,
              numberOfConflictiveMappings: `${item3.numberOfConflictiveMappings}`,
              mappingException: `${item3.mappingException}`,
            };

            tables.push(targetOntologyList);
          });
        });
      });

      return tables;
    });
  }

  const downloadMappingsAsJSON = () => {
    const fileName = 'mappings';
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(result))}`;
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = fileName + '.json';

    link.click();
  };

  const content = mappingSummary.map((post, index) => (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }} key={post.key}>
      <Typography variant="h6">Metrics</Typography>
      <TableContainer key={post.key}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Source Ontology URL:</TableCell>
              <TableCell>
                <a href={post.sourceOntologyURI} target="_blank" rel="noopener noreferrer">
                  {post.sourceOntologyURI}
                </a>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Number of Target Ontologies:</TableCell>
              <TableCell>{post.numberOfTargetOntologies} </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Download Mappings as a JSON file:</TableCell>
              <TableCell>
                <IconButton onClick={downloadMappingsAsJSON}>
                  <FileDownloadIcon color="action" fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  ));
  /**
   * return source ontology url
   * @returns {string}
   */
  function getSourceOntologyUrl() {
    let url = '';

    mappingSummary.forEach((item) => {
      url = item.sourceOntologyURI;

      return url;
    });

    return url;
  }

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={10} md={11}>
          <Typography variant="h5" gutterBottom textAlign="center">
            External mappings
          </Typography>

          <Typography variant="body2" gutterBottom style={{ marginBottom: '1rem' }}>
            On this page users can generate mappings between an external ontology and a list of selected ontologies that have been ingested into the TIB Terminology Service (TS). To use this feature,
            the user must provide a &nbsp;
            <Link href="https://www.w3.org/TR/url-1/" target="_blank" rel="noopener noreferrer">
              resolvable URL
            </Link>
            &nbsp; for an external <i> ontology raw file </i> hosted on Github or Gitlab and select one or more ontology names that have been ingested into the TIB Terminology Service (TS).
            Please, click <Link component={RouterLink} to={'?howtouse=true&sectionId=rawUri'}>here</Link> to find out how to obtain desired ontology raw file hosted on Github or Gitlab repositories.
            Optionally, the user may select or deselect checking the satisfiability (see defintion 3.6 in &nbsp;
            <Link href="https://www.sciencedirect.com/science/article/pii/S1574652607030039" target="_blank" rel="noopener noreferrer">
              this
            </Link>
            &nbsp; paper) of classes in integrated source ontology, target ontology and generated mappings ontology. The service will then validate the external ontology URL. If the external ontology
            URL passes validation and at least one ontology name is selected, the <b>submit</b> button will be enabled. Otherwise, the <b>submit</b> button will be disbaled. The generated mappings can
            be downloaded as JSON. To explore more details about the generated mappings, click the &nbsp; <ExpandCircleDownOutlinedIcon /> &nbsp; button in the Actions column. If an exception occurs,
            if the number of generated mappings and conflictive mappings is zero, further exploration of mappings is not availbale. This is indicated by the line blank icon (&nbsp;{' '}
            <CheckBoxOutlineBlankIcon /> &nbsp;) in the Actions column. When the user hovers over this icon, a massage <i>no mapping details</i> message is displayed. To view the exception explanation
            or the resoning explanation, the user should click the &nbsp; <InfoOutlinedIcon /> &nbsp; button in the Actions column.
          </Typography>
          <hr />
          <form onSubmit={handleFormSubmit} style={{ textAlign: 'left' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ flex: 1, fontSize: 13  }}>

                <TextField
                  required
                  id="usersUrlInput"
                  label="An external ontology resolvable URL file hosted on Github or Gitlab."
                  placeholder="example: https://gitlab.com/coypu-project/coy-ontology/-/raw/main/ontology/global/coy.ttl"
                  helperText={'example: https://gitlab.com/coypu-project/coy-ontology/-/raw/main/ontology/global/coy.ttl'}
                  variant="outlined"
                  type="url"
                  name="uri"
                  onChange={handleUriChange}
                  size="small"
                  value={uri}
                />
               <br/>
               Click <Link component={RouterLink} to={'?howtouse=true&sectionId=rawUri'}>here</Link> to find out how to obtain desired ontology raw file.
              </div>
              <div style={{ flex: 1 }}>
                <Select
                  isMulti={true}
                  menuPlacement="auto"
                  name="ids"
                  options={tibOntologyIds}
                  onChange={handleChangeOntologyIds}
                  autoFocus={true}
                  className="menu-outer-top"
                  classNamePrefix="select"
                  placeholder="Select one or more ontology names from TIB Terminology Service"
                  maxMenuHeight={190}
                />
                <div style={{fontSize: 13  }}>
                  Check the satisfiability of classes using the{' '}
                  <Link href="http://www.hermit-reasoner.com/" target="_blank">
                    {' '}
                    HermiT{' '}
                  </Link>{' '}
                  reasoner:
                  <Switch checked={checkedSAT} onChange={handleSATChange} inputProps={{ 'aria-label': 'controlled' }} />
                </div>
              </div>
              <Button disabled={!buttonDisabled(uri, selectedIds.join(','))} variant="contained" type="submit" style={{ marginLeft: '15px', height: '30%' }}>
                Submit
              </Button>
            </div>
          </form>
        </Grid>
        <Grid item xs={12} md={11}>
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="3vh">
            {loading && spinnerContainer}
          </Box>
        </Grid>
        <Grid item xs={12} md={11}>
          <div>{count > 0 && content}</div>

          <Grid container>
            {count > 0 && <Typography variant="h6">Mapping results:</Typography>}

            {count > 0 && (
              <MaterialReactTable
                columns={columns}
                data={tables}
                enableColumnResizing={true}
                autoResetPageIndex={false}
                enableStickyHeader={true}
                enableFullScreenToggle={false}
                enableRowActions={true}
                /**
                 * It changes background color of each row to white smoke colour when the row is expanded.
                 * otherwise, background color of the row is white
                 * solved by Dr FarFar Kheir Eddine, email: Kheir.Farfar@tib.eu
                 **/
                muiTableBodyRowProps={({ row }) => ({
                  sx: {
                    backgroundColor: row.getIsExpanded() ? '#efefef' : 'white',
                  },
                })}
                muiTableProps={{
                  sx: {
                    borderCollapse: 'collapse',
                  },
                }}
                /**
                 * Defines background color for table headers
                 * It is not necessary to use useTheme hook.
                 * It is enough to use the `sx` prop with the theme callback.
                 */
                muiTableHeadCellProps={{
                  sx: () => ({
                    backgroundColor: '#D3D3D3' /* lightgray color*/,
                  }),
                }}
                renderRowActions={({ row, table }) => [
                  <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '0px' }} key={row.original.id}>
                    <IconButton onClick={() => console.info('Mapping Details')}>
                      <GenerateMappingDetails
                        mappings={mappings}
                        row={row}
                        sourceOntologyUrl={getSourceOntologyUrl()}
                        numberOfMappings={row.original.numberOfMappings}
                        numberOfConflictiveMappings={row.original.numberOfConflictiveMappings}
                        targetOntologyTitle={row.original.targetOntologyTitle}
                        targetOntologyId={row.original.targetOntologyId}
                        targetOntologyUrl={row.original.targetOntologyUrl}
                        table={table}
                      />
                    </IconButton>

                    <IconButton onClick={() => console.info('Mapping Exception Details')}>
                      <MappingReport
                        mappings={mappings}
                        row={row}
                        sourceOntologyUrl={getSourceOntologyUrl()}
                        targetOntologyTitle={row.original.targetOntologyTitle}
                        targetOntologyUrl={row.original.targetOntologyUrl}
                      />
                    </IconButton>
                  </Box>,
                ]}
                initialState={{ density: 'compact' }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default GenerateMapping;
