import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { marked } from "marked";
import makeStyles from "@mui/styles/makeStyles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
const PAGE_SIZE = 13;

const useStyles = makeStyles((theme) => ({
  markdownGrid: {
    minHeight: "80%",
  },
  markdownContent: {
    fontSize: "14px",
  },
  buttonContainer: {
    position: "fixed",
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    gap: theme.spacing(2),
  },
  datepicker: {
    width: "400px",
  },
  timeline: {
    maxHeight: "70vh",
    overflowY: "auto",
  },
}));

const ReportConto = ({ id }) => {
  const styles = useStyles();
  const today = new Date();
  const startOfCurrentMonth = new Date(
    today.getFullYear(),
    today.getMonth(),
    1,
  );

  const [startDate, setStartDate] = useState(new Date(startOfCurrentMonth.setUTCHours(0, 0, 0, 1)));
  const [endDate, setEndDate] = useState(new Date(new Date().setUTCHours(23, 59, 59, 999)));
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [html, setHtml] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;
  const currentPageData = data
    .split("\n\n")
    .slice(startIndex, endIndex)
    .join("\n\n");

  const handleStartDate = (date) => {
    date.toISOString()
    date.setUTCHours(0, 0, 0, 1);
    setStartDate(date);
  }

  const handleEndDate = (date) => {
    date.toISOString()
    date.setUTCHours(23, 59, 59, 999);
    setEndDate(date);
  }

  const handleDataPreview = async () => {
    async function fetchOntology() {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_DIFF_BACKEND_URL}/api/history/conto/report?uri=${id}&firstDate=${startDate.toISOString()}&secondDate=${endDate.toISOString()}`,
      );
      if (response.ok) {
        const data = await response.json();
        const markdownContent = formatDataForMarkdown(data);
        const htmlContent = await marked(markdownContent);
        setHtml(htmlContent);
      }
      setFetched(true);
      setLoading(false);
    }

    fetchOntology();
  };

  const formatUriFragment = (uri) => {
    const fragments = uri.split("/");
    let lastFragment = fragments[fragments.length - 1];
    if (lastFragment.includes("#")) {
      lastFragment = lastFragment.split("#")[1];
    }

    return `[${lastFragment}](${uri})`;
  };

  const formatDataForMarkdown = (data) => {
    let markdownContent = "";

    const groupedChanges = {};

    data.forEach((change) => {
      const parts = change.split(" ");

      const ppLabel = parts[0];
      const s = formatUriFragment(parts[1]);
      const p = formatUriFragment(parts[2]);
      const o = formatUriFragment(parts[3]);

      if (!groupedChanges[ppLabel]) {
        groupedChanges[ppLabel] = [];
      }

      groupedChanges[ppLabel].push({ s, p, o });
    });

    const subjectArr = Object.values(groupedChanges).flatMap((changes) =>
      changes.map((change) => change.s),
    );
    const maxFrequentResource = getMode(subjectArr);

    console.log(maxFrequentResource);

    markdownContent =
      `## Aggregated Information\n` +
      "#### Most frequent axiom is: " +
      maxFrequentResource.value +
      "\n" +
      `#### Number of appearances is: **${maxFrequentResource.count}**\n\n`;

    Object.entries(groupedChanges).forEach(([ppLabel, triples]) => {
      markdownContent += `### ${ppLabel}\n`;

      triples.forEach((triple) => {
        markdownContent += `- ${triple.s} ${triple.p} ${triple.o}\n`;
      });

      markdownContent += "\n";
    });

    return markdownContent;
  };

  const getMode = (array) => {
    const buildFrequencyMap = (array) => {
      return array.reduce((acu, item) => {
        acu[item] = acu[item] !== undefined ? acu[item] + 1 : 1;
        return acu;
      }, {});
    };

    const maxFrequency = (frequencyMap) => {
      let maxFreq = -1,
        result = null;
      Object.entries(frequencyMap).forEach(([key, value]) => {
        if (value > maxFreq) {
          maxFreq = value;
          result = key;
        }
      });
      return { value: result, count: maxFreq };
    };

    const frequencyMap = buildFrequencyMap(array);
    return maxFrequency(frequencyMap);
  };

  const generateHTMLFile = async (htmlContent, pdfStartDate, pdfEndDate) => {
    const htmlContentWithDate =
      `Report from ${pdfStartDate.toISOString().split("T")[0]} to ${pdfEndDate.toISOString().split("T")[0]}\n\n` +
      htmlContent;
    const htmlMarked = await marked.parse(htmlContentWithDate);
    const blob = new Blob([htmlMarked], { type: "text/html" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `report - ${pdfStartDate.toISOString().split("T")[0]} - ${pdfEndDate.toISOString().split("T")[0]}.html`;
    link.click();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Typography varian="body2">Click or prompt the start date</Typography>
        <DatePicker
          showIcon
          selected={startDate}
          onChange={(date) => handleStartDate(date)}
          selectsStart
          maxDate={endDate}
          className={styles.datepicker}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
      </Grid>
      <Grid item xs={3}>
        <Typography varian="body2">Click or prompt the end date</Typography>
        <DatePicker
          showIcon
          selected={endDate}
          onChange={(date) => handleEndDate(date)}
          selectsEnd
          minDate={startDate}
          className={styles.datepicker}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
      </Grid>
      <Grid item xs={3}>
        <Button variant="contained" onClick={handleDataPreview} fullWidth>
          Preview Data
        </Button>
      </Grid>
      <Grid item xs={3}>
        <Button
          variant="contained"
          onClick={() => generateHTMLFile(html, startDate, endDate)}
          fullWidth
        >
          Generate Report
        </Button>
      </Grid>

      {fetched === false && (
        <Grid item xs={12}>
          <Typography variant="h5">
            In this view you can see a similar information to full ontology
            history, but with some differences
            <br />
            <br />
            First: you could define the period, for which you would like to see
            all differences.
            <br />
            <br />
            Second: after clicking <i>Preview Data</i> button in the right top,
            you would see some additional information - most frequent axiom and
            number of times it appeared across all differences
            <br />
            <br />
            Third: after inspecting the data provided you could create some
            report in html format to use it for later, or share with your
            colleagues.
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} md={12}>
        <div
          id="renderedHtml"
          className={styles.timeline}
          style={{ whiteSpace: "pre-line" }}
        >
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </Grid>
    </Grid>
  );
};

ReportConto.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ReportConto;
