import { Typography } from '@mui/material';
import MaterialReactTable from "material-react-table";

export const CommunityAssessment = ({ communityAssessmentRows, communityAssessmentColumns }) => {
  return (
    <div>
      <Typography variant="h4" gutterBottom component="div">
        Community Assessment
      </Typography>
      <Typography variant="body1" gutterBottom>
        An overview of the Quality of Github ontologies maintained in Terminology Service
      </Typography>
      <MaterialReactTable
        columns={communityAssessmentColumns}
        data={communityAssessmentRows}
        enableRowSelection={false}
        enableColumnResizing
        initialState={{ pagination: { pageSize: 10 } }}
        />
    </div>
  );
};
